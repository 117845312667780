import Layout from "../layout/layout";
import "./index.css";
import Filter from "./filter";
import { useState } from "react";
import BetHistoryTable from "./betHistoryTable";

// Fetch players data using useEffect
// eslint-disable-next-line react-hooks/rules-of-hooks

const BetHistory = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    id: "",
    gameName: "",
    page: "",
    pageSize: "",
  });

  return (
    <Layout title="Bet History">
      <div className="users-page">
        <Filter setRecall={setRecall} setFilter={setFilter} filter={filter} />
        <BetHistoryTable filter={filter} recall={recall} />
      </div>
    </Layout>
  );
};
export default BetHistory;
