import Layout from "../layout/layout";
import "./index.css";
import Filter from "./filter";
import { useState } from "react";
import GameTable from "./gameTable";

// Fetch players data using useEffect
// eslint-disable-next-line react-hooks/rules-of-hooks

const Games = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    id: "",
    gameName: "",
    page: "",
    pageSize: "",
  });

  return (
    <Layout title="Game Management ">
      <div className="users-page position-relative">
        <Filter setRecall={setRecall} setFilter={setFilter} filter={filter} />

        <GameTable filter={filter} recall={recall} setRecall={setRecall} />
      </div>
    </Layout>
  );
};
export default Games;
