import React from "react";
import { Dropdown, Form } from "react-bootstrap";

const TableColumns = ({ tableData, checkedBox, filterTable }) => {
  return (
    <div className="table-dropdown" id={filterTable}>
      <Dropdown>
        <Dropdown.Toggle variant="success" key="dropdown-basic">
          <i className="las la-eye"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {tableData.map((item, i) => (
            <Form.Check
              key={i}
              type={"checkbox"}
              id={`checkbox-${item.field}`}
              label={item?.headerName}
              name={item.field}
              defaultChecked={item.checked}
              onClick={checkedBox}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default TableColumns;
