import { Button } from "react-bootstrap";
import { useState } from "react";
import CommentPopup from "./commentPopup";
import Layout from "../layout/layout";
import CommentsTable from "./commentsTable";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./comments.css";
const Comments = () => {
  const [addPopup, setAddPopup] = useState("");
  const [columnDefs] = useState([
    { field: "id", minWidth: 150, flex: 1 },
    { field: "TagName", minWidth: 180, flex: 1 },
    { field: "Creator", minWidth: 180, flex: 1 },
    {
      field: "Actions",
      minWidth: 200,
      flex: 1,
      cellRenderer: () => <Actions setAddPopup={setAddPopup} />,
    },
  ]);
  const handlePopup = (value) => {
    if (value !== "") {
      setAddPopup(value);
    } else {
      setAddPopup("");
    }
  };
  return (
    <Layout title="COMMENTS">
      <div className="notification-page payment-method-page">
        <Button
          className="send-notificaton comment-btn"
          onClick={() => handlePopup("socialPopup")}
        >
          <span className="add-icon">
            <AddBoxIcon />
          </span>{" "}
          Add Tags
        </Button>
        <CommentPopup
          show={addPopup}
          socialPopup={addPopup}
          handleClose={handlePopup}
        />
        <CommentsTable columnDefs={columnDefs} />
      </div>
    </Layout>
  );
};
export default Comments;

const Actions = ({ setAddPopup }) => {
  const handlePoppup = (value) => {
    setAddPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
    </>
  );
};
