import { Button } from "react-bootstrap";
import Layout from "../../layout/layout";
import BannerTable from "./bannerTable";
import { useState, useEffect } from "react";
import Filter from "./filter";
import BannerPopup from "./bannerPopup";
import "./banners.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getBannerData, getBannerPageData } from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import { formatDateTime2 } from "../../../utils/dateTimeFromater";
const Banners = () => {
  const [addPopup, setAddPopup] = useState("");
  const { isLoading } = useMyContect();
  const [rowData, setRowData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [recall, setRecall] = useState(0);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const pageSize = 20;
  const [bannerPage, setBannerPage] = useState([]);
  const [serchData, setSearchData] = useState({
    bannerPage: "",
    plateformType: "",
  });
  const [columnDefs] = useState([
    {
      headerName: "Id",
      field: "id",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Position",
      field: "bannerPosition",
      minWidth: 180,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    { headerName: "Lang", field: "language", minWidth: 180, flex: 1 },
    {
      headerName: "Banner Page",
      field: "bannerPage",
      width: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Plateform",
      field: "plateformType",
      minWidth: 180,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Banner Link",
      field: "bannerLink",
      width: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Active From",
      field: "activeFrom",
      width: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Active To",
      field: "activeTo",
      width: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    { headerName: "Banner", field: "bannerImage", minWidth: 180, flex: 1 },
    {
      headerName: "Open In New Tab",
      field: "opensInNewTab",
      minWidth: 180,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Banner Alt",
      field: "bannerAlt",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "Actions",
      minWidth: 200,
      flex: 1,
      cellRenderer: () => <Actions setBannerPopup={setAddPopup} />,
    },
  ]);
  const handlePopup = (value) => {
    if (value !== " ") {
      setAddPopup(value);
    } else {
      setAddPopup("");
    }
  };

  const handleFetchPageData = async () => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
        };
        setBannerPage([]);
        const res = await getBannerPageData(config);
        if (res) {
          setBannerPage(res?.data?.data);
        }
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  const handleSelectChange = (e) => {
    const plateform = e.target.selectedOptions[0].parentNode.label;
    const value = e.target.value;
    setSearchData({
      ...serchData,
      bannerPage: value,
      plateformType: plateform,
    });
  };

  const handleSearch = () => {
    const { bannerPage, plateformType } = serchData;
    handleFetch(page, bannerPage, plateformType);
  };

  const handleFetch = async (page, bannerPage, plateformType) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
            bannerPage: bannerPage,
            plateformType: plateformType,
          },
        };
        setRowData([]);
        setLoader(true);
        const res = await getBannerData(config);
        if (res) {
          if (res && res.data && res.data.data) {
            const transformedData = res.data.data.map((item) => ({
              ...item,
              opensInNewTab: item?.opensInNewTab ? "True" : "False",
              bannerImage: item?.bannerImage,
              activeFrom: item?.activeFrom
                ? formatDateTime2(item?.activeFrom)
                : "---",
              activeTo: item?.activeTo
                ? formatDateTime2(item?.activeTo)
                : "---",
            }));
            setRowData(transformedData);
            setPages(res?.data?.pages);
          }
        }
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleFetch(page);
    handleFetchPageData();
  }, [page, isLoading, recall]);
  return (
    <Layout title="BANNERS">
      <div className="notification-page payment-method-page banners-page1 banner-content-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs mb-0 horizontal-tabs-top ">
            <Button
              className="send-notificaton"
              onClick={() => handlePopup("bannerPopup")}
            >
              <span className="add-icon">
                <AddBoxIcon />
              </span>{" "}
              Add Banner
            </Button>
          </div>
          <Filter
            bannerPage={bannerPage}
            handleSelectChange={handleSelectChange}
            handleSearch={handleSearch}
          />
        </div>
        <BannerPopup
          show={addPopup}
          handleClose={handlePopup}
          bannerPage={bannerPage}
          setRecall={setRecall}
        />
        <BannerTable
          columnDefs={columnDefs}
          rowData={rowData}
          setPage={setPage}
          pages={pages}
          page={page}
          loader={loader}
        />
      </div>
    </Layout>
  );
};
export default Banners;

const Actions = ({ setBannerPopup }) => {
  const handlePoppup = (value) => {
    setBannerPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
    </>
  );
};
