import { useState } from "react";
import GlobalTable from "../../global/globalTable";

const StatisticsTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "ID", minWidth: 150, flex: 1 },
    { field: "User Name", minWidth: 180, flex: 1 },
    { field: "Parent Affiliate Name", minWidth: 180, flex: 1 },
    { field: "First Name", minWidth: 180, flex: 1 },
    { field: "Last Name", minWidth: 180, flex: 1 },
    { field: "Email", minWidth: 180, flex: 1 },
    { field: "Phone", minWidth: 180, flex: 1 },
    { field: "Currency", minWidth: 180, flex: 1 },
    { field: "Bet Amount", minWidth: 180, flex: 1 },
    { field: "Win Amount", minWidth: 180, flex: 1 },
    { field: "Profit", minWidth: 180, flex: 1 },
    { field: "Bet Count", minWidth: 180, flex: 1 },
    { field: "Win Count", minWidth: 180, flex: 1 },
    { field: "Probability", minWidth: 180, flex: 1 },
  ];

  return (
    <div className="deposit-table">
      <h2>User Game Statistics</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default StatisticsTable;
