import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const BonusTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "Bonus", minWidth: 120, flex: 1 },
    { field: "Bonus Template ID", minWidth: 180, flex: 1 },
    { field: "Creator Name", minWidth: 180, flex: 1 },
    { field: "Activator Type", minWidth: 180, flex: 1 },
    { field: "Activator Name", minWidth: 180, flex: 1 },
    { field: "Activator User Name	", minWidth: 180, flex: 1 },
    { field: "Activation Count", minWidth: 180, flex: 1 },
    {
      field: "User ID",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    { field: "User Name", minWidth: 120, flex: 1 },
    { field: "Affiliate Name", minWidth: 120, flex: 1 },
    { field: "Email", minWidth: 120, flex: 1 },
    {
      field: "Bonus Amount",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Current Rollover",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Realization Rollover",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Activation Amount",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "NGR",
      minWidth: 120,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Balance",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    { field: "Status", minWidth: 120, flex: 1 },
    { field: "Create Date", minWidth: 120, flex: 1 },
    { field: "Active Till", minWidth: 120, flex: 1 },
    { field: "Last Activation", minWidth: 180, flex: 1 },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Bonus Report</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default BonusTable;
