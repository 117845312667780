import Filter from "./filter";
import ExportExcel from "../global/exportExcel";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import TableColumns from "../global/tableColumns";
import Pagination from "../../utils/Pagination";
import { withdrawRequestsData } from "../../utils/Apis";
import { useMyContect } from "../../context/myContext";
const WithdrawTable = () => {
  const [rowData, setRowData] = useState([]);
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    userId: "",
    fromAmount: "",
    toAmount: "",
  });
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [recall, setRecall] = useState(0);
  const [pages, setPages] = useState("");

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "userId",
      headerName: "User Id",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "left" },
    },
    {
      field: "usdAmount",
      headerName: "USD Amount",
      minWidth: 100,
      flex: 1,
      // cellStyle: { "text-align": "center"  },
      // type: 'center'
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "userName",
      headerName: "User Name",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "brand",
      headerName: "Brand",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "status",
      headerName: "Active",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
  ]);
  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );
  const handleGetDepositData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          filter: filter,
        };
        setLoading(true);

        const res = await withdrawRequestsData(config);
        // console.log("res", res);

        if (res) {
          setLoading(false);
          if (res && res.data && res.data.data) {
            const transformedData = res.data?.data.map((item) => ({
              ...item,
              status: item.status === "complete" ? "Completed" : item.status,
              userId: item?.userId || "---",
              usdAmount:
                typeof item?.usdAmount === "number"
                  ? parseFloat(item.usdAmount)?.toFixed(2)
                  : "---",
              brand: localStorage.getItem("brand"),
              userName: item?.userName || "---",
              firstName: item?.firstName || "---",
              lastName: item?.lastName || "---",

              id: item?.id ? item?.id : "---",
            }));
            setPages(res.data.pages);
            setRowData(transformedData);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  const handleTableCheckBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  useEffect(() => {
    handleGetDepositData(page, limit);
    // eslint-disable-next-line
  }, [isLoading, page, recall]);
  return (
    <div className="deposit-table withdraw-table">
      <div className="top-area">
        <h6>
          <p className="p-0 m-0 withdraw-icon">
            <AssignmentReturnedIcon />
          </p>{" "}
          Withdraw Requests <span>0</span>
        </h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <TableColumns
            tableData={tableData}
            checkedBox={handleTableCheckBox}
          />
        </div>
      </div>
      <Filter filter={filter} setFilter={setFilter} setRecall={setRecall} />
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
export default WithdrawTable;
