import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";

const AdminCommentTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "User ID", minWidth: 200, flex: 1 },
    { field: "User Name", minWidth: 200, flex: 1 },
    { field: "Groups", minWidth: 200, flex: 1 },
    { field: "Comments", minWidth: 200, flex: 1 },
    { field: "Create Date", minWidth: 200, flex: 1 },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Admin Comments</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default AdminCommentTable;
