import Layout from "../layout/layout";
import { useState } from "react";
import GlobalTable from "../global/globalTable";
import ButtonWrapper from "../global/button";
import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import FilterDatePicker from "../global/filterDatePicker";
const options = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

const AffiliateDeposit = () => {
  return (
    <Layout title="AFFILATE USERS REG & DEPOSITS">
      <div className="notification-page affiliate-deposit-page users-page">
        <div className="filter-section">
          <FilterDatePicker
            label="Registration From date"
            name="RegistrationFromdate"
          />
          <FilterDatePicker
            label="Registration To Date"
            name="RegistrationFromdate"
          />
          <FilterDatePicker
            label="Deposit From date"
            name="RegistrationFromdate"
          />
          <FilterDatePicker
            label="Deposit To Date"
            name="RegistrationFromdate"
          />
          <div className="payment-btn">
            <ButtonWrapper text="Search" />
          </div>
        </div>
        <CommisionStageTable />
      </div>
    </Layout>
  );
};

export default AffiliateDeposit;

const CommisionStageTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "Affiliate Name", minWidth: 180, flex: 1 },
    { field: "Reg.Users", minWidth: 120, flex: 1 },
    { field: "Total Deposits", minWidth: 120, flex: 1 },
    { field: "Deposit Count", minWidth: 150, flex: 1 },
    { field: "AVG Deposit", minWidth: 150, flex: 1 },
    { field: "Registration Start Date", minWidth: 180, flex: 1 },
    { field: "Registration End Date", minWidth: 180, flex: 1 },
    { field: "Deposit Start Date", minWidth: 150, flex: 1 },
    { field: "Deposit End Date", minWidth: 150, flex: 1 },
  ];

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Affiliate Statistics</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption={10} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
