import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import ButtonWrapper from "../global/button";
import { userSchema } from "../agent/AgentValidationSchema";
// import { useMyContect } from "../../context/myContext";

const AddUsersPopUp = ({
  show,
  loading,
  handleClose,
  handleNewUser,
  selectedUserDetails,
}) => {
  // const { user } = useMyContect();
  // const getLevel = () => {
  //   let level;
  //   if (user?.level === "master") {
  //     level = "distributor";
  //   } else if (user?.level === "distributor") {
  //     level = "sub-distributor";
  //   } else if (user?.level === "sub-distributor") {
  //     level = "store";
  //   } else {
  //     level = "cashier";
  //   }

  //   const displayLevel = level.charAt(0).toUpperCase() + level.slice(1);
  //   return { displayLevel, apiLevel: level };
  // };
  // const { displayLevel, apiLevel } = getLevel();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="notification-popup add-payment-popup add-commission-popup deposit-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {show === "create" ? "Add User" : "Edit User"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            // level: "User",
            userName: selectedUserDetails?.userName || "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={userSchema}
          onSubmit={(values) => {
            handleNewUser(values);
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="level-label">
                  <span>Level:</span>{" "}
                  <p class="badge badge-pill badge-success p-2 m-0">User</p>
                </Form.Label>
              </Form.Group>
              {show === "create" && (
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text py-3 pe-1" id="">
                        Username:
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder={`Enter User Name`}
                      autoComplete="off"
                      name="userName"
                      value={values.userName}
                      onChange={handleChange}
                      isRequired={true}
                      className="form-control py-3 px-1 modal-input"
                      isInvalid={touched.userName && !!errors.userName}
                    />
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`${touched.userName && "d-block"}`}
                  >
                    {errors.userName}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text py-3 pe-1" id="">
                      Password:
                    </span>
                  </div>
                  <input
                    type="password"
                    placeholder={`Enter Password`}
                    autoComplete="off"
                    name="password"
                    onChange={handleChange}
                    isRequired={true}
                    className="form-control py-3 px-1 modal-input"
                    isInvalid={touched.password && !!errors.password}
                  />
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  className={`${touched.password && "d-block"}`}
                >
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text py-3 pe-1" id="">
                      Confirm Password:
                    </span>
                  </div>
                  <input
                    type="password"
                    placeholder={`Re-Type Password`}
                    autoComplete="off"
                    name="confirmPassword"
                    onChange={handleChange}
                    isRequired={true}
                    className="form-control py-3 px-1 modal-input"
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                  />
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  className={`${touched.confirmPassword && "d-block"}`}
                >
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="deposit-popup-btn">
                <ButtonWrapper loading={loading} text="Create" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddUsersPopUp;
