import React, { useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import ButtonWrapper from '../global/button'
import SelectInput from '../global/selectInput'
import { updateGameGenreImg } from '../../utils/Apis'
// import { useMyContect } from "../../context/myContext";
import UploadFileIcon from '@mui/icons-material/UploadFile';
const UpdateGamePopUp = ({
  show,
  loading,
  handleClose,
  allCategory,
  selectedGameDetails,
  setRecall
}) => {
  const inputRef = useRef(null)
  const [file, setFile] = useState()
  const [selectedGame, setSelectedGame] = useState({
    id: 0,
    value: "Select Category"
  })
  const handleChange = (e) => {
    const g = allCategory?.find(({ name }) => name === e.target.value)
    setSelectedGame({
      id: Number(g?.masterGameSubCategoryId),
      value: g?.name
    })
  }
  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const isNotSameGenre = selectedGameDetails?.sub_category_name !== selectedGame?.value
    if (file || isNotSameGenre) {
      const payload = {
        gameId: selectedGameDetails?.master_casino_game_id
      }
      if (file) {
        payload.file = file
      }
      if (isNotSameGenre) {
        payload.catId = selectedGame?.id
      }
      updateGameGenreImg(payload).then(res => {
        if (res?.data?.code === 200) {
          setRecall(p => p + 1)
          setFile(null)
          handleClose()
        }
      })
    }
  }
  useEffect(() => {
    if (selectedGameDetails) {
      setSelectedGame({
        id: Number(selectedGameDetails?.master_game_sub_category_id),
        value: selectedGameDetails?.sub_category_name
      })
    }
  }, [selectedGameDetails])
  const gameLink = file ? URL.createObjectURL(file) : ""
  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose()
        setFile(null)
      }}
      size="lg"
      centered
      className="notification-popup add-payment-popup add-commission-popup deposit-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Game Category</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="level-label">
              <span>Game Name:</span>{' '}
              <p class="badge badge-pill badge-success p-2 m-0">{selectedGameDetails?.name}</p>
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text py-3 pe-1" id="">
                  Category:
                </span>
              </div>
              {selectedGame ? <SelectInput
                listArray={allCategory?.map((el) => {
                  return {
                    id: el?.masterGameSubCategoryId,
                    value: el?.name,
                  }
                })}
                defaultOption={selectedGame}
                handleSelectOption={handleChange}
              /> : null}
            </div>
            <div style={{
              position: 'relative'
            }}>
              <div style={{
                height: '20px',
                margin: '20px 0 20px',
                alignItems: 'center'
              }}>
                <button style={{
                  position: 'absolute',
                  right: 0,
                  borderRadius: '8px',
                  background: '#4caf50',
                  color: 'white',
                  border: 0
                }} type='button' onClick={() => {
                  inputRef.current.click()
                }}><UploadFileIcon color='inherit' /></button>
                <input style={{
                  opacity: 0,
                  position: 'absolute'
                }} ref={inputRef} type='file' onChange={handleFileChange} />
                <span>Game Icon:</span>
              </div>
              <div style={{
                width: "100%",
                height: '200px'
              }}><img src={gameLink || selectedGameDetails?.thumbnail_url} alt='' style={{ width: '100%', height: '100%' }} /></div>
            </div>
          </Form.Group>
          <div className="deposit-popup-btn">
            <ButtonWrapper loading={loading} text="Update" type="submit" />
          </div>
        </Form>

      </Modal.Body>
    </Modal>
  )
}

export default UpdateGamePopUp
