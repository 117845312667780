import { Button } from "react-bootstrap";
import Layout from "../../layout/layout";
import SiteTextTable from "./siteTextTable";
import { useState } from "react";
import SiteTextPopup from "./siteTextPopup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "./siteText.css";
import { useMyContect } from "../../../context/myContext";

const SiteText = () => {
  const [siteTextPopup, setSitetextPopup] = useState("");
  const [recall, setRecall] = useState(0);
  const [siteTextData, setSiteTextData] = useState([]);
  const { data } = useMyContect();
  const handlePopup = (value) => {
    if (value !== " ") {
      setSitetextPopup(value);
    } else {
      setSitetextPopup(" ");
    }
  };

  const [columnDefs] = useState([
    {
      headerName: "Language",
      field: "language",
      width: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Title",
      field: "title",
      width: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Content",
      field: "content",
      width: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Action",
      field: "",
      width: 200,
      flex: 1,
      cellRenderer: () => (
        <Actions setSitetextPopup={setSitetextPopup} data={data} />
      ),
    },
  ]);

  return (
    <Layout title="SITE TEXTS">
      <div className="notification-page payment-method-page ">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs1 horizontal-tabs-top horizontal-tabs-mb">
            <Button
              className="send-notificaton"
              onClick={() => handlePopup("Create")}
            >
              <span className="add-icon">
                <AddBoxIcon />
              </span>{" "}
              Create New Site Text
            </Button>
          </div>
        </div>
        <SiteTextTable
          columnDefs={columnDefs}
          setSiteTextData={setSiteTextData}
          siteTextData={siteTextData}
          recall={recall}
        />
        <SiteTextPopup
          show={siteTextPopup}
          handleClose={handlePopup}
          sitePopup={siteTextPopup}
          setSiteTextData={setSiteTextData}
          siteTextData={siteTextData}
          recall={recall}
          setRecall={setRecall}
        />
      </div>
    </Layout>
  );
};
export default SiteText;

const Actions = ({ setSitetextPopup, data }) => {
  const [flag, setFlag] = useState(false);
  const handlePoppup = async (value) => {
    setFlag(!flag);
    setSitetextPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
      {/* <EditPaymentMethod show={flag} handleClose={handlePoppup} /> */}
    </>
  );
};
