import { useEffect, useState } from "react";
import { getAllSiteText } from "../../../utils/Apis";
import GlobalTable from "../../global/globalTable";
import Pagination from "../../../utils/Pagination";
import { useMyContect } from "../../../context/myContext";

const SiteTextTable = ({ columnDefs, setSiteTextData, recall }) => {
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState("");
  const { isLoading } = useMyContect();
  const pageSize = 10;
  const handleFetchSiteText = async (page) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        setLoading(true);
        const res = await getAllSiteText(config);
        if (res) {
          setLoading(false);
          if (res && res.data && res.data.data) {
            const transformedData = res.data.data.map((item) => ({
              id: item.id,
              language: item.language,
              title: item.title,
              content: item.content,
            }));
            setRowData(transformedData);
            setSiteTextData(transformedData);
            setPages(res?.data?.pages);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetchSiteText(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, recall, isLoading]);
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Site Texts</h6>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

export default SiteTextTable;
