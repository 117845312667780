import { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";

const InfoTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "IP Address", minWidth: 200, flex: 1 },
    { field: "User Agent", minWidth: 200, flex: 1 },
    { field: "First Use", minWidth: 200, flex: 1 },
    { field: "Last Use", minWidth: 200, flex: 1 },
  ]);

  return (
    <div className="deposit-table withdraw-table">
      <div className="top-area">
        <h6>Admin IP info</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput defaultOption={10} listArray={[{ id: 1, value: 20 }]} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
export default InfoTable;
