import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
import FilterDatePicker from "../global/filterDatePicker";

const Filter = ({ filter, recall, setFilter, setRecall }) => {
  let Currencyoptions = [
    {
      id: 1,
      value: "USD",
    },
  ];

  let activeOptions = [
    {
      id: 1,
      value: "Active",
    },
    {
      id: 1,
      value: "Not Active",
    },
  ];
  let usedOptions = [
    {
      id: 1,
      value: "Used",
    },
    {
      id: 1,
      value: "Not Used",
    },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSearch = () => {
    setRecall(recall + 1);
  };
  const handleClear = () => {
    setFilter({
      amount: "",
      currency: "",
      createdAt: null,
      endDate: null,
      creatorId: "",
      couponcode: "",
      status: "",
      usedStatus: "",
      redempDateFrom: null,
      redempDateTo: null,
    });
    setRecall(recall + 1);
  };

  const renderInput = (lable, name, value) => (
    <FloatingLabel controlId="floatingInput" label={lable}>
      <Form.Control
        type="number"
        placeholder=""
        autoComplete="off"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FloatingLabel>
  );
  const handleDateChange = (name, date) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date.split(" ")[0],
    }));
  };

  return (
    <div className="voucher-filter ">
      <div className="filter-section mb-filter">
        <SelectInput
          defaultOption={"Currencies"}
          name="currency"
          listArray={Currencyoptions}
          selectedValue={filter.currency}
          handleSelectOption={handleChange}
        />
        {renderInput("Amount", "amount", filter.amount)}
        <FilterDatePicker
          label="Create Date From"
          name={"createdAt"}
          flag={false}
          value={filter.createdAt}
          handleDateChange={handleDateChange}
        />
        <FilterDatePicker
          label="Create Date To"
          name={"endDate"}
          flag={false}
          value={filter.endDate}
          handleDateChange={handleDateChange}
        />
        {renderInput("User ID", "creatorId", filter.creatorId)}
        {renderInput("Code", "couponcode", filter.couponcode)}
      </div>
      <div className="filter-section">
        <SelectInput
          defaultOption={"Used/Not Used"}
          listArray={usedOptions}
          handleSelectOption={handleChange}
          name="usedStatus"
        />
        <SelectInput
          defaultOption={"Active/Not Active"}
          listArray={activeOptions}
          name="status"
          selectedValue={filter.status}
          handleSelectOption={handleChange}
        />
        <FilterDatePicker
          label="Redemption Date From"
          name={"redempDateFrom"}
          flag={false}
          value={filter.redempDateFrom}
          handleDateChange={handleDateChange}
        />
        <FilterDatePicker
          label="Redemption Date To"
          name={"redempDateTo"}
          flag={false}
          value={filter.redempDateTo}
          handleDateChange={handleDateChange}
        />
        <ButtonWrapper
          text={"Search"}
          clValue="search-filter-btn"
          handleClick={handleSearch}
        />
        <ButtonWrapper
          text={"Clear All"}
          clValue="clear-btn"
          handleClick={handleClear}
        />
      </div>
    </div>
  );
};
export default Filter;
