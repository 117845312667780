import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import FilterInput from "../../global/filterInput";
import FilterDatePicker from "../../global/filterDatePicker";

const LoginLogFilter = ({ setRecall, filter, setFilter }) => {
  let deviceType = [
    {
      id: 1,
      value: "Desktop",
    },
    {
      id: 2,
      value: "Mobile",
    },
    {
      id: 3,
      value: "Tablet",
    },
    {
      id: 4,
      value: "TV",
    },
    {
      id: 5,
      value: "Console",
    },
    {
      id: 6,
      value: "Car",
    },
    {
      id: 7,
      value: "Other",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="voucher-filter login-filter">
      <div className="filter-section mb-filter">
        <FilterInput
          label="User ID"
          name="userId"
          handleChange={handleChange}
          value={filter.userId}
        />
        <FilterInput
          label="User Name"
          name="userName"
          handleChange={handleChange}
          value={filter.userName}
        />
        <FilterInput
          label="IP"
          name="ipAddress"
          handleChange={handleChange}
          value={filter.ipAddress}
        />
        <FilterInput
          label="Browser"
          name="Browser"
          handleChange={handleChange}
          value={filter.Browser}
        />
      </div>
      <div className="filter-section mb-filter">
        <FilterInput
          label="OS"
          name="os"
          handleChange={handleChange}
          value={filter.os}
        />
        <FilterDatePicker
          label="Date From:"
          name="fromDate:"
          handleChange={handleChange}
          value={filter.fromDate}
        />
        <FilterDatePicker
          label="Date To:"
          name="toDate:"
          handleChange={handleChange}
          value={filter.toDate}
        />
        <SelectInput
          name="deviceType"
          defaultOption={"Device Type"}
          listArray={deviceType}
          handleSelectOption={handleChange}
          value={filter.deviceType}
        />
        <div className="filter-section mb-0 ">
          <ButtonWrapper
            text={"Search"}
            clValue="search-filter-btn"
            handleClick={() => {
              setRecall(Math.random());
            }}
          />
          <ButtonWrapper
            text="Clear All"
            clValue="clear-btn"
            handleClick={() => {
              setFilter({
                userId: "",
                userName: "",
                ipAddress: "",
                Browser: "",
                os: "",
                fromDate: "",
                toDate: "",
                deviceType: "",
              });
              setRecall(Math.random());
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default LoginLogFilter;
