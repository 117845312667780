import Layout from "../layout/layout";
import { useState } from "react";
import GlobalTable from "../global/globalTable";
import SelectInput from "../global/selectInput";
import ButtonWrapper from "../global/button";
import ExportExcel from "../global/exportExcel";
import TableColumns from "../global/tableColumns";
import FilterInput from "../global/filterInput";
import FilterDatePicker from "../global/filterDatePicker";

const CurrencyOptions = [
  {
    id: 1,
    value: "AED",
  },
];

const AffiliateUserReport = () => {
  return (
    <Layout title="Affiliate Report">
      <div className="notification-page affiliate-report-page users-page">
        <div className="filter-section">
          <FilterInput label={"Affiliate ID"} name={"AffiliateID"} />
          <FilterDatePicker label={"Start date"} name={"StartDate"} />
          <FilterDatePicker label={"To Date"} name={"ToDate"} />
          <SelectInput
            defaultOption={"Affiliate Currency"}
            listArray={CurrencyOptions}
          />
          <div className="payment-btn">
            <ButtonWrapper text="Search" />
          </div>
        </div>
        <CommissionStageTable />
      </div>
    </Layout>
  );
};

export default AffiliateUserReport;

const CommissionStageTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "ID", minWidth: 200, flex: 1 },
    { field: "User Name", minWidth: 200, flex: 1 },
    {
      field: "Views",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "Unique Views",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Clicks",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Signups",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "FTD Count",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "FTD Amount",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Deposit Count",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Deposit Amount",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Bonus Amount",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Withdraw Count",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Withdraw Amount",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "GGR",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "NGR",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "commission",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      minWidth: 200,
      flex: 1,
    },
    { field: "Users", minWidth: 200, flex: 1 },
  ];

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Affiliate User Report</h6>
        <div className="right-area">
          <ExportExcel />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
