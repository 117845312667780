/* eslint-disable jsx-a11y/img-redundant-alt */
import SelectInput from '../global/selectInput'
import TableColumns from '../global/tableColumns'
import GlobalTable from '../global/globalTable'
import { useEffect, useState } from 'react'
import {
  getAllUserBetHistory,
} from '../../utils/Apis'
import Pagination from '../../utils/Pagination'
import { useMyContect } from '../../context/myContext'
import { formatDate } from '../../utils/dateTimeFromater'
//import { Edit } from '@mui/icons-material'

const BetHistoryTable = ({ recall, filter }) => {
  const { isLoading} = useMyContect()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState('')
  const [pageSize, setPageSize] = useState('20')
  const [rowData, setRowData] = useState([])
  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
    {
      id: 1,
      value: 500,
    },
    {
      id: 1,
      value: 1000,
    },
    {
      id: 1,
      value: 1500,
    },
    {
      id: 1,
      value: 2000,
    },
    {
      id: 1,
      value: 2500,
    },
    {
      id: 1,
      value: 3000,
    },
    {
      id: 1,
      value: 3500,
    },
    {
      id: 1,
      value: 4000,
    },
  ]
  const getAllBetHistoryData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('accToken')
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        }

        setLoading(true)
        const res = await getAllUserBetHistory(config)
        setLoading(false)
        if (res?.data) {
          setRowData(res?.data?.bets || [])
          setPages(res?.data.totalPages)
        }
      } else {
        console.log('No token found')
      }
    } catch (error) {
      console.log('Error found while fetching data', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAllBetHistoryData(page, pageSize)
    // eslint-disable-next-line
  }, [pageSize, page, filter, recall])

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Game ID',
      field: 'game_id',
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      sort: 'desc',
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (params) => params.value || '-',
    },
    {
      headerName: 'Date',
      field: 'created_at',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => formatDate(params?.value)  || '-',
    },
    {
      headerName: 'Game Image',
      field: 'game_thumbnail', // or replace with the actual field that holds the image URL
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => {
        const imageUrl = params.value || ''
        return imageUrl ? (
          <img
            src={imageUrl}
            alt="Game Image"
            style={{ width: '50px', height: '50px' }}
          />
        ) : (
          '-'
        )
      },
    },
    

    {
      headerName: 'Game Name',
      field: 'game_name',
      minWidth: 200,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
    },
    {
      headerName: 'Win/Loss',
      field: 'action_type',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
      cellStyle: (params) => {
        if (params.value === 'win') {
          return { color: 'green', fontWeight: 'bold' }
        } else if (params.value === 'loss') {
          return { color: 'red', fontWeight: 'bold' }
        }else if (params.value === 'bet') {
          return { color: 'warning', fontWeight: 'bold' }
        }
        return {}
      },
    },
    {
      headerName: 'Before Balance',
      field: 'before_balance',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => Number(params?.value || 0)?.toFixed(2) || '-',
    },
    {
      headerName: 'After Balance',
      field: 'after_balance',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => Number(params?.value || 0)?.toFixed(2) || '-',
      cellStyle: (params) => {
        const actionType = params?.data?.action_type|| "";
        if (actionType === "win") {
          return { color: 'green', fontWeight: 'bold' };  // Increased balance
        } else if (actionType ==="loss") {
          return { color: 'red', fontWeight: 'bold' };  // Decreased balance
        } else {
          return {};  // No change
        }
      },
    },
    {
      headerName: 'User Name',
      field: 'userName',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
    },
  ])

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    })),
  )

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item,
    )

    setTableData(updatedTableData)
    const updatedColumn = updatedTableData.filter((item) => item.checked)
    setColumnDefs(updatedColumn)
  }
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>All Games</h6>
        <div className="right-area">
          <SelectInput
            listArray={options}
            defaultOption="10"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : '10')
            }}
          />

          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData || [{}]}
            loading={loading}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  )
}
export default BetHistoryTable
