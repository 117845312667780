/* eslint-disable jsx-a11y/img-redundant-alt */
import SelectInput from '../global/selectInput'
import TableColumns from '../global/tableColumns'
import GlobalTable from '../global/globalTable'
import { useEffect, useState } from 'react'
import {
  getAllGameCategory,
  getAllGames,
} from '../../utils/Apis'
import Pagination from '../../utils/Pagination'
import { useMyContect } from '../../context/myContext'
import { Edit } from '@mui/icons-material'
import UpdateGamePopUp from './updateGamePopup'
import { paginationDropdownOptions } from './constants'
import { adminInstance } from '../../config/axios'

const GameTable = ({ recall, filter, setRecall }) => {
  const { isLoading } = useMyContect()
  const [loading, setLoading] = useState(false)
  const [addUsers, setAddUsers] = useState('')
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState('')
  const [pageSize, setPageSize] = useState('10')
  const [rowData, setRowData] = useState([])
  const [selectedGameDetails, setSelectedGameDetails] = useState()
  const [allCategory, setAllCategory] = useState([])

  const getAllGameData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('accToken')
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        }

        setLoading(true)
        const res = await getAllGames(config)
        setLoading(false)
        if (res?.data) {
          setRowData(res?.data?.games || [])
          setPages(res?.data.totalPages)
        }
      } else {
        console.log('No token found')
      }
    } catch (error) {
      console.log('Error found while fetching data', error)
    } finally {
      setLoading(false)
    }
  }
  const getAllCategory = async () => {
    try {
      const token = localStorage.getItem('accToken')
      if (token) {
        const res = await getAllGameCategory()
        if (res?.data) {
          setAllCategory(res?.data?.categories)
        }
      } else {
        console.log('No token found')
      }
    } catch (error) {
      console.log('Error found while fetching data', error)
    }
  }
  useEffect(() => {
    getAllCategory()
  }, [])
  useEffect(() => {
    getAllGameData(page, pageSize)
    // eslint-disable-next-line
  }, [pageSize, page, filter, recall])

  const actionCellRenderer = (data) => {
    return (
      <div className="users-action-buttons">
        <button
          onClick={
            () => {
              adminInstance().post('/v1/admin/agent/change-games-status', {
                gameId: data.master_casino_game_id,
                isActive: !data.is_active
              }).then(res => {
                if (res.status === 200) {
                  setRecall(p => p + 1)
                }
              })
              setSelectedGameDetails(data)
            }
          }
        >
          {data.is_active ? "Deactivate" : "Activate"}
        </button>
        <button
          onClick={
            () => {
              setAddUsers('edit')
              setSelectedGameDetails(data)
            }
          }
          className="edit-agent-button"
        >
          <Edit fontSize="10" />
        </button>
      </div>
    )
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Game Image',
      field: 'thumbnail_url', // or replace with the actual field that holds the image URL
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => {
        const imageUrl = params.value || ''
        return imageUrl ? (
          <img
            src={imageUrl}
            alt="Game Image"
            style={{ width: '50px', height: '50px' }}
          />
        ) : (
          '-'
        )
      },
    },
    {
      headerName: 'Game ID',
      field: 'identifier',
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      sort: 'desc',
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (params) => params.value || '-',
    },

    {
      headerName: 'Game Name',
      field: 'name',
      minWidth: 200,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
    },
    {
      headerName: 'Category Name',
      field: 'sub_category_name',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
    },
    {
      headerName: 'Provider Name',
      field: 'provider_name',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
    },
    {
      headerName: 'Game Status',
      field: 'is_active',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => {
        if (params.value === true) {
          return 'Active'
        } else if (params.value === false) {
          return 'Inactive'
        }
        return '-'
      },
      cellStyle: (params) => {
        if (params.value === true) {
          return { color: 'green', fontWeight: 'bold' }
        } else if (params.value === false) {
          return { color: 'red', fontWeight: 'bold' }
        }
        return {}
      },
    },

    {
      headerName: 'Actions',
      flex: 1,
      minWidth: 250,
      field: 'actionButton',
      cellRenderer: (item) => actionCellRenderer(item?.data),
    },
  ])

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    })),
  )

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item,
    )

    setTableData(updatedTableData)
    const updatedColumn = updatedTableData.filter((item) => item.checked)
    setColumnDefs(updatedColumn)
  }


  // const handleNewUser = async (values) => {
  //   try {
  //     setLoading(true);
  //     console.log(addUsers, selectedUserDetails);
  //     const value = user?.agentId?.toUpperCase();
  //     delete values.confirmPassword;
  //     if (addUsers === "edit") {
  //       values.id = selectedUserDetails?.id;
  //       delete values.userName;
  //     }
  //     const res =
  //       addUsers === "create"
  //         ? await createUser({ ...values, agentId: value })
  //         : await UpdateUser({ ...values });
  //     console.log("[Response]", res);
  //     if (res.status === 201 || res.status === 200) {
  //       setLoading(false);
  //       toast.success(
  //         `${
  //           addUsers.charAt(0).toUpperCase() + addUsers.slice(1)
  //         } user successfully`
  //       );
  //       setAddUsers("");
  //       setSelectedUserDetails({});
  //       getAllGameData(page, pageSize);
  //     } else {
  //       toast.error(res.message);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     return toast.error("Something went wrong!");
  //   }
  // };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>All Games</h6>
        <div className="right-area">
          {/* <button
            className="add-agent-button"
            onClick={() => setAddUsers("create")}
          >
            Create User
          </button> */}
          {/* <ExportExcel rowData={rowData} tableData={tableData} /> */}
          <SelectInput
            listArray={paginationDropdownOptions}
            defaultOption="10"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : '10')
            }}
          />

          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData || [{}]}
            loading={loading}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
      <UpdateGamePopUp
        loading={loading}
        show={addUsers}
        handleClose={() => setAddUsers(false)}
        handleNewUser={() => { }}
        allCategory={allCategory}
        selectedGameDetails={selectedGameDetails}
        setRecall={setRecall}
      />
    </div>
  )
}
export default GameTable
