import { Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import ButtonWrapper from "../global/button";
import { useEffect, useState } from "react";
import { useMyContect } from "../../context/myContext";
import toast from "react-hot-toast";
import { addUpdatePaymentRestrictionGroup } from "../../utils/Apis";

const GroupAllowedPopup = ({
  show,
  handleClose,
  setRefreshGroup,
  groupdata,
  cpu,
}) => {
  const { user } = useMyContect();

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    deposit: "Deposit",
    paymentProvider: "",
    paymentChannel: "",
    userProfileGroup: "",
    creatorName: "",
    creatorId: "",
    status: "Active",
  });
  const handleChange = (e) => {
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await addUpdatePaymentRestrictionGroup({
      body: fields,
      restGroupId: fields?.id || "",
    })
      .then((result) => {
        if (result?.data?.status === 200) {
          setRefreshGroup(Math.random());
          toast.success(result?.data?.message);
          handleClear();
        } else {
          toast.error(result?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setFields((prevState) => ({
      ...prevState,
      creatorName: user?.firstName,
      creatorId: user?.id,
    }));
  }, [user]);

  useEffect(() => {
    if (groupdata) {
      setFields((prevState) => ({
        ...prevState,
        id: groupdata?.ID,
        deposit: groupdata?.Direction,
        paymentProvider: groupdata["Payment Provider Name"],
        userProfileGroup: groupdata["Group Name"],
      }));
    } else {
      setFields((prevState) => ({
        ...prevState,
        id: "",
        deposit: "Deposit",
        paymentProvider: "",
        userProfileGroup: "",
      }));
    }
  }, [groupdata]);

  const handleClear = () => {
    setFields({
      deposit: "Deposit",
      paymentProvider: "",
      paymentChannel: "",
      userProfileGroup: null,
      creatorName: user?.firstName,
      creatorId: user?.id,
      status: "Active",
    });
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup group-allowed-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{groupdata ? "Edit" : "Add"} Group Allowed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <form onSubmit={handleSubmit}>
            <div className="row-one">
              <SelectInput
                defaultOption={"Deposit"}
                isRequired={true}
                listArray={[{ value: "Widthdraw" }]}
                name={"deposit"}
                selectedValue={fields?.deposit}
                handleSelectOption={handleChange}
              />
              <SelectInput
                defaultOption={"Payment Provider"}
                listArray={cpu?.providers}
                isRequired={true}
                name={"paymentProvider"}
                selectedValue={fields?.paymentProvider}
                handleSelectOption={handleChange}
              />
              <SelectInput
                defaultOption={"Payment Channel"}
                listArray={cpu?.channels}
                isRequired={true}
                name={"paymentChannel"}
                selectedValue={fields?.paymentChannel}
                handleSelectOption={handleChange}
              />
              <SelectInput
                defaultOption={"User Profile Group"}
                listArray={cpu?.usergroups}
                name={"userProfileGroup"}
                isRequired={true}
                selectedValue={fields?.userProfileGroup}
                handleSelectOption={handleChange}
              />
            </div>

            <div className="payment-btn">
              <ButtonWrapper
                loading={loading}
                type={"submit"}
                text="Save"
                // handleClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default GroupAllowedPopup;
