import { useState } from "react";
import GlobalTable from "../../global/globalTable";

const StatisticsTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "User ID", minWidth: 150, flex: 1 },
    { field: "User Name", minWidth: 150, flex: 1 },
    { field: "Currency", minWidth: 150, flex: 1 },
    {
      field: "Balance",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Deposit",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Withdraw",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Payment Diff",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Bet",
      sortable: true,
      minWidth: 150,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Win",
      sortable: true,
      minWidth: 150,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Total Profit",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "NGR",
      sortable: true,
      minWidth: 150,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Casino Bet",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Casino Win",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Casino Profit",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "LiveCasino Bet",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "LiveCasino Win",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "LiveCasino Profit",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Sport Bet",
      sortable: true,
      minWidth: 150,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Sport Win",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Sport Profit",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Other Bet",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Other Win",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Other Profit",
      sortable: true,
      minWidth: 180,
      flex: 1,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
  ];

  return (
    <div className="deposit-table">
      <h2>Vendor Statistics</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default StatisticsTable;
