import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
import { useState } from "react";
// import SwitchBox from "./switchBox";
// import FilterDatePicker from "../global/filterDatePicker";
const Filter = ({ setRecall, setFilter, filter }) => {
  const [showAllFilter, setShowAllFilter] = useState(false);

  const CountryOptions = [
    { id: 1, value: "india" },
    { id: 1, value: "US" },
  ];

  const kycOptions = [
    { id: 1, value: "Verified" },
    { id: 1, value: "Not Verified" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    console.log(filter);
  };

  const renderInput = (label, name, value, type) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type={type ? type : "text"}
        placeholder=""
        autoComplete="off"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FloatingLabel>
  );

  const renderSelectInput = (label, name, listArray, value) => (
    <SelectInput
      defaultOption={label}
      name={name}
      listArray={listArray}
      selectedValue={value}
      handleSelectOption={handleChange}
    />
  );
  const handleClear = () => {
    setFilter({
      id: "",
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      country: "",
      city: "",
      address: "",
      iskycCompleted: "",
      fromBalance: "",
      toBalance: "",
      page: "",
      pageSize: "",
    });
    setRecall(Math.random());
  };
  // const emailInputRef = useRef(null);
  // const handleSpanClick = () => {
  //   if (emailInputRef.current) {
  //     emailInputRef.current.focus();
  //   }
  // };

  return (
    <div className="filter-section-user user-top-icon" style={{}}>
      <div className="filter-section mt-3 mb-4">
        {renderInput("User ID", "id", filter?.id)}
        {renderInput("User Name", "userName", filter?.userName)}
        {renderInput("Email", "email", filter?.email)}
        {/* <SwitchBox
          emailInputRef={emailInputRef}
          handleSpanClick={handleSpanClick}
          handleToggle={(item) => {
            setFilter((prevState) => ({
              ...prevState,
              status: item === true ? "Yes" : "No",
            }));
          }}
        /> */}
        {renderInput("First Name", "firstName", filter?.firstName)}
        {showAllFilter && (
          <>
            {renderInput("Last Name", "lastName", filter?.lastName)}
            {renderInput("Mobile", "mobile", filter?.mobile)}
            {/* {renderInput("Mobile Code", "mobile", filter?.mobile)} */}
            {renderSelectInput(
              "Country",
              "country",
              CountryOptions,
              filter?.country
            )}
            {renderInput("City", "city", filter?.city)}
            {renderInput("Address", "Address")}
            {/* {renderSelectInput("Group", CurrencyOptions)} */}
            {/* {renderInput("PIN", "Group")} */}
            {/* {renderSelectInput("Currency", CurrencyOptions)} */}
            {/* {renderSelectInput("Affiliate Status", CurrencyOptions)} */}
            {/* {renderSelectInput("Gender", CurrencyOptions)} */}
            {/* {renderSelectInput("Account Status", CurrencyOptions)} */}
            {renderSelectInput(
              "KYC Status",
              "iskycCompleted",
              kycOptions,
              filter?.iskycCompleted
            )}
            {renderInput("From Balance", "fromBalance", filter?.fromBalance)}
            {renderInput("To Balance", "toBalance", filter?.toBalance)}
            {/* {renderInput("IP", "ip")} */}
            {/* <FilterDatePicker
              label={"Registration Date From"}
              name={"registtrationdatefrom"}
              flag={true}
            />
            <FilterDatePicker
              label={"Registration Date To"}
              name={"Registrationdateto"}
              flag={true}
            />
            <FilterDatePicker
              label={"Birth Date From"}
              name={"BirthDateFrom"}
            />
            <FilterDatePicker label={"Birth Date To"} name={"BirthDateTo"} />
            <FilterDatePicker
              label={"Last Login Date From"}
              name={"LastLoginDateFrom"}
              flag={true}
            />
            <FilterDatePicker
              label={"Last Login Date To"}
              name={"LastLoginDateTo"}
              flag={true}
            />
            {renderSelectInput("Registration Status", CurrencyOptions)} */}
          </>
        )}

        <div className="filter-section m-0">
          <ButtonWrapper
            text="Search"
            clValue="search-filter-btn"
            handleClick={() => {
              setRecall(Math.random());
            }}
          />
          <ButtonWrapper
            text="Clear All"
            clValue="clear-btn"
            handleClick={handleClear}
          />
          <ButtonWrapper
            text={showAllFilter ? "Less Filter" : "More Filter"}
            clValue="less-btn"
            handleClick={() => setShowAllFilter(!showAllFilter)}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
