import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const CountryTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "Country", minWidth: 150, flex: 1 },
    {
      field: "Active Players",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Registrations",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Deposit Count",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "FTD Count",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Deposit Amount",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Withdraw Amount",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "GGR",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Bonus Amount",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "NGR",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Balance",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Country Financial Statistics</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default CountryTable;
