import { useState, useEffect } from "react";
import GlobalTable from "../../global/globalTable";
import { getAllSocial } from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import Pagination from "../../../utils/Pagination";
import socialIcon from "../../../assets/socialIcon.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Modal } from "react-bootstrap";
const SocialTable = ({ columnDefs, recall }) => {
  const { isLoading } = useMyContect();
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const pageSize = 20;
  const handleFetch = async (page) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        const res = await getAllSocial(config);
        if (res) {
          if (res && res.data && res.data.data) {
            const transformedData = res.data.data.map((item) => ({
              ...item,
              image: item?.image || socialIcon,
              opensInNewTab: item?.opensInNewTab === true ? "True" : "False",
            }));
            setRowData(transformedData);
            setPages(res?.data?.pages);
          }
        }
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };
  useEffect(() => {
    handleFetch(page);
  }, [page, isLoading, recall]);
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Social</h6>
      </div>
      <GlobalTable
        columnDefs={columnDefs.map((colDef) =>
          colDef.field === "image"
            ? { ...colDef, cellRenderer: RenderImage }
            : colDef
        )}
        rowData={rowData}
      />
      <Pagination
        currentPage={page}
        totalPages={pages}
        onPageChange={setPage}
      />
    </div>
  );
};
const RenderImage = (params) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const handleDownload = () => {
    const splitArr = params?.value.split("/");
    const fileName = splitArr[splitArr.length - 1];
    fetch(params?.value)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <>
      <img
        className="table-image"
        role="button"
        onClick={handleClick}
        src={params.value}
        alt="Social Icon"
      />
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        centered
        // className="notification-popup add-payment-popup add-commission-popup"
      >
        <Modal.Body>
          {/* {console.log("image value>>>>", params?.value)} */}
          <img
            className="image-preview"
            src={params?.value}
            alt="Social Icon"
          />
          <div className="text-center mt-2">
            {/* Add a download button */}
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                handleDownload();
              }}
            >
              Download <CloudDownloadIcon />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SocialTable;
