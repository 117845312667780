import ExportExcel from "../global/exportExcel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useEffect, useState } from "react";
import { useMyContect } from "../../context/myContext";
import { depositRequestsData } from "../../utils/Apis";
import GlobalTable from "../global/globalTable";
import Pagination from "../../utils/Pagination";

const DepositTable = () => {
  const [rowData, setRowData] = useState([]);
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  // const [limit] = useState(20);
  const [pages, setPages] = useState("");
  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "userId",
      headerName: "User Id",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "left" },
    },
    {
      field: "usdAmount",
      headerName: "USD Amount",
      minWidth: 100,
      flex: 1,
      // cellStyle: { "text-align": "center"  },
      // type: 'center'
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "userName",
      headerName: "User Name",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "brand",
      headerName: "Brand",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "status",
      headerName: "Active",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
  ]);
  const [tableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );
  const handleGetDepositData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
        };
        setRowData([]);
        setLoading(true);
        const res = await depositRequestsData(config);
        console.log("res", res);

        if (res) {
          setLoading(false);
          if (res && res.data && res.data.data) {
            const transformedData = res.data?.data.map((item) => ({
              ...item,
              status: item.status === "complete" ? "Completed" : item.status,
              userId: item?.userId || "---",
              usdAmount:
                typeof item?.usdAmount === "number"
                  ? parseFloat(item.usdAmount)?.toFixed(2)
                  : "---",
              brand: localStorage.getItem("brand"),
              userName: item?.userName || "---",
              firstName: item?.firstName || "---",
              lastName: item?.lastName || "---",

              id: item?.id ? item?.id : "---",
            }));
            setPages(res.data.pages);
            setRowData(transformedData);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetDepositData();
  }, [isLoading, page]);

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>
          <p className="m-0 p-0 dollar-icon">
            {" "}
            <AttachMoneyIcon />{" "}
          </p>{" "}
          Deposit Requests <span>0</span>
        </h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
export default DepositTable;
