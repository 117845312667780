import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { paginationDropdownOptions } from "../constants";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";
import GlobalTable from "../../global/globalTable";
import Pagination from "../../../utils/Pagination";

import { useMyContect } from "../../../context/myContext";
import { getAllGameBySubCategory } from "../../../utils/Apis";

const GameListByCategory = ({ show, handleClose,selectedCategory,rowGameData,setRowGameData }) => {
  console.log("Selected Category>>>",selectedCategory)
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const [pageSize, setPageSize] = useState("10");
 

  const getAllGameByCategory = async (page, pageSize) => {
    try {
      setRowGameData([])
      const token = localStorage.getItem('accToken')
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
            categoryId:selectedCategory?.data?.master_game_sub_category_id
          },
        }

        setLoading(true)
        const res = await getAllGameBySubCategory(config)
        setLoading(false)
        if (res?.data) {
          setRowGameData(res?.data?.games || [])
          setPages(res?.data.numberOfPages)
        }
      } else {
        console.log('No token found')
      }
    } catch (error) {
      console.log('Error found while fetching data', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAllGameByCategory(page, pageSize)
    // eslint-disable-next-line
  }, [pageSize, page])
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Game ID",

      field: "master_casino_game_id",

      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Game Name",
      field: "game_name",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Category ",
      field: "category_name",
      minWidth: 180,
      flex: 1,
      cellRenderer:  (params) => params.value || "-",
    },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const fetchData = () => {
    setLoading(true);
    setPages(1);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  console.log("pageSize", pageSize);
  useEffect(() => {
    fetchData();
    // To remove warnings
  }, []);

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose} // Call handleClose directly
      size="xl"
      centered
      className=""
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Games List</Modal.Title> */}
      </Modal.Header>

      <Modal.Body>
        <div className="">
          <div className="top-area">
            <h6>All Games</h6>
            <div className="right-area">
              <SelectInput
                listArray={paginationDropdownOptions}
                defaultOption="10"
                handleSelectOption={(e) => {
                  setPageSize(e.target.value ? e.target.value : "10");
                }}
              />

              <TableColumns tableData={tableData} checkedBox={checkedBox} />
            </div>
          </div>
          {isLoading || loading ? (
            <div className="spinner my-5"></div>
          ) : (
            <>
              <GlobalTable
                columnDefs={columnDefs}
                rowData={rowGameData || [{}]}
                loading={loading}
              />
              <Pagination
                currentPage={page}
                totalPages={pages}
                onPageChange={setPage}
              />
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GameListByCategory;
