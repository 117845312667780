import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import ButtonWrapper from "../global/button";
import {
  addUpdateCommissionStage,
  deleteCommissionStage,
} from "../../utils/Apis";
import toast from "react-hot-toast";
import { useMyContect } from "../../context/myContext";

const AddCommissionStage = ({ show, handleClose, setRecall, showPopup }) => {
  const { data } = useMyContect();
  const [loading, setLoading] = useState(false);
  const commissionOptions = [
    {
      id: 1,
      value: "Global",
    },
    {
      id: 2,
      value: "Per Game",
    },
  ];

  const initialFields = {
    commissionStageId: "",
    stageType: "Global",
    gameType: "",
    levelName: "",
    ngrFrom: "",
    ngrTo: "",
    commissionIncrease: "",
  };

  const [formData, setFormData] = useState(initialFields);

  useEffect(() => {
    if (data && showPopup === "Edit") {
      setFormData({
        commissionStageId: data?.id,
        stageType: data?.commissionType ?? "Global",
        gameType: data?.gameType,
        levelName: data?.levelName,
        ngrFrom: data?.ngrFrom,
        ngrTo: data?.ngrTo,
        commissionIncrease: data?.commissionIncrease,
      });
    } else {
      setFormData(initialFields);
    }
    // eslint-disable-next-line
  }, [data, showPopup]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderFloatingLabel = (
    label,
    controlId,
    name,
    value,
    type = "text",
    disabled = false
  ) => (
    <FloatingLabel controlId={controlId} label={label}>
      <Form.Control
        type={type}
        placeholder="name@example.com"
        autoComplete="off"
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleChange}
      />
    </FloatingLabel>
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const result = await addUpdateCommissionStage(formData);

      if (result?.data?.status === 200) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.data?.message);
      }
    } catch (err) {
      toast.error(err.message || err.data.message);
    } finally {
      handleClose();
      handleClear();
      setRecall(Math.random());
      setLoading(false);
    }
  };

  const handleClear = () => {
    setFormData(initialFields);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("accToken");
    try {
      if (token) {
        setLoading(true);
        const res = await deleteCommissionStage(data?.id);
        if (res) {
          toast.success(res.data.message);
          setLoading(true);
          setRecall(Math.random());
          handleClose();
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="notification-popup add-payment-popup add-commission-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Commission Stage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showPopup === "Delete" ? (
          <>
            <div className="">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="auto-btn">
              <ButtonWrapper
                text={loading ? "Deleting..." : "Delete"}
                loading={loading}
                handleClick={handleDelete}
              />
            </div>
          </>
        ) : (
          <>
            <div className="tabs-section">
              <div className="row-one">
                <SelectInput
                  listArray={commissionOptions}
                  selectedValue={formData.stageType ?? "Global"}
                  name={"stageType"}
                  handleSelectOption={handleChange}
                />
                {renderFloatingLabel(
                  "",
                  "floatingInput",
                  "gameType",
                  formData?.gameType,
                  "text",
                  true
                )}
              </div>
              <div className="row-one">
                {renderFloatingLabel(
                  "Level Name",
                  "floatingInput",
                  "levelName",
                  formData?.levelName
                )}
                {renderFloatingLabel(
                  "NGR From",
                  "floatingInput",
                  "ngrFrom",
                  formData?.ngrFrom,
                  "number"
                )}
                {renderFloatingLabel(
                  "NGR To",
                  "floatingInput",
                  "ngrTo",
                  formData?.ngrTo,
                  "number"
                )}
                {renderFloatingLabel(
                  "Commission Increase",
                  "floatingInput",
                  "commissionIncrease",
                  formData?.commissionIncrease,
                  "number"
                )}
              </div>
              <div className="payment-btn">
                <ButtonWrapper
                  text="Add"
                  handleClick={handleSubmit}
                  loading={loading}
                />
                <ButtonWrapper
                  text="Clear All"
                  clValue="clear-btn"
                  handleClick={handleClear}
                />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddCommissionStage;
