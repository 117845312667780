import React, { useEffect, useState } from "react";
import { Table, FloatingLabel, Form } from "react-bootstrap";
import {
  getAllNotificationTemplate,
  getAllNotifications,
} from "../../utils/Apis";
import ButtonWrapper from "../global/button";
import Pagination from "../../utils/Pagination";

const NotificationTable = ({
  activeTab1,
  isClick,
  filteritem,
  setNotiData,
  refreshNoti,
}) => {
  const [filter, setFilter] = useState({
    fromDate: filteritem.fromDate,
    endDate: filteritem.endDate,
    templateId: "",
    userId: "",
  });

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "userId" || e.target.name === "templateId") {
      if (value.includes("-") || /[^\w\s]/.test(value)) {
        return;
      }
    }
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await getAllNotifications({
      page,
      timePeriod: activeTab1?.toLocaleLowerCase()?.replace(/\s+/, "_"),
      body: filter,
    })
      .then((result) => {
        setData(result?.data?.data);
        setPages(result?.data?.pages);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    /*eslint-disable */
  }, [page, activeTab1, isClick, refreshNoti]);

  // console.log(filter);
  return (
    <React.Fragment>
      <div
        className={`datepicker1 text-center filter-Tab filter-section notification-filter-tab active-tab-noti`}
      >
        <FloatingLabel controlId="templateId" label="Template Id">
          <Form.Control
            type="number"
            placeholder="Template Id"
            name="templateId"
            value={filter.templateId}
            onChange={handleChange}
          />
        </FloatingLabel>
        <FloatingLabel controlId="userId" label="UserId">
          <Form.Control
            type="number"
            placeholder="userId"
            name="userId"
            value={filter.userId}
            onChange={handleChange}
          />
        </FloatingLabel>

        <Form.Select name="All" value={filter?.all} onChange={handleChange}>
          <option value={""}>All</option>
          <option>Deleted</option>
          <option>Not Deleted</option>
        </Form.Select>
        <ButtonWrapper text="filter" handleClick={getData} />
      </div>
      {loading ? (
        <div className="spinner mt-5 pt-5"></div>
      ) : data?.length === 0 ? (
        <h4 className="text-center mt-5 pt-5">No Data Found</h4>
      ) : (
        <div className="deposit-table">
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Create Date</th>
                <th>Admin Name</th>
                <th>Template Type</th>
                <th>Template Name</th>
                <th>Template ID</th>
                <th>User ID</th>
                <th>User Group Name</th>
                <th>Date From</th>
                <th>Date End</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index} role="button" onClick={() => setNotiData(item)}>
                  <td>{item.id || "---"}</td>
                  <td>{item.createdAt?.toString()?.split("T")[0] || "---"}</td>
                  <td>{item.creatorName || "---"}</td>
                  <td>{item.type || "---"}</td>
                  <td>{item.templateName || "---"}</td>
                  <td>{item.templateId || "---"}</td>
                  <td>{item.userId || "---"}</td>
                  <td>{item.userGroup || "---"}</td>
                  <td>{item.dateFrom?.toString()?.split("T")[0] || "---"}</td>
                  <td>{item.dateEnd?.toString()?.split("T")[0] || "---"}</td>
                  <td>{item.status || "---"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <Pagination
        currentPage={page}
        totalPages={pages}
        onPageChange={setPage}
      />
    </React.Fragment>
  );
};
export default NotificationTable;

export const TemplateTable = ({ refreshTemp, setTempData }) => {
  const [filter, setFilter] = useState({
    language: "",
    showinOrders: "",
    showinKyc: "",
    autoSendMail: "",
    templateName: "",
    creatorName: "",
    status: "",
  });
  const handleChange = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setPage(1);
    setLoading(true);
    await getAllNotificationTemplate({
      page,
      body: filter,
    })
      .then((result) => {
        setData(result?.data?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, [page, refreshTemp]);
  return (
    <React.Fragment>
      <div
        className={`datepicker1 filter-Tab filter-section notification-filter-tab active-tab-noti`}
      >
        <Form.Select
          name="language"
          value={filter?.language}
          onChange={handleChange}
        >
          <option value={""}>Languages</option>
          <option value="en-US">en-US</option>
        </Form.Select>
        <Form.Select
          name="showinOrders"
          value={filter?.showinOrders}
          onChange={handleChange}
        >
          <option value={""}>Showing Orders</option>
          <option>Yes</option>
          <option>No</option>
        </Form.Select>
        <Form.Select
          name="showinKyc"
          value={filter?.showinKyc}
          onChange={handleChange}
        >
          <option value={""}>Show In Kyc</option>
          <option>Yes</option>
          <option>No</option>
        </Form.Select>
        <Form.Select
          name="autoSendMail"
          value={filter?.autoSendMail}
          onChange={handleChange}
        >
          <option value={""}>Auto Send (Email)</option>
          <option>Yes</option>
          <option>No</option>
        </Form.Select>
        <FloatingLabel controlId="floatingInput" label="Template Name">
          <Form.Control
            type="text"
            placeholder="Template Name"
            name="templateName"
            value={filter?.templateName}
            onChange={handleChange}
          />
        </FloatingLabel>
        <ButtonWrapper text="filter" handleClick={getData} />
      </div>
      {loading ? (
        <div className="spinner mt-5 pt-5"></div>
      ) : data?.length === 0 ? (
        <h4 className="text-center mt-5 pt-5">No Data Found</h4>
      ) : (
        <div className="deposit-table">
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Admin Name</th>
                <th>Template Name</th>
                <th>Create Date</th>
                <th>Show In Orders</th>
                <th>Show In Kyc</th>
                <th>Auto Send (Email)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index} role="button" onClick={() => setTempData(item)}>
                  <td>{item.id}</td>
                  <td>{item.creatorName}</td>
                  <td>{item.templateName}</td>
                  <td>{item.createdAt?.toString()?.split("T")[0]}</td>
                  <td>{item.showinOrders}</td>
                  <td>{item.showinKyc}</td>
                  <td>{item.autoSendMail}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
};
