import { useState } from "react";
import GlobalTable from '../../global/globalTable';
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const ActivityTable = () => {
    const [rowData] = useState([]);
    const [columnDefs] = useState([
      { field: "User ID" ,minWidth: 150, flex: 1},
      { field: "User Name" ,minWidth: 150, flex: 1},
      { field: "Registration time",minWidth: 180, flex: 1 },
      { field: "Country",minWidth: 150, flex: 1 },
      { field: "FDT",minWidth: 150, flex: 1 },
      { field: "GGR",minWidth: 150, flex: 1 },
      { field: "First Deposit Date",minWidth: 180, flex: 1 },
      { field: "Deposit Amount",minWidth: 180, flex: 1 },
      { field: "Deposit Count",minWidth: 180, flex: 1 },
      { field: "Average Deposit Amount",width:"200px" },
      { field: "Last Deposit Date",width:"200px" },
      { field: "Withdraw Amount",minWidth: 180, flex: 1 },
      { field: "Withdraw Count",minWidth: 180, flex: 1 },
      { field: "Average Withdraw Amount",minWidth: 180, flex: 1 },
      { field: "Bonus Amount",minWidth: 180, flex: 1},
      { field: "Bonus Count",minWidth: 180, flex: 1},
    ]);
    
    let options = [
  
        {
          id: 1,
          value: 50,
        },
        {
          id: 1,
          value: 100,
        },
      ];
    return (
      <div className="deposit-table">
      <div className="top-area">
         <h6>ACTIVITY</h6>
       <div className="right-area">
       <ExportExcel />
        <SelectInput listArray={options} defaultOption="20" />
        <TableColumns tableData={columnDefs}/>
       </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default ActivityTable