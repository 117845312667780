import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
// import SelectInput from "../global/selectInput";
// import SwitchBox from "./switchBox";
// import FilterDatePicker from "../global/filterDatePicker";

const Filter = ({ setRecall, setFilter, filter }) => {
  // const [showAllFilter, setShowAllFilter] = useState(false);

  // const CountryOptions = [
  //   { id: 1, value: "india" },
  //   { id: 1, value: "US" },
  // ];

  // const kycOptions = [
  //   { id: 1, value: "Verified" },
  //   { id: 1, value: "Not Verified" },
  // ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderInput = (label, name, value, type) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type={type ? type : "text"}
        placeholder=""
        autoComplete="off"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FloatingLabel>
  );

  // const renderSelectInput = (label, name, listArray, value) => (
  //   <SelectInput
  //     defaultOption={label}
  //     name={name}
  //     listArray={listArray}
  //     selectedValue={value}
  //     handleSelectOption={handleChange}
  //   />
  // );

  const handleClear = () => {
    setFilter({
      id: "",
      gameName: "",
      page: "",
      pageSize: "",
    });
    setRecall(Math.random());
  };
  // const emailInputRef = useRef(null);
  // const handleSpanClick = () => {
  //   if (emailInputRef.current) {
  //     emailInputRef.current.focus();
  //   }
  // };

  return (
    <div className="filter-section-user user-top-icon" style={{}}>
      <div className="filter-section mt-3 mb-4">
        {renderInput("Game ID", "id", filter?.id)}
        {renderInput("Game Name", "gameName", filter?.gameName)}
        {renderInput("User Name", "userName", filter?.userName)}
        <div className="filter-section m-0">
          <ButtonWrapper
            text="Search"
            clValue="search-filter-btn"
            handleClick={() => {
              setRecall(Math.random());
            }}
          />
          <ButtonWrapper
            text="Clear All"
            clValue="clear-btn"
            handleClick={handleClear}
          />
          {/* <ButtonWrapper
            text={showAllFilter ? "Less Filter" : "More Filter"}
            clValue="less-btn"
            handleClick={() => setShowAllFilter(!showAllFilter)}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Filter;
