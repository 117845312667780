import { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../../global/globalTable";

const CashdeskTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "Date", minWidth: 180, flex: 1 },
    { field: "Deposit", minWidth: 180, flex: 1 },
    { field: "Correction", minWidth: 180, flex: 1 },
    { field: "Free Money", minWidth: 180, flex: 1 },
    { field: "Withdraw", minWidth: 180, flex: 1 },
    { field: "Currency", minWidth: 180, flex: 1 },
  ]);

  const [columnDefs1] = useState([
    { field: "Time", minWidth: 180, flex: 1 },
    { field: "User ID", minWidth: 180, flex: 1 },
    { field: "User Name", minWidth: 180, flex: 1 },
    { field: "Amount", minWidth: 180, flex: 1 },
    { field: "Transaction ID", minWidth: 180, flex: 1 },
    { field: "Type", minWidth: 180, flex: 1 },
    { field: "Admin", minWidth: 180, flex: 1 },
    { field: "Comment", minWidth: 180, flex: 1 },
  ]);

  return (
    <div className="cashdesk-table-section">
      <div className="deposit-table">
        <GlobalTable columnDefs={columnDefs} rowData={rowData} />
      </div>
      <div className="deposit-table">
        <GlobalTable columnDefs={columnDefs1} rowData={rowData} />
      </div>
    </div>
  );
};
export default CashdeskTable;
