import Layout from "../../layout/layout";
import "./style.css";
import Filter from "./Filter";
import { useState } from "react";
import TransactionTable from "./TransactionTable";

// Fetch players data using useEffect
// eslint-disable-next-line react-hooks/rules-of-hooks

const Transactions = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    id: "",
    userName: "",
    dateCreated: "",
    level: "",
    manager: "",
    beforeBalance: "",
    afterBalance: "",
    actionBy: "",
    page: "",
    pageSize: "",
  });

  return (
    <Layout title="Transactions">
      <div className="users-page">
        <Filter setRecall={setRecall} setFilter={setFilter} filter={filter} />
        <TransactionTable filter={filter} recall={recall} />
      </div>
    </Layout>
  );
};
export default Transactions;
