import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  createNewSubCategory,
  GetGamesForSelectedCategory,
  updateSubCategory,
} from '../../../utils/Apis'
import toast from 'react-hot-toast'
import { MultiSelect } from 'react-multi-select-component'
const CategoryPopUp = ({
  show,
  handleClose,
  type,
  onCategorySubmit,
  selectedCategory,
}) => {
  const [allGames, setAllGames] = useState([])
  const [selectedGame,setSelectedGame]=useState([])
  // Form validation schema
  const validationSchema = Yup.object().shape({
    rank: Yup.number()
      .required('Category rank is required')
      .positive('Rank must be a positive number')
      .integer('Rank must be an integer'),
    name: Yup.string()
      .required('Category name is required')
      .min(3, 'Category name must be at least 3 characters'),
  })
  const getAllGames = async () => {
    try {
      const token = localStorage.getItem('accToken')
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            categoryId: selectedCategory?.master_game_sub_category_id || null,
          },
        }
        const res = await GetGamesForSelectedCategory(config)
        if (res?.data) {
          setAllGames(res?.data?.games || [])
        }
      } else {
        toast.error('Token Not Found', { toastId: 'token not found' })
      }
    } catch (error) {
      console.log('Error found while fetching data', error)
    }
  }
  useEffect(() => {
    getAllGames()
    // eslint-disable-next-line
  }, [show])
  // API call to add or edit a category
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let response
      const token = localStorage.getItem('accToken')
      if (!token) {
        toast.error('Token Not Found', { toastId: 'token not found' })
      }
      const config = {
        headers: {
          DomainToken: `${token}`,
        },
      }

      if (type === 'edit') {
        response = await updateSubCategory({
          ...config,
          body: {
            ...values,
            categoryId: selectedCategory?.master_game_sub_category_id,
            selectedGames:selectedGame
          },
        })
      } else {
        response = await createNewSubCategory({ ...config, body: {...values,selectedGames:selectedGame} })
      }
      if (response.data.successful) {
        toast.success(
          `${
            type === 'edit' ? 'Category updated' : 'Category added'
          } successfully!`,
          { toastId: 'token not found' },
        )
        if (onCategorySubmit) onCategorySubmit() // Refresh categories if needed
        handleClose()
        resetForm()
      } else {
        toast.error(response.data.message || 'Something went wrong.')
      }
    } catch (error) {
      console.error('Error adding/updating category:', error)
      toast.error('Failed to process the request.')
    } finally {
      setSubmitting(false)
    }
  }
  // Default values for edit mode
  const initialValues = {
    rank: type === 'edit' ? selectedCategory?.category_rank || '' : '',
    name: type === 'edit' ? selectedCategory?.name || '' : '',
  }
   console.log("Selected games>>>>",selectedGame)
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="notification-popup add-payment-popup add-commission-popup deposit-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === 'edit' ? 'Edit Category' : 'Add New Category'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="category-modals">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize // Allows resetting the form when initialValues change
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Category Rank</Form.Label>
                  <Form.Control
                    type="text"
                    name="rank"
                    value={values.rank}
                    onChange={handleChange}
                    placeholder="Enter Category Rank"
                    isInvalid={touched.rank && !!errors.rank}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.rank}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    placeholder="Enter Category Name"
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select Games</Form.Label>
                  <MultiSelect
                    options={allGames?.map((el)=>{return {
                      label:el?.game_name,
                      value:el?.master_casino_game_id
                    }})}
                    value={selectedGame}
                    onChange={setSelectedGame}
                    labelledBy={'Select'}
                    isCreatable={true}
                  />
                </Form.Group>

                <div className="deposit-popup-btn">
                  <button
                    type="submit"
                    className="global-btn undefined pd-x btn btn-primary w-100"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? 'Processing...'
                      : type === 'edit'
                      ? 'Save changes'
                      : 'Add'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CategoryPopUp
