import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const PaymentTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "Payment Channel", minWidth: 180, flex: 1 },
    { field: "Payment Provider", minWidth: 180, flex: 1 },
    { field: "Total Deposit", minWidth: 180, flex: 1 },
    { field: "Deposit Count", minWidth: 180, flex: 1 },
    { field: "Depositing Users", minWidth: 180, flex: 1 },
    { field: "FTD	", minWidth: 180, flex: 1 },
    { field: "Chargeback Amount", minWidth: 180, flex: 1 },
    { field: "Chargeback Count", minWidth: 180, flex: 1 },
    { field: "Refund", minWidth: 180, flex: 1 },
    { field: "Total Withdraw", minWidth: 180, flex: 1 },
    { field: "Withdraw Count", minWidth: 180, flex: 1 },
    { field: "Withdrawing Users", minWidth: 180, flex: 1 },
    { field: "FTW", minWidth: 180, flex: 1 },
    { field: "Difference", minWidth: 180, flex: 1 },
    { field: "Currency", minWidth: 180, flex: 1 },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Payment Statistics</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default PaymentTable;
