import { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../../global/globalTable";
import ExportExcel from '../../global/exportExcel'
import SelectInput from '../../global/selectInput'

const DepositTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "User ID",minWidth: 180, flex: 1 },
    { field: "User Name",minWidth: 180, flex: 1 },
    { field: "First Name",minWidth: 180, flex: 1 },
    { field: "Last Name",minWidth: 180, flex: 1 },
    { field: "Country Name",minWidth: 180, flex: 1 },
    { field: "Email",minWidth: 180, flex: 1 },
    { field: "Total Deposit",minWidth: 180, flex: 1 },
    { field: "KYC Status",minWidth: 180, flex: 1 },
  ]);

  return (
    <div className="deposit-table withdraw-table">
      <div className="top-area">
        <h6>Users total deposits</h6>
        <div className="right-area">
        <ExportExcel />
        <SelectInput defaultOption={10} listArray={[{id: 1, value: 20}]} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
export default DepositTable;
