import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const FirstDepositTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "User ID",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    { field: "User Name", minWidth: 180, flex: 1 },
    { field: "Email", minWidth: 180, flex: 1 },
    { field: "Local Amount", minWidth: 180, flex: 1 },
    { field: "Brand Amount", minWidth: 180, flex: 1 },
    { field: "System Amount", minWidth: 180, flex: 1 },
    { field: "Payment Provider", minWidth: 180, flex: 1 },
    { field: "Parent Affiliate", minWidth: 180, flex: 1 },
    { field: "Payment Channel", minWidth: 180, flex: 1 },
    { field: "Transaction ID", minWidth: 180, flex: 1 },
    { field: "Deposit Date", minWidth: 180, flex: 1 },
    { field: "Registration Date", minWidth: 180, flex: 1 },
    { field: "Registration IP", minWidth: 180, flex: 1 },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>First Depositor Statistics</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default FirstDepositTable;
