import ExportExcel from "../global/exportExcel";
// import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import Pagination from "../../utils/Pagination";
import { getAllNotifications } from "../../utils/Apis";
import { useMyContect } from "../../context/myContext";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import TableColumns from "../global/tableColumns";

const Table = ({
  activeTab1,
  isClick,
  filteritem,
  setNotiData,
  refreshNoti,
}) => {
  const [rowData, setRowData] = useState([]);
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
//   const [pageSize, setPageSize] = useState("20");
  const [filter, setFilter] = useState({
    fromDate: filteritem.fromDate,
    endDate: filteritem.endDate,
    templateId: "",
    userId: "",
  });

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "userId" || e.target.name === "templateId") {
      if (value.includes("-") || /[^\w\s]/.test(value)) {
        return;
      }
    }
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "left" },
    },
    {
      field: "creatorName",
      headerName: "Creator Name",
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center"  },
      // type: 'center'
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "templateName",
      headerName: "Template Name",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "templateId",
      headerName: "Template ID",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "userId",
      headerName: "User ID",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "userGroup",
      headerName: "User Group",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "dateFrom",
      headerName: "Data From",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },

    {
      field: "dateEnd",
      headerName: "Date End",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      flex: 1,
      cellRenderer: (item) => actionCellRenderer(item),
      // cellStyle: { "text-align": "center" },
    },
  ]);
  const actionCellRenderer = (params) => {
    const { id, USD } = params.data;
    return (
      <div className="users-action-buttons">
        <button
          onClick={() => setNotiData(id, USD)}
          className="deposit-button "
        >
          View
        </button>
      </div>
    );
  };
  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );
    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };
  const getData = async () => {
    setLoading(true);

    await getAllNotifications({
      page,
      timePeriod: activeTab1?.toLocaleLowerCase()?.replace(/\s+/, "_"),
      body: filter,
    })
      .then((result) => {
        setRowData(result?.data?.data);
        setPages(result?.data?.pages);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    /*eslint-disable */
  }, [page, activeTab1, isClick, refreshNoti]);

  return (
    <>
      <div className="deposit-table notification">
        <div
          className={`datepicker1 text-center filter-Tab filter-section notification-filter-tab active-tab-noti`}
        >
          <FloatingLabel controlId="templateId" label="Template Id">
            <Form.Control
              type="number"
              placeholder="Template Id"
              name="templateId"
              value={filter.templateId}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="userId" label="UserId">
            <Form.Control
              type="number"
              placeholder="userId"
              name="userId"
              value={filter.userId}
              onChange={handleChange}
            />
          </FloatingLabel>

          <Form.Select name="All" value={filter?.all} onChange={handleChange}>
            <option value={""}>All</option>
            <option>Deleted</option>
            <option>Not Deleted</option>
          </Form.Select>
          <ButtonWrapper text="filter" handleClick={getData} />
        </div>
        <div className="top-area">
          <h6>Notifications</h6>
          <div className="right-area">
            <ExportExcel rowData={rowData} tableData={tableData} />
            {/* <SelectInput
              listArray={options}
              defaultOption="20"
              handleSelectOption={(e) => {
                setPageSize(e.target.value ? e.target.value : "20");
              }}
            /> */}

            <TableColumns tableData={tableData} checkedBox={checkedBox} />
          </div>
        </div>
        {isLoading || loading ? (
          <div className="spinner my-5"></div>
        ) : rowData?.length === 0 ? (
          <h4 className="text-center my-5">No Data Found</h4>
        ) : (
          <>
            <GlobalTable
              columnDefs={columnDefs}
              rowData={rowData}
              pageCss={"aghader"}
            />
            <Pagination
              currentPage={page}
              totalPages={pages}
              onPageChange={setPage}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Table;
