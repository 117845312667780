import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
const ProfileDropdown = ({
  profileDropdown,
  handleprofileShow,
  user,
  handleLogout,
}) => {
  return (
    <div className="desktop-header-full-name">
      <div className="login-user" onClick={handleprofileShow}>
        <span className="header-short-name">
          {user?.username?.charAt(0).toUpperCase()}
        </span>
        <span className="header-user-name">{user?.username}</span>
        <span className="material-icons">
          <ArrowBackIosNewIcon />
        </span>
      </div>

      <ul
        style={{
          height: profileDropdown ? "90px" : "0",
          border: profileDropdown
            ? "0.0625rem solid #e3e4eb"
            : "0 solid #e3e4eb",
        }}
      >
        <li>
          <Link to="/profile">Profile</Link>
        </li>
        <li>
          <Link onClick={handleLogout}>Log Out</Link>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
