import Layout from "../layout/layout";
import { useState } from "react";
import GlobalTable from "../global/globalTable";
import SelectInput from "../global/selectInput";
import { Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
// import Checkbox from "@mui/material/Checkbox";
import ExportExcel from "../global/exportExcel";
import FilterInput from "../global/filterInput";

const options = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

const CurrencyOptions = [
  {
    id: 1,
    value: "AED",
  },
];

const AffiliateMonthlyPayment = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Layout title="MONTHLY PAYMENTS">
      <div className="notification-page affiliate-report-page users-page">
        <div className="filter-section">
          <FilterInput label={"Affiliate ID"} name={"AffiliateID"} />
          <FilterInput label={"Year"} name={"AffiliateID"} />
          <div className="form-floating monthly-check">
            <Form.Check
              className=""
              type={"checkbox"}
              id={"1"}
              label={"Paid"}
              name={"Paid"}
              checked={isChecked}
              onChange={handleChange}
            />
          </div>
          <SelectInput defaultOption={"Month"} listArray={CurrencyOptions} />
          <div className="payment-btn">
            <ButtonWrapper text="Search" />
          </div>
        </div>
        <CommissionStageTable />
      </div>
    </Layout>
  );
};

export default AffiliateMonthlyPayment;

const CommissionStageTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    {
      field: "Affiliate ID",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    { field: "User Name", minWidth: 200, flex: 1 },
    { field: "Month", minWidth: 150, flex: 1 },
    {
      field: "Creation Date",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    {
      field: "Commission",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
    },
    { field: "Original Amount", minWidth: 200, flex: 1 },
    { field: "Pay Status", minWidth: 200, flex: 1 },
  ];

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Monthly Payments</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption={10} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
