import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import { useEffect, useState } from "react";
import { useMyContect } from "../../context/myContext";
import { addUpdateRegisterAffiliate } from "../../utils/Apis";
import toast from "react-hot-toast";
const RegisterAffiliatePopup = ({ show, handleClose, setRecall }) => {
  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup content-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Register Affiliate</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <AddAffiliateForm
          handleClose={handleClose}
          setRecall={setRecall}
          show={show}
        />
      </Modal.Body>
    </Modal>
  );
};
export default RegisterAffiliatePopup;

const AddAffiliateForm = ({ editorRef, handleClose, show, setRecall }) => {
  const { data } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [addPopup, setAddPopup] = useState({
    registeredAffiliateId: "",
    userId: data?.id,
    skype: "",
    phone: "",
    playerCount: "",
    averageDeposit: "",
    averageLoss: "",
    website: "",
    facebook: "",
    other: "",
    message: "",
    companyName: "",
    companyAddress: "",
    companyVatNumber: "",
  });

  useEffect(() => {
    if (data?.["AffiliateRegisters.id"]) {
      setAddPopup({
        registeredAffiliateId: data?.["AffiliateRegisters.id"],
        userId: data?.id,
        skype: data?.["AffiliateRegisters.skype"] || "",
        phone: data?.["AffiliateRegisters.phone"] || "",
        playerCount: data?.["AffiliateRegisters.playerCount"] || "",
        averageDeposit: data?.["AffiliateRegisters.averageDeposit"] || "",
        averageLoss: data?.["AffiliateRegisters.averageLoss"] || "",
        website: data?.["AffiliateRegisters.website"] || "",
        facebook: data?.["AffiliateRegisters.facebook"] || "",
        other: data?.["AffiliateRegisters.other"] || "",
        message: data?.["AffiliateRegisters.message"] || "",
        companyName: data?.["AffiliateRegisters.companyName"] || "",
        companyAddress: data?.["AffiliateRegisters.companyAddress"] || "",
        companyVatNumber: data?.["AffiliateRegisters.companyVatNumber"] || "",
      });
    }
    // eslint-disable-next-line
  }, [data]);

  const handleChange = (e) => {
    setAddPopup((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const result = await addUpdateRegisterAffiliate(addPopup);

      if (result?.data?.status === 200) {
        toast.success(result?.data?.message);
        // Call getData() only when status is 200
      } else {
        toast.error(result?.data?.message);
      }
    } catch (err) {
      toast.error(err.message || err.data.message);
    } finally {
      handleClose();
      setRecall(Math.random());
      setLoading(false);
    }
  };

  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      <div className="auto-options1 popup-filter">
        <div>
          <FloatingLabel controlId="skype" label="Skype">
            <Form.Control
              type="text"
              placeholder="Skype"
              autoComplete="off"
              name="skype"
              value={addPopup?.skype}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>

        <div>
          <FloatingLabel controlId="phone" label="Phone">
            <Form.Control
              type="number"
              placeholder="Phone"
              autoComplete="off"
              name="phone"
              value={addPopup?.phone}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="PlayerCount" label="Player Count">
            <Form.Control
              type="number"
              placeholder="Player Count"
              autoComplete="off"
              name="playerCount"
              value={addPopup?.playerCount}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="averageDeposit" label="Average Deposit">
            <Form.Control
              type="number"
              placeholder="Average Deposit"
              autoComplete="off"
              name="averageDeposit"
              value={addPopup?.averageDeposit}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="averageLoss" label="Average Loss">
            <Form.Control
              type="number"
              placeholder="Average Loss"
              autoComplete="off"
              name="averageLoss"
              value={addPopup?.averageLoss}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="website" label="Website">
            <Form.Control
              type="url"
              placeholder="Website"
              autoComplete="off"
              name="website"
              value={addPopup?.website}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="facebook" label="Facebook">
            <Form.Control
              type="text"
              placeholder="Facebook"
              autoComplete="off"
              name="facebook"
              value={addPopup?.facebook}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="other" label="Other">
            <Form.Control
              type="text"
              placeholder="Other"
              autoComplete="off"
              name="other"
              value={addPopup?.other}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="message" label="Message">
            <Form.Control
              type="text"
              placeholder="Message"
              autoComplete="off"
              name="message"
              value={addPopup?.message}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="companyName" label="Company Name">
            <Form.Control
              type="text"
              placeholder="Company Name"
              autoComplete="off"
              name="companyName"
              value={addPopup?.companyName}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel controlId="companyAddress" label="Company Address">
            <Form.Control
              type="text"
              placeholder="Company Address"
              autoComplete="off"
              name="companyAddress"
              value={addPopup?.companyAddress}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
        <div>
          <FloatingLabel
            controlId="companyVatNumber"
            label="Company Vat Number"
          >
            <Form.Control
              type="text"
              placeholder="Company Vat Number"
              autoComplete="off"
              name="companyVatNumber"
              value={addPopup?.companyVatNumber}
              onChange={(e) => handleChange(e)}
            />
          </FloatingLabel>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <ButtonWrapper
          clValue="py-2"
          text={`${"Submit"}`}
          loading={loading}
          handleClick={handleSubmit}
        />
      </div>
    </div>
  );
};
