import { Button, Spinner } from "react-bootstrap";
import "./global.css";
import { ClearAll } from "@mui/icons-material";

const ButtonWrapper = ({
  text,
  loading,
  handleClick,
  clValue,
  icon,
  svgIcon,
  type,
  whiteSpace,
}) => {
  return (
    <Button
      className={`global-btn ${clValue} pd-x`}
      onClick={handleClick}
      type={type}
      disabled={loading}
      style={{
        whiteSpace: whiteSpace ? "nowrap" : "normal",
      }}
    >
      {icon && icon} {text === "Clear All" && <ClearAll />} {svgIcon && svgIcon}{" "}
      {loading ? <Spinner animation="border" /> : text}
    </Button>
  );
};
export default ButtonWrapper;
