import Layout from "../layout/layout";
import { useState } from "react";
import GlobalTable from "../global/globalTable";
import SelectInput from "../global/selectInput";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import ExportExcel from "../global/exportExcel";
import TableColumns from "../global/tableColumns";
const options = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

const AffiliateReport = () => {
  const CurrencyOptions = [{ id: 1, value: "AED" }];

  return (
    <Layout title=" Report">
      <div className="notification-page affiliate-report-page users-page">
        <div className="filter-section">
          {["From date", "To Date"].map((label, index) => (
            <FloatingLabel
              key={index}
              controlId={`floatingInput-${index}`}
              label={label}
            >
              <Form.Control
                type="date"
                placeholder={`Enter ${label.toLowerCase()}`}
                autoComplete="off"
                name={label.toLowerCase().replace(/\s/g, "_")}
              />
            </FloatingLabel>
          ))}

          <SelectInput defaultOption="Day" listArray={CurrencyOptions} />
          <div className="payment-btn">
            <ButtonWrapper text="Search" />
          </div>
        </div>
        <CommissionStageTable />
      </div>
    </Layout>
  );
};

export default AffiliateReport;

const CommissionStageTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "Date", minWidth: 150, flex: 1 },
    { field: "Click Count", minWidth: 150, flex: 1 },
    { field: "Signup Count", minWidth: 150, flex: 1 },
    { field: "Unique View Cont", minWidth: 200, flex: 1 },
    { field: "View Count", minWidth: 150, flex: 1 },
    { field: "FTD", minWidth: 100, flex: 1 },
    { field: "Amount", minWidth: 100, flex: 1 },
    { field: "Net Amount", minWidth: 150, flex: 1 },
    { field: "Commission Amount", minWidth: 200, flex: 1 },
  ];

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Affiliate Report</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption={10} />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
