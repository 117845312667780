import { useState } from "react";
import GlobalTable from "../../global/globalTable";

const MainPageTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "ID", minWidth: 150, flex: 1 },
    { field: "Position", minWidth: 180, flex: 1 },
    { field: "Lang", minWidth: 180, flex: 1 },
    { field: "	Banner ALT", minWidth: 180, flex: 1 },
    { field: "Banner Device", minWidth: 180, flex: 1 },
    { field: "Image", minWidth: 180, flex: 1 },
    { field: "Banner Title", minWidth: 180, flex: 1 },
    { field: "Opens in new tab", minWidth: 180, flex: 1 },
    { field: "Url", minWidth: 180, flex: 1 },
  ]);
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Main Page Banners</h6>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default MainPageTable;
