import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ItemsArr, navItemsArr } from "../../utils/util";
// import GlobalInput from "../global/input";
import "./navbar.css";
import { useMyContect } from "../../context/myContext";
import { jwtDecode } from "jwt-decode";

const Navbar = () => {
  const { user, isLoading } = useMyContect();
  const location = useLocation();
  const token = localStorage.getItem("accToken");
  const currPath = location.pathname;

  const [showDropdown, setShowDropdown] = useState(
    localStorage.getItem("dropdown")
  );

  const handleDropdown = (value) => {
    if (value === showDropdown) {
      localStorage.removeItem("dropdown");
      setShowDropdown("");
    } else if (value) {
      localStorage.setItem("dropdown", value);
      setShowDropdown(value);
    }
  };
  let activeUrl = window.location.pathname;

  useEffect(() => {
    // This will scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // You can also listen to the 'scroll' event and save the scroll position in localStorage
    const handleScroll = () => {
      localStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const filteredMenuItems =
    user?.level === "cashier"
      ? ItemsArr
      : navItemsArr
          .map((item) => {
            if (item.text === "Admin Management") {
              return user?.role === "superAdmin" ? item : null;
            }
            // else if (
            //   item.text === "Agent" ||
            //   item.text === "Agent Transaction History"
            // ) {
            //   return user?.level === "cashier" ? null : item;
            // }
            return item;
          })
          .filter(Boolean);

  useEffect(() => {
    const accessToken = localStorage.getItem("accToken");
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const expirationTimeInSeconds = decodedToken.exp || 0;
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      // const timeLeft = expirationTimeInSeconds - currentTimeInSeconds;
      // const hours = Math.floor(timeLeft / 3600);
      // const minutes = Math.floor((timeLeft % 3600) / 60);
      // const seconds = timeLeft % 60;
      if (expirationTimeInSeconds <= currentTimeInSeconds) {
        localStorage.clear();
        window.location.reload();
      }
    }
    // eslint-disable-next-line
  }, [isLoading, token, currPath]);

  return (
    <div className="sidebar">
      <div className="logo">Backend</div>
      <div className="menu-item">
        {/* <div className="search-area">
          <GlobalInput
            placeholder={"Search..."}
            value="Metabet777"
            type={"text"}
          />
          <i className="las la-angle-down"></i>
        </div> */}
        <ul>
          {filteredMenuItems?.map((el, i) => {
            return (
              <React.Fragment key={i}>
                {el?.subMenu ? (
                  <li
                    className={`sub ${
                      showDropdown === el?.text ? "open-list" : ""
                    }`}
                  >
                    <div
                      className="submenu-item"
                      onClick={() => handleDropdown(el?.text)}
                      role="presentation"
                    >
                      {el?.icon} {el?.text}
                      <div className="down-arrow">
                        {showDropdown === el?.text ? (
                          <i className="las la-angle-up"></i>
                        ) : (
                          <i className="las la-angle-down"></i>
                        )}
                      </div>
                    </div>
                    <ul>
                      {el.subMenu.map((ele, i) => (
                        <Link
                          title={ele?.text}
                          to={ele?.url}
                          className={`${
                            activeUrl === ele?.url ? "active-item" : ""
                          }`}
                          key={i}
                        >
                          <li className="text-truncate">
                            <i className="las la-circle"></i>{" "}
                            <span className="text-truncate">{ele?.text}</span>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <Link
                    title={el?.text}
                    to={el?.url}
                    className={`${activeUrl === el?.url ? "active-item" : ""}`}
                  >
                    <li className="text-truncate">
                      {el?.icon}{" "}
                      <span className="text-truncate">{el?.text}</span>
                    </li>
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default Navbar;
