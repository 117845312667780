import Layout from "../../layout/layout";
import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import SelectInput from "../../global/selectInput";
import ExportExcel from "../../global/exportExcel";
import KycVerificationFilter from "./kycVerificationFilter";
import "./kyc.css";

const KycVerification = () => {
  return (
    <Layout title="KYC VERIFICATION">
      <div className="notification-page affiliate-report-page users-page kyc-verifications">
        <KycVerificationFilter />
        <CommisionStageTable />
      </div>
    </Layout>
  );
};
export default KycVerification;

const CommisionStageTable = () => {
  const [rowData] = useState([
    {
      ID: 2299244,
      UserProfileID: 17838571,
      Username: "justin20478vh",
      Questionnaire: "Proof of Address",
      Form: "Proof of Address",
      Status: "Pending",
      Admin: "-",
      SendDate: "13-10-2023",
      Fields: "-",
      changeStatus: "pending",
    },
  ]);

  const [columnDefs] = useState([
    { field: "ID", minWidth: 150, flex: 1 },
    { field: "User Profile ID", minWidth: 150, flex: 1 },
    { field: "Username", minWidth: 180, flex: 1 },
    { field: "Questionnaire", minWidth: 180, flex: 1 },
    { field: "Form", minWidth: 180, flex: 1 },
    { field: "Status", minWidth: 180, flex: 1 },
    { field: "Admin", minWidth: 180, flex: 1 },
    { field: "Send Date", minWidth: 180, flex: 1 },
    { field: "Fields", minWidth: 180, flex: 1 },
    { field: "Change Status", minWidth: 180, flex: 1 },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>KYC Verification</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
        </div>
      </div>

      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
