import { useState } from "react";
import GlobalTable from '../../global/globalTable';
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const BrandTable = () => {
    const [rowData] = useState([]);
    const [columnDefs] = useState([
      { field: "Brand ID", minWidth: 200, flex: 1 },
      { field: "Brand Name",minWidth: 200, flex: 1 },
      { field: "Currency Code",minWidth: 200, flex: 1 },
      { field: "Balance",minWidth: 200, flex: 1 },
      { field: "Date",minWidth: 200, flex: 1 },
    ]);
    let options = [
  
        {
          id: 1,
          value: 50,
        },
        {
          id: 1,
          value: 100,
        },
      ];
    return (
      <div className="deposit-table">
      <div className="top-area">
         <h6>Brand Balances</h6>
       <div className="right-area">
       <ExportExcel />
        <SelectInput listArray={options} defaultOption="20" />
        <TableColumns tableData={columnDefs} />
       </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default BrandTable