import { useState, useEffect } from "react";
import GlobalTable from "../global/globalTable";
import { useMyContect } from "../../context/myContext";
import Pagination from "../../utils/Pagination";
import { getComments } from "../../utils/Apis";
const CommentsTable = ({columnDefs}) => {
  const { isLoading } = useMyContect();
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const pageSize = 20;
  const handleFetch = async (page) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        const res = await getComments(config);
        if (res) {
          if (res && res.data && res.data.data) {
            const transformedData = res.data.data.map((item) => ({
              id : item?.id,
              TagName: item?.tagName,
              Creator: item?.creatorName,
            }));
            setRowData(transformedData);
            setPages(res?.data?.pages);
          }
        }
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };
  useEffect(() => {
    handleFetch(page);
  }, [page, isLoading]);
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Tags</h6>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
      <Pagination
        currentPage={page}
        totalPages={pages}
        onPageChange={setPage}
      />
    </div>
  );
};

export default CommentsTable;
