import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import ButtonWrapper from "../global/button";
import { useMyContect } from "../../context/myContext";
import { useState } from "react";
import { addVoucher } from "../../utils/Apis";
import toast from "react-hot-toast";

const AddVoucherPopup = ({ show, handleClose, recall, setRecall }) => {
  const { user } = useMyContect();
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    voucherCount: "",
    amount: "",
    days: "",
    brand: localStorage.getItem("brand"),
    currency: "",
    creatorName: user?.userName,
    creatorId: user?.id,
    status: "Active",
  });

  const [error, setError] = useState({
    voucherCount: false,
    amount: false,
    days: false,
    brand: localStorage.getItem("brand"),
    currency: false,
  });

  let Currencyoptions = [
    {
      id: 1,
      value: "USD",
    },
  ];

  const handleChange = (e) => {
    console.log(fields);
    setFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePopup = () => {
    handleClose();
    setError({
      voucherCount: false,
      amount: false,
      days: false,
      brand: localStorage.getItem("brand"),
      currency: false,
    });
  };

  const handleSubmit = async () => {
    try {
      console.log("data val", fields);

      const token = localStorage.getItem("accToken");

      const checkError = {
        voucherCount: fields.voucherCount === "",
        amount: fields.amount === "" || isNaN(fields.amount),
        days: fields.days === "" || isNaN(fields.days),
        brand: fields.brand === "",
        currency: fields.currency === "",
      };

      setError(checkError);
      if (Object.values(checkError).some((error) => error)) {
        return;
      }

      if (token) {
        setLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body: fields,
        };
        console.log(config);

        const res = await addVoucher(fields);

        if (res) {
          setLoading(false);
          toast.success(res.data.message);
          setRecall(Math.random());
          handleClose();
          setFields({
            voucherCount: "",
            amount: "",
            days: "",
            brand: localStorage.getItem("brand"),
            currency: "",
            creatorName: user?.userName,
            status: "Active",
          });
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handlePopup}
      centered
      className={`notification-popup add-payment-popup1 add-voucher`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Voucher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <div className="row-one">
            <SelectInput
              defaultOption={"Currencies"}
              listArray={Currencyoptions}
              handleSelectOption={handleChange}
              name="currency"
              value={fields?.currency}
            />
            {error.currency && (
              <div className="text-danger">*Currency is required</div>
            )}

            <FloatingLabel controlId="floatingInput" label="Amount">
              <Form.Control
                type="number"
                placeholder="Amount"
                autoComplete="off"
                name="amount"
                value={fields.amount}
                onChange={handleChange}
              />
              {error.amount && (
                <div className="text-danger">*Enter valid Amount</div>
              )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="Voucher Count">
              <Form.Control
                type="number"
                placeholder="Coupon Code"
                autoComplete="off"
                name="voucherCount"
                value={fields.voucherCount}
                onChange={handleChange}
              />
              {error.voucherCount && (
                <div className="text-danger">*Code is required</div>
              )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="Usability Days">
              <Form.Control
                type="number"
                placeholder="Usability Days"
                autoComplete="off"
                name="days"
                value={fields.days}
                onChange={handleChange}
              />
              {error.days && (
                <div className="text-danger">*Enter the Valid Days</div>
              )}
            </FloatingLabel>
          </div>
          <div className="Voucher-btn filter-section">
            <ButtonWrapper
              text={loading ? "Creating..." : "Create"}
              loading={loading}
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddVoucherPopup;
