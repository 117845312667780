import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SwitchBox from "../global/switchBox";
import ButtonWrapper from "../global/button";
import {  useEffect, useState } from "react";
import { createPaymentProvider, deleteProvider } from "../../utils/Apis";
import { useMyContect } from "../../context/myContext";
import toast from "react-hot-toast";

const AddProviderPopup = ({
  show,
  handleClose,
  addPaymentType,
  recall,
  setRecall,
}) => {
  const { data, setIsLoading, isLoading } = useMyContect();

  const [paymentProvider, setPaymentProvider] = useState({
    name: "",
    enabled: false
  });
  const [error , setError] = useState({
    name: false,
    enabled: false
  })
 
  useEffect(() => {
    if (addPaymentType === "Edit" && data) {
      setPaymentProvider({
        name: data.name,
        enabled: data.enabled === "Yes"
      });
    } else {
      setPaymentProvider({
        name: "",
        enabled: false
      });
    }
  }, [addPaymentType, data]);


  const handleChange = (e) => {
    setPaymentProvider({
      ...paymentProvider,
      name: e.target.value,
    });
    console.log(paymentProvider);
  };

  const handleToggle = () => {
    setPaymentProvider({
      ...paymentProvider,
      enabled: !paymentProvider.enabled,
    });
    console.log(paymentProvider);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("accToken");
    try {

      if (token) {
        setIsLoading(true);
        const res = await deleteProvider(data?.id);
        console.log(res);
        if (res) {
          toast.success(res.data.message)
          setIsLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
      toast.error(error.message)
    } finally {
      setIsLoading(false);
     
    }
  };

  const handleCreatePaymentProvider = async () => {
    try {
      let providerData = paymentProvider;
      providerData = {
        ...providerData,
        enabled: paymentProvider.enabled ? "Yes" : "No",
      };
      
      const checkError = {
       name: paymentProvider.name ===""
      };
      setError(checkError);
      if (Object.values(checkError).some((error) => error)) {
        return;
      }

      const token = localStorage.getItem("accToken");

      if (token) {
        setIsLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body:
            data && addPaymentType === "Edit"
              ? {
                  ...providerData,
                  id: data.id,
                }
              : providerData,
        };

        // console.log("config", config);

        const res = await createPaymentProvider(config);
        toast.success(res.data.message)
        if (res) {
          setIsLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found")
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
      toast.error(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup add-provider-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {addPaymentType === "Delete"
            ? "Delete"
            : addPaymentType === "Edit"
            ? "Update"
            : "Create"}{" "}
          provider
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addPaymentType === "Delete" ? (
          <div className="ms-2">
            <p>Are you sure you want to delete? </p>
            <div className="auto-btn">
            <ButtonWrapper
              handleClick={handleDelete}
              text={`${isLoading ? "Deleting..." : "Delete"}`}
            />
            </div>
           
          </div>
        ) : (
          <div className="tabs-section">
            <div className="row-one">
              <FloatingLabel controlId="floatingInput" label="Provider Name">
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="name"
                  value={paymentProvider.name}
                  onChange={handleChange}
                />
                {error.name &&  <div className="text-danger">Provider Name is required</div> }
              </FloatingLabel>
              <SwitchBox
                label={"Enabled"}
                handleToggle={handleToggle}
                toggleFlag={paymentProvider.enabled}
              />
            </div>
            <div className="payment-btn">
              <ButtonWrapper
                text={`${
                  addPaymentType === "Edit"
                    ? isLoading
                      ? "Updating..."
                      : "Update"
                    : isLoading
                    ? "Creating..."
                    : "Create"
                }`}
                handleClick={handleCreatePaymentProvider}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AddProviderPopup;
