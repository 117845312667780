import Layout from "../layout/layout";
import { useEffect, useState } from "react";
import ButtonWrapper from "../global/button";
import GlobalTable from "../global/globalTable";
import AddCommissionStage from "./addCommissionStage";
import AddCommissionFTD from "./commissionFTD";
import AddCommissionStageMonthly from "./commissionStagemonthly";
import AddCommissionCPA from "./addCommissionCPA";
import HorizontalTabs from "../global/horizontalTab";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import {
  getAllCommissionStage,
  getAllCommissionStageFtd,
  getAllCommissionStageMonthly,
} from "../../utils/Apis";
import Pagination from "../../utils/Pagination";

const tabs = [
  "Commission Stages",
  "Commission Stage FTD",
  "Commission Stages Monthly",
  "Commission Stages CPA",
];

const CommissionStages = () => {
  const [activeTab, setActiveTab] = useState("Commission Stages");
  const [showPopup, setShowPopup] = useState("");
  const [recall, setRecall] = useState(0);

  const handleTabs = (value) => setActiveTab(value);

  const handlePopup = (value) => setShowPopup(value ? value : "");

  const renderTable = () => {
    switch (activeTab) {
      case "Commission Stages":
        return (
          <CommissionStageTable
            recall={recall}
            setShowPopup={setShowPopup}
            activeTab={activeTab}
          />
        );
      case "Commission Stage FTD":
        return (
          <CommissionStageFTDTable
            recall={recall}
            setShowPopup={setShowPopup}
            activeTab={activeTab}
          />
        );
      case "Commission Stages Monthly":
        return (
          <CommissionStageMonthlyTable
            recall={recall}
            setShowPopup={setShowPopup}
            activeTab={activeTab}
          />
        );
      case "Commission Stages CPA":
        return (
          <CommissionStageCPATable
            recall={recall}
            setShowPopup={setShowPopup}
            activeTab={activeTab}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Layout title="Commission Stages">
      <div className="notification-page">
        <div className="tabs-section notification-tabs commision-popup">
          <div className="horizontal-tabs">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            <ButtonWrapper
              text={`Add ${activeTab}`}
              icon={
                <span className="add-icon">
                  <AddBoxIcon />
                </span>
              }
              clValue={"send-notificaton"}
              handleClick={() => handlePopup("Add")}
            />
          </div>
          {renderTable()}
        </div>
      </div>
      <AddCommissionStage
        show={activeTab === "Commission Stages" && !!showPopup}
        handleClose={handlePopup}
        showPopup={showPopup}
        setRecall={setRecall}
      />
      <AddCommissionFTD
        show={activeTab === "Commission Stage FTD" && !!showPopup}
        handleClose={handlePopup}
        showPopup={showPopup}
        setRecall={setRecall}
      />
      <AddCommissionStageMonthly
        show={activeTab === "Commission Stages Monthly" && !!showPopup}
        handleClose={handlePopup}
        showPopup={showPopup}
        setRecall={setRecall}
      />
      <AddCommissionCPA
        show={activeTab === "Commission Stages CPA" && !!showPopup}
        handleClose={handlePopup}
      />
    </Layout>
  );
};

export default CommissionStages;

const CommissionStageTable = ({ recall, activeTab, setShowPopup }) => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState("");

  const fetchTableData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          // filter: filter,
        };
        setRowData([]);
        setIsLoading(true);
        const res = await getAllCommissionStage(config);

        if (res) {
          if (res && res.data && res.data.data) {
            setPages(res.data?.pages);
            setRowData(res?.data?.data);
          }
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found.");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recall, page, limit, activeTab]);

  const [columnDefs] = useState([
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    {
      field: "commissionType",
      headerName: "Stage Type",
      minWidth: 150,
      flex: 1,
    },
    { field: "gameType", headerName: "Game Type", minWidth: 150, flex: 1 },
    { field: "levelName", headerName: "Level Type", minWidth: 150, flex: 1 },
    { field: "ngrFrom", headerName: "NGR From", minWidth: 150, flex: 1 },
    { field: "ngrTo", headerName: "NGR To", minWidth: 150, flex: 1 },
    {
      field: "commissionIncrease",
      headerName: "Commission Increase",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      cellRenderer: () => <Actions setShowPopup={setShowPopup} />,
    },
  ]);

  return (
    <div className="deposit-table">
      {isLoading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const CommissionStageMonthlyTable = ({ recall, activeTab, setShowPopup }) => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState("");

  const fetchTableData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          // filter: filter,
        };
        setRowData([]);
        setIsLoading(true);
        const res = await getAllCommissionStageMonthly(config);

        if (res) {
          if (res && res.data && res.data.data) {
            // const transformedData = res.data?.data.map((item) => ({
            //   ...item,
            //   recommended: item?.recommended ? "Yes" : "No",
            // }));
            setPages(res.data?.pages);
            setRowData(res?.data?.data);
          }
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found.");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recall, page, limit, activeTab]);

  const [columnDefs] = useState([
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    { field: "levelName", headerName: "Key Name", minWidth: 150, flex: 1 },
    { field: "nthMonth", headerName: "nth Month", minWidth: 150, flex: 1 },
    {
      field: "commissionIncrese",
      headerName: "Commission Increase",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      cellRenderer: () => <Actions setShowPopup={setShowPopup} />,
    },
  ]);

  return (
    <div className="deposit-table">
      <h2>Game Stats</h2>
      {isLoading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const CommissionStageFTDTable = ({ recall, activeTab, setShowPopup }) => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState("");

  const fetchTableData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          // filter: filter,
        };
        setRowData([]);
        setIsLoading(true);
        const res = await getAllCommissionStageFtd(config);

        if (res) {
          if (res && res.data && res.data.data) {
            // const transformedData = res.data?.data.map((item) => ({
            //   ...item,
            //   recommended: item?.recommended ? "Yes" : "No",
            // }));
            setPages(res.data?.pages);
            setRowData(res?.data?.data);
          }
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found.");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recall, page, limit, activeTab]);

  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 150,
      flex: 1,
      cellStyle: { "text-align": "center" },
    },
    {
      field: "levelName",
      headerName: "Key Name",
      minWidth: 150,
      flex: 1,
      cellStyle: { "text-align": "center" },
    },
    {
      field: "ftdcountMorethanOrEqual",
      headerName: "Count More Then or Equal",
      minWidth: 150,
      flex: 1,
      cellStyle: { "text-align": "center" },
    },
    {
      field: "commissionIncrese",
      headerName: "Commission Increase",
      minWidth: 150,
      flex: 1,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      field: "",
      cellRenderer: () => <Actions setShowPopup={setShowPopup} />,
    },
  ]);

  return (
    <div className="deposit-table">
      <h2>Game Stats</h2>
      {isLoading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const CommissionStageCPATable = ({ recall, activeTab, setShowPopup }) => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { headerName: "ID", minWidth: 150, flex: 1 },
    { headerName: "Key Name", minWidth: 150, flex: 1 },
    { headerName: "Amount", minWidth: 150, flex: 1 },
    { headerName: "Count More Then or Equal", minWidth: 150, flex: 1 },
  ]);

  return (
    <div className="deposit-table">
      <h2>Game Stats</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

const Actions = ({ setShowPopup }) => {
  const [flag, setFlag] = useState(false);
  const handlePopup = (value) => {
    setFlag(!flag);
    setShowPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePopup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePopup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
      {/* <EditPaymentMethod show={flag} handleClose={handlePopup} /> */}
    </>
  );
};
