import Layout from "../layout/layout";
import UserTable from "./userTable";
import "./index.css";
import Filter from "./filter";
import { useState } from "react";

// Fetch players data using useEffect
// eslint-disable-next-line react-hooks/rules-of-hooks

const Users = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    id: "",
    userName: "",
    // firstName: "",
    // lastName: "",
    // email: "",
    // mobile: "",
    // country: "",
    // city: "",
    // address: "",
    // iskycCompleted: "",
    // fromBalance: "",
    // toBalance: "",
    // page: "",
    // pageSize: "",
  });
  
  return (
    <Layout title="Users">
      <div className="users-page">
        <Filter setRecall={setRecall} setFilter={setFilter} filter={filter} />
        <UserTable filter={filter} recall={recall} />
      </div>
    </Layout>
  );
};
export default Users;
