import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import GlobalEditor from "../../global/globalEditor";
import toast from "react-hot-toast";
import {
  createSiteText,
  deleteSiteText,
  updateSiteText,
} from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";

const SiteTextPopup = ({ show, handleClose, sitePopup, recall, setRecall }) => {
  const editorRef = useRef(null);
  const { data } = useMyContect();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("accToken");
      setLoading(true);

      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body: data,
        };
        const res = await deleteSiteText(config);
        if (res?.data?.status === 200) {
          toast.success("Site Text Deleted Successfully", {
            tostId: "successSite",
          });
        } else {
          toast.error("Something went wrong for Deletion", {
            toastId: "wenwrong",
          });
        }
        if (res) {
          setLoading(false);
          setRecall(recall + 1);
          handleClose();

          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      centered
      // className={`notification-popup
      // ${sitePopup === "Delete" ? "-delete" : ""} 
      // add-notification-popup content-popup`}  
       className={`notification-popup
      add-notification-popup content-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{sitePopup} Site text</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          {sitePopup === "Delete" ? (
            <>
              <div className="">
                <p>Are you sure you want to delete?</p>
              </div>
              <div className="auto-btn">
                <ButtonWrapper
                  text={"Delete"}
                  disable={loading}
                  loading={loading}
                  handleClick={handleDelete}
                />
              </div>
            </>
          ) : (
            <>
              <SiteTextAddAndUpdate
                editorRef={editorRef}
                data={data}
                sitePopup={sitePopup}
                handleClose={handleClose}
                setRecall={setRecall}
                recall={recall}
              />
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SiteTextPopup;

const SiteTextAddAndUpdate = ({
  editorRef,
  data,
  setRecall,
  recall,
  sitePopup,
  handleClose,
}) => {
  // console.log("new", sitePopup);
  let options = [
    {
      id: 1,
      value: "en-Us",
    },
  ];

  const [siteText, setSiteText] = useState({
    language: "",
    title: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    language: false,
    title: false,
    content: false,
  });

  useEffect(() => {
    if (data && sitePopup === "Edit") {
      setSiteText({
        content: data?.content,
        language: data?.language,
        title: data?.title,
      });
    } else {
      setSiteText({
        language: "",
        title: "",
        content: "",
      });
    }
  }, [data, sitePopup]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSiteText({
      ...siteText,
      [name]: value,
    });
    setError({
      ...error,
      [name]: false,
    });
  };

  const handleEditorChange = (content) => {
    setSiteText({
      ...siteText,
      content: content,
    });
    setError({
      ...error,
      content: false,
    });
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem("accToken");
      const checkError = {
        language: siteText.language === "",
        title: siteText.title === "",
        content: siteText.content === "",
      };
      setError(checkError);
      if (Object.values(checkError).some((error) => error)) {
        return;
      }

      setLoading(true);
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body: siteText,
        };

        const res =
          sitePopup === "Edit"
            ? await updateSiteText(data?.id, config)
            : await createSiteText(config);
        if (res?.data?.status === 200) {
          toast.success(res.data.message, {
            tostId: "successSite",
          });
        } else {
          toast.error("Something went wrong for Updation", {
            toastId: "wenwrong",
          });
        }
        if (res) {
          setLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      <div className="row">
        <div className="col-6">
          <SelectInput
            listArray={options}
            defaultOption="Choose Language"
            name={"language"}
            selectedValue={siteText?.language}
            handleSelectOption={handleChange}
          />
          {error.language && (
            <div className="text-danger">Language is required</div>
          )}
        </div>
        <div className="col-6">
          <FloatingLabel controlId="floatingInput" label="Title">
            <Form.Control
              type="Text"
              placeholder="name@example.com"
              autoComplete="off"
              name="title"
              value={siteText?.title}
              onChange={handleChange}
            />
          </FloatingLabel>
          {error.title && <div className="text-danger">Title is required</div>}
        </div>
      </div>

      <div className="add-languages"></div>
      <div className="editor-section">
        <h6>Text</h6>
        <GlobalEditor
          editorRef={editorRef}
          handleEditorChange={handleEditorChange}
          textValue={siteText?.content}
        />
        {error.content && <div className="text-danger">Text is required</div>}
      </div>
      {/* {error.title && <div className="text-danger">Title is required</div>} */}
      <div className="auto-btn">
        <ButtonWrapper
          disable={loading}
          loading={loading}
          text={sitePopup === "Edit" ? "Update" : sitePopup}
          handleClick={handleUpdate}
        />
      </div>
    </div>
  );
};
