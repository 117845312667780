import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";

const RegisterAffiliateFilter = ({ setRecall, setFilter, filter }) => {
  const [affiliateFilter, setAffiliateFilter] = useState(false);

  const currencyOptions = [
    {
      id: 1,
      value: "USD",
    },
  ];

  const CountryOptions = [
    { id: 1, value: "India" },
    { id: 1, value: "US" },
  ];

  const affiliateStatus = [
    { id: 1, value: "Active" },
    { id: 1, value: "Inactive" },
  ];

  const kycOptions = [
    { id: 1, value: "Verified" },
    { id: 1, value: "Not Verified" },
  ];

  const renderFloatingLabel = (label, controlId, name, value) => (
    <FloatingLabel controlId={controlId} label={label}>
      <Form.Control
        type="text"
        placeholder=""
        autoComplete="off"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FloatingLabel>
  );

  const moreFilter = () => {
    setAffiliateFilter(!affiliateFilter);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClear = () => {
    setFilter({
      id: "",
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      country: "",
      city: "",
      address: "",
      iskycCompleted: "",
      fromBalance: "",
      toBalance: "",
      page: "",
      pageSize: "",
    });
    setRecall(Math.random());
  };

  return (
    <div className="voucher-filter">
      <div className="filter-section">
        {renderFloatingLabel("User ID", "id", "id", filter?.id)}
        {renderFloatingLabel(
          "User Name",
          "userName",
          "userName",
          filter?.userName
        )}
        {renderFloatingLabel("Email", "email", "email", filter?.email)}
        {renderFloatingLabel(
          "First Name",
          "firstName",
          "firstName",
          filter?.firstName
        )}
        {affiliateFilter &&
          renderFloatingLabel(
            "Last Name",
            "lastName",
            "lastName",
            filter?.lastName
          )}
        {affiliateFilter &&
          renderFloatingLabel("Mobile", "mobile", "mobile", filter?.mobile)}
        {affiliateFilter && (
          <SelectInput
            name="country"
            defaultOption="Country"
            selectedValue={filter?.country ?? "Country"}
            listArray={CountryOptions}
            handleSelectOption={handleChange}
          />
        )}
        {affiliateFilter &&
          renderFloatingLabel("City", "city", "city", filter?.city)}
        {affiliateFilter &&
          renderFloatingLabel("Address", "address", "address", filter?.address)}
        {affiliateFilter &&
          renderFloatingLabel("Pin", "pin", "pin", filter?.pin)}
        {affiliateFilter && (
          <SelectInput
            name="currency"
            defaultOption="Currency"
            selectedValue={filter?.currency ?? "Currency"}
            listArray={currencyOptions}
            handleSelectOption={handleChange}
          />
        )}
        {affiliateFilter && (
          <SelectInput
            name="affiliateStatus"
            defaultOption="Affiliate Status"
            selectedValue={filter?.affiliateStatus ?? "Affiliate Status"}
            listArray={affiliateStatus}
            handleSelectOption={handleChange}
          />
        )}
        {affiliateFilter &&
          renderFloatingLabel(
            "From Balance",
            "fromBalance",
            "fromBalance",
            filter?.fromBalance
          )}
        {affiliateFilter &&
          renderFloatingLabel(
            "To Balance",
            "toBalance",
            "toBalance",
            filter?.toBalance
          )}
        {affiliateFilter && (
          <SelectInput
            name="iskycCompleted"
            defaultOption="KYC Status"
            selectedValue={filter?.iskycCompleted ?? "KYC Status"}
            listArray={kycOptions}
            handleSelectOption={handleChange}
          />
        )}

        <div className="filter-section register-affiliate-btn">
          <ButtonWrapper
            text="Search"
            clValue="search-filter-btn"
            handleClick={() => {
              setRecall(Math.random());
            }}
          />
          <ButtonWrapper
            text="Clear All"
            clValue="clear-btn"
            handleClick={handleClear}
          />
          <ButtonWrapper
            text={affiliateFilter ? "Less Filter" : "More Filter"}
            clValue="clear-btn"
            handleClick={moreFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterAffiliateFilter;
