import { useEffect, useState } from "react";
import LoginLogFilter from "./loginLogFilter";
import Layout from "../../layout/layout";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";
import { getLoginLogData } from "../../../utils/Apis";
import { Pagination } from "react-bootstrap";

const LoginLog = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    userId: "",
    userName: "",
    ipAddress: "",
    Browser: "",
    os: "",
    fromDate: "",
    toDate: "",
    deviceType: "",
  });
  return (
    <Layout title="LOGIN LOG">
      <div className="affiliate-list-page media-template-page register-affiliate-page users-page">
        <LoginLogFilter
          setRecall={setRecall}
          filter={filter}
          setFilter={setFilter}
        />
        <LoginLogTable
          recall={recall}
          setRecall={setRecall}
          filter={filter}
          setFilter={setFilter}
        />
      </div>
    </Layout>
  );
};
export default LoginLog;

const LoginLogTable = ({ recall, filter }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [pages, setPages] = useState("");
  const [rowData, setRowData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "User ID", field: "userId", minWidth: 150, flex: 1 },
    { headerName: "User Name", field: "userName", minWidth: 200, flex: 1 },
    { headerName: "Hot Address", field: "ipAddress", minWidth: 200, flex: 1 },
    { headerName: "Browser", field: "Browser", minWidth: 200, flex: 1 },
    { headerName: "OS", field: "os", minWidth: 200, flex: 1 },
    { headerName: "Date", field: "createdAt", minWidth: 200, flex: 1 },
    { headerName: "Device Type", field: "deviceType", minWidth: 200, flex: 1 },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const handleGetLoginLogData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("accToken");
      setLoading(true);
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        };
        setRowData([]);
        const res = await getLoginLogData(config);
        if (res && res.data && res.data?.data) {
          setLoading(false);
          setPages(res.data.pages);
          setRowData(res.data?.data);
        }
        console.log("Response is getting", res);
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found in the fetching gameTransaction Data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetLoginLogData(page, limit);
    // eslint-disable-next-line
  }, [page, limit, recall]);

  const handleTableCheckBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Authorization Log</h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setLimit(e.target.value || "20");
            }}
          />
          <TableColumns
            tableData={tableData}
            checkedBox={handleTableCheckBox}
          />
        </div>
      </div>
      {loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
