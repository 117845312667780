/* eslint-disable jsx-a11y/img-redundant-alt */
import SelectInput from '../global/selectInput'
import TableColumns from '../global/tableColumns'
import GlobalTable from '../global/globalTable'
import { useEffect, useState } from 'react'
import { useMyContect } from '../../context/myContext'
import { Delete, Edit } from '@mui/icons-material'
import Pagination from '../../utils/Pagination'
import { paginationDropdownOptions } from './constants'
import CategoryPopUp from './cat-popups/cat-popup'
import {
  deactivateCategory,
  getAllGameSubCategory,
  updateRanksInDb,
} from '../../utils/Apis'
import GameListByCategory from './cat-popups/cat-list'
import toast from 'react-hot-toast'

const CatTable = ({ recall, filter, setRecall, setFilter }) => {
  const { isLoading } = useMyContect()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState('')
  const [pageSize, setPageSize] = useState('10')
  const [rowData, setRowData] = useState([])

  const [showViewList, setShowViewList] = useState(false)
  const [showCatEdit, setShowCatEdit] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [rowGameData, setRowGameData] = useState([])
  const getAllGameCategory = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('accToken')
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        }

        setLoading(true)
        const res = await getAllGameSubCategory(config)
        setLoading(false)
        if (res?.data) {
          setRowData(res?.data?.categories || [])
          setPages(res?.data.numberOfPages)
        }
      } else {
        toast.error('Token Not Found', { toastId: 'token not found' })
      }
    } catch (error) {
      console.log('Error found while fetching data', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAllGameCategory(page, pageSize)
    // eslint-disable-next-line
  }, [pageSize, page, filter, recall])
  const handleDeactivate = async (data) => {
    try {
      const token = localStorage.getItem('accToken')
      if (!token) {
        toast.error('Token Not Found', { toastId: 'token not found' })
        return
      }

      const categoryId = data?.master_game_sub_category_id

      if (!categoryId) {
        toast.error('No category selected')
        return
      }

      // Call the deactivateCategory function with the category ID and token
      const success = await deactivateCategory(categoryId)
      if (success) {
        getAllGameCategory(page, pageSize)
        toast.success('Category deactivated successfully')
      } else {
        toast.error('Failed to deactivate category')
      }
    } catch (error) {
      console.error('Error deactivating category:', error)
      toast.error('Error deactivating category')
    }
  }

  const actionCellRenderer = (data) => {
    return (
      <div className="users-action-buttons">
        <button
          className="edit-agent-button"
          onClick={() => {
            setShowCatEdit(true)
            setSelectedCategory(data)
          }}
        >
          <Edit fontSize="10" />
        </button>
        <button
          className="delete-agent-button"
          onClick={() => handleDeactivate(data)}
        >
          <Delete fontSize="10" />
        </button>
      </div>
    )
  }
  const viewListCellRender = (item) => {
    return (
      <div className="users-action-buttons">
        <button
          className="view-list-button  "
          onClick={() => {
            setShowViewList(true)
            setSelectedCategory(item || {})
            setRowGameData([])
          }}
        >
          View List
        </button>
      </div>
    )
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Category Rank',
      field: 'category_rank',
      rowDrag: true,
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => params.value,
    },
    {
      headerName: 'Category Name',
      field: 'name',
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
    },
    {
      headerName: 'Category Total Game',
      field: 'game_count',
      minWidth: 180,
      flex: 1,
      cellRenderer: (params) => params.value || '-',
    },
    {
      headerName: 'Category Game List',
      field: 'game_list',
      minWidth: 200,
      flex: 1,
      cellRenderer: (item) => viewListCellRender(item),
    },
    {
      headerName: 'Actions',
      flex: 1,
      minWidth: 250,
      field: 'actionButton',
      cellRenderer: (params) => actionCellRenderer(params?.data),
    },
  ])

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    })),
  )

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item,
    )

    setTableData(updatedTableData)
    const updatedColumn = updatedTableData.filter((item) => item.checked)
    setColumnDefs(updatedColumn)
  }
  useEffect(() => {
    if (filter?.categoryName || filter?.id) {
      setPage(1)
    }
  }, [filter])
  const UpdateRank = async (data) => {
    try {
      const token = localStorage.getItem('accToken')
      if (!token) {
        toast.error('Token Not Found', { toastId: 'token not found' })
        return
      }

      const categoryId = data?.master_game_sub_category_id

      if (!categoryId) {
        toast.error('No category selected')
        return
      }

      // Call the deactivateCategory function with the category ID and token
      const success = await updateRanksInDb(data)
      if (success) {
        getAllGameCategory(page, pageSize)
        toast.success('Category deactivated successfully')
      } else {
        toast.error('Failed to deactivate category')
      }
    } catch (error) {
      console.error('Error deactivating category:', error)
      toast.error('Error deactivating category')
    }
  }
  const handleDragAndDrop = async (draggedId, newIndex, data) => {
    try {
      // Clone the data to avoid mutating the original array
      const updatedData = [...data]

      // Find the dragged item's index
      const draggedIndex = updatedData.findIndex(
        (item) => item.master_game_sub_category_id === draggedId,
      )
      // Remove the dragged item from the current position
      const [draggedItem] = updatedData.splice(draggedIndex, 1)

      // Insert the dragged item into the new position
      updatedData.splice(newIndex, 0, draggedItem)
      updatedData.forEach(async (item, index) => {
        item.rank = index + 1 // Set the rank based on the new position (1-based index)
      })
      if (draggedItem) {
        UpdateRank(draggedItem)
      }
    } catch (error) {
      toast.error('Error updating ranks')
    }
  }
  const handleRowDragEnd = async (event) => {
    const draggedId = event.node // ID of the dragged item
    const newIndex = event.overIndex // New index where the item is dropped
    handleDragAndDrop(
      draggedId.data.master_game_sub_category_id,
      newIndex,
      rowData,
    )
  }

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>All Categories</h6>
        <div className="right-area">
          <SelectInput
            listArray={paginationDropdownOptions}
            defaultOption="10"
            handleSelectOption={(e) => {
              setFilter({
                ...filter,
                pageSize: e.target.value ? e.target.value : '10',
              })
              setPageSize(e.target.value ? e.target.value : '10')
            }}
          />

          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData || [{}]}
            loading={loading}
            rowDragManaged={true}
            onRowDragEnd={handleRowDragEnd}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />

          <CategoryPopUp
            show={showCatEdit}
            type={'edit'}
            handleClose={() => setShowCatEdit(false)}
            selectedCategory={selectedCategory}
            onCategorySubmit={() => getAllGameCategory(page, pageSize)}
          />
          {selectedCategory?.data?.master_game_sub_category_id ? (
            <GameListByCategory
              show={showViewList}
              handleClose={() => {
                setShowViewList(false)
                setSelectedCategory()
              }}
              selectedCategory={selectedCategory || ''}
              rowGameData={rowGameData}
              setRowGameData={setRowGameData}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  )
}
export default CatTable
