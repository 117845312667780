const paginationDropdownOptions = [
  {
    id: 1,
    value: 20,
  },
  {
    id: 1,
    value: 50,
  },
  {
    id: 1,
    value: 100,
  },
  {
    id: 1,
    value: 500,
  },
  {
    id: 1,
    value: 1000,
  },
  {
    id: 1,
    value: 1500,
  },
  {
    id: 1,
    value: 2000,
  },
  {
    id: 1,
    value: 2500,
  },
  {
    id: 1,
    value: 3000,
  },
  {
    id: 1,
    value: 3500,
  },
  {
    id: 1,
    value: 4000,
  },
];

export { paginationDropdownOptions };

export const DummyGameList = [];
