import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import CategoryPopUp from "./cat-popups/cat-popup";
import { useState } from "react";
// import SelectInput from "../global/selectInput";
// import SwitchBox from "./switchBox";
// import FilterDatePicker from "../global/filterDatePicker";

const Filter = ({ setRecall, setFilter, filter }) => {
  const [showAddCategory, setShowAddCategory] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderInput = (label, name, value, type) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type={type ? type : "text"}
        placeholder=""
        autoComplete="off"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FloatingLabel>
  );

  const handleClear = () => {
    setFilter({
      id: "",
      categoryName: "",
      page: "",
      pageSize: "",
    });
    setRecall(Math.random());
  };

  return (
    <div className="filter-section-user user-top-icon " style={{}}>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="filter-section mt-3 mb-4  game-management-filter  ">
          {renderInput("Category ID", "id", filter?.id)}
          {renderInput("Category Name", "categoryName", filter?.categoryName)}
          <div className="filter-section m-0 game-management-section">
            <ButtonWrapper
              text="Search"
              clValue="search-filter-btn"
              handleClick={() => {
                setRecall(Math.random());
              }}
            />
            <ButtonWrapper
              text="Clear All"
              clValue="clear-btn"
              whiteSpace={true}
              handleClick={handleClear}
            />
          </div>
        </div>
        <div
          className=" d-flex justify-content-end "
          style={{
            height: "52px",
          }}
        >
          <button
            className="mng-cat-btn global-btn search-filter-btn btn btn-primary "
            onClick={() => setShowAddCategory(true)}
          >
            Add Category
          </button>
        </div>
      </div>
      <CategoryPopUp
        show={showAddCategory}
        handleClose={() => setShowAddCategory(false)}
        type="add"
      />
    </div>
  );
};

export default Filter;
