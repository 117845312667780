import { useEffect, useState } from "react";
import ButtonWrapper from "../../global/button";
import Layout from "../../layout/layout";
import toast from "react-hot-toast";
import { addUpdateScriptContent, getScriptContent } from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import "./scripts.css";
const Scripts = () => {
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [activeTab, sectActiveTab] = useState("Zopim");
  const handleTabs = (value) => {
    if (value !== " ") {
      sectActiveTab(value);
    } else {
      sectActiveTab(" ");
    }
  };

  const [filter, setFilter] = useState({
    google_analytics_script: "",
    liveChatVendor: "",
    live_chat_script: "",
    other_scripts_1: "",
    other_scripts_2: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await getScriptContent();
        setFilter(result?.data?.data?.response);
        sectActiveTab(result?.data?.data?.response?.liveChatVendor ?? "Zopim");
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [isLoading]);

  const handleChange = (e) => {
    const value = e.target.value;
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  function decodeScriptContent(encodedScript) {
    return encodedScript
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&");
  }

  const handleSubmit = async () => {
    setIsClick(true);
    const newFilter = {
      ...filter,
      liveChatVendor: activeTab,
    };
    try {
      const result = await addUpdateScriptContent({
        body: newFilter,
      });
      if (result?.data?.status === 200) {
        toast.success(result?.data?.message);
        // Call getData() only when status is 200
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsClick(false);
    }
  };

  return (
    <Layout title="SCRIPTS">
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : (
        <div className="notification-page payment-method-page">
          <div className="scripts-page">
            <div className="script-item">
              <h3>Google Analytics</h3>
              <textarea
                name="google_analytics_script"
                onChange={handleChange}
                value={decodeScriptContent(filter?.google_analytics_script)}
              ></textarea>
              <div className="auto-btn ">
                {/* <ButtonWrapper
                  text="Save"
                  loading={isClick}
                  handleClick={handleSubmit}
                /> */}
              </div>
            </div>
            <div className="script-item">
              <div className="script-tabs">
                <ul>
                  <li
                    className={`${
                      activeTab === "Zopim" ? "tabs-border-btm" : " "
                    }`}
                    onClick={() => handleTabs("Zopim")}
                  >
                    Zopim
                  </li>
                  <li
                    className={`${
                      activeTab === "Comm100" ? "tabs-border-btm" : " "
                    }`}
                    onClick={() => handleTabs("Comm100")}
                  >
                    Comm100
                  </li>
                  <li
                    className={`${
                      activeTab === "LiveChatInc" ? "tabs-border-btm" : " "
                    }`}
                    onClick={() => handleTabs("LiveChatInc")}
                  >
                    LiveChatInc
                  </li>
                </ul>
              </div>
              <textarea
                name="live_chat_script"
                onChange={handleChange}
                value={decodeScriptContent(filter?.live_chat_script)}
              ></textarea>
              {/* <div className="auto-btn ">
                <ButtonWrapper text="Save" handleClick={handleSubmit} />
              </div> */}
            </div>
            <div className="script-item">
              <h3>Other Scripts 1</h3>
              <textarea
                name="other_scripts_1"
                onChange={handleChange}
                value={decodeScriptContent(filter.other_scripts_1)}
              ></textarea>
              {/* <div className="auto-btn ">
                <ButtonWrapper text="Save" handleClick={handleSubmit} />
              </div> */}
            </div>
            <div className="script-item">
              <h3>Other Scripts 2</h3>
              <textarea
                name="other_scripts_2"
                onChange={handleChange}
                value={decodeScriptContent(filter.other_scripts_2)}
              ></textarea>
              {/* <div className="auto-btn ">
                <ButtonWrapper text="Save" handleClick={handleSubmit} />
              </div> */}
            </div>

            <div className="btn-wrapper mb-4">
              <ButtonWrapper
                text="Save"
                handleClick={handleSubmit}
                clValue="py-2"
                loading={isClick}
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default Scripts;
