import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import SwitchBox from "../../global/switchBox";
import FilterInput from "../../global/filterInput";
import { useMyContect } from "../../../context/myContext";
import toast from "react-hot-toast";
import { addUpdateKycQuestionnaires } from "../../../utils/Apis";

const AddNewQuestionnairePopup = ({
  show,
  handleClose,
  setRefreshQues,
  sendNotification,
}) => {
  const { user, data } = useMyContect();
  const [loading, setLoading] = useState(false);

  let list = [
    {
      id: 1,
      value: "Mobile Phone",
    },
    {
      id: 2,
      value: "Email",
    },
  ];

  const initialFields = {
    name: "",
    order: "",
    autoVerify: "Automatically Verifiable",
    required: "No",
    status: "Yes",
    creatorName: user?.firstName,
    creatorId: user?.id,
  };
  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    if (data && sendNotification === "Edit") {
      setFields((prevState) => ({
        ...prevState,
        name: data?.name,
        order: data?.order,
        autoVerify: data?.autoVerify,
        required: data?.required,
        status: data?.status,
      }));
    } else {
      setFields(initialFields);
    }
    // eslint-disable-next-line
  }, [data, sendNotification]);

  const handleChange = (e, fieldType) => {
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    await addUpdateKycQuestionnaires({
      body: fields,
      id: data?.id || "",
    })
      .then((result) => {
        if (result?.data?.status === 200) {
          setRefreshQues(Math.random());
          toast.success(result?.data?.message);
          handleClose();
        } else {
          toast.error(result?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup kyc-popup add-payment-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Questionnaire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row-one">
          <FilterInput
            label="Questionnaire Name"
            name={"name"}
            value={fields?.name}
            handleChange={handleChange}
          />
          <FilterInput
            label="order"
            name={"order"}
            value={fields?.order}
            handleChange={handleChange}
          />

          <SelectInput
            name={"autoVerify"}
            defaultOption={"Automatically Verifiable"}
            listArray={list}
            selectedValue={fields?.autoVerify ?? "Automatically Verifiable"}
            handleSelectOption={(e) => handleChange(e, "select")}
          />
          <SwitchBox
            label={"Mandatory"}
            toggleFlag={fields?.required === "Yes" ? true : false}
            handleToggle={(item) => {
              setFields((prevState) => ({
                ...prevState,
                required: item === true ? "Yes" : "No",
              }));
            }}
          />
        </div>

        <div className="filter-section register-affiliate-btn justify-content-end">
          <ButtonWrapper
            loading={loading}
            text={"Add"}
            clValue="search-filter-btn"
            handleClick={handleSubmit}
          />
          <ButtonWrapper
            text="Clear All"
            clValue="clear-btn"
            handleClick={() =>
              setFields({
                name: "",
                order: "",
                autoVerify: "Automatically Verifiable",
                required: "No",
              })
            }
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewQuestionnairePopup;
