import { useEffect, useState } from "react";
import Layout from "../layout/layout";
import Filter from "./filter";
import GlobalTable from "../global/globalTable";
import "./transaction.css";
import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import TableColumns from "../global/tableColumns";
import { useMyContect } from "../../context/myContext";
import { getAllSystemTransactionData } from "../../utils/Apis";
import toast from "react-hot-toast";
import Pagination from "../../utils/Pagination";

let options = [
  {
    id: 1,
    value: 20,
  },
  {
    id: 1,
    value: 50,
  },
  {
    id: 1,
    value: 100,
  },
];

const SystemTransaction = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    id: "",
    userId: "",
    transactionType: "",
    fromAmount: "",
    toAmount: "",
    fromDate: "",
    toDate: "",
    status: "",
  });
  return (
    <Layout title={"SYSTEM TRANSACTIONS"}>
      <div className="voucher-page users-page system-transaction-page">
        <Filter setRecall={setRecall} filter={filter} setFilter={setFilter} />
        <SystemTransactionTable recall={recall} filter={filter} />
      </div>
    </Layout>
  );
};
export default SystemTransaction;

const SystemTransactionTable = ({ recall, filter }) => {
  const [rowData, setRowData] = useState([]);
  const { isLoading, user } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [pages, setPages] = useState("");

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      minWidth: 200,
      flex: 1,
      headerName: "Transaction ID",
      cellStyle: {
        "text-align": "center",
      },
    },
    // { field:"", headerName: "Date From", width: "150px",sortable: true,
    // sortingOrder: ["asc", "desc"],
    // sort: "desc"},
    // { field:"", headerName: "Date To", width: "150px",sortable: true,
    // sortingOrder: ["asc", "desc"],
    // sort: "desc"},
    {
      field: "status",
      headerName: "Transaction Status",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "transactionType",
      headerName: "Operation Type",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "userId",
      headerName: "User ID",
      minWidth: 80,
      flex: 1,
      cellStyle: {
        "text-align": "center",
      },
    },
    {
      field: "admin",
      headerName: "Admin",
      width: "250px",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: "200px",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "paymentProvider",
      minWidth: 150,
      flex: 1,
      headerName: "Payment Provider",
    },
    {
      field: "paymentChannel",
      headerName: "Payment Channel",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "brandAmount",
      headerName: "Brand Amount",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "usdAmount",
      headerName: "Amount",
      minWidth: 150,
      flex: 1,
      // sortable: true,
      // sortingOrder: ["asc", "desc"],
      // sort: "desc",
      cellStyle: { "text-align": "right" },
    },
    {
      field: "systemAmount",
      headerName: "System Amount",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "manualConfirm",
      headerName: "Manual Confirm",
      minWidth: 150,
      flex: 1,
    },
    { field: "info", headerName: "Info", minWidth: 150, flex: 1 },
  ]);
  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );
  const handleSystemTransactionData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          filter: filter,
        };

        setLoading(true);
        const res = await getAllSystemTransactionData(config);
        console.log(res);

        if (res) {
          if (res && res.data && res.data.data) {
            console.log(res.data.data);
            const transformedData = res.data?.data?.map((item) => ({
              ...item,
              transactionType: item?.transactionType
                ? item?.transactionType
                : "---",
              userId: item?.userId || "---",
              status: item?.status || "---",
              usdAmount:
                typeof item?.usdAmount === "number"
                  ? parseFloat(item?.usdAmount)?.toFixed(2)
                  : "---",
              brand: localStorage.getItem("brand") || "",
              admin: user?.userName || "",
              paymentProvider: user?.paymentProvider || "---",
              paymentChannel: user?.paymentChannel || "---",
              brandAmount: user?.brandAmount || "---",
              systemAmount: user?.systemAmount || "---",
              manualConfirm: user?.manualConfirm || "---",
              info: user?.info || "---",
            }));
            setPages(res.data?.pages);
            setRowData(transformedData);
          }
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found.");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleSystemTransactionData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, isLoading, recall]);

  const handleTableCheckBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>System Transaction</h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setLimit(e.target.value || "20");
            }}
          />
          <TableColumns
            tableData={tableData}
            checkedBox={handleTableCheckBox}
          />
        </div>
      </div>
      {loading || isLoading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
