import { useEffect, useState } from "react";
import GlobalTable from "../global/globalTable";
import Layout from "../layout/layout";
import MediaFilter from "./mediaFilter";
import ButtonWrapper from "../global/button";
import { Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import SwitchBox from "../global/switchBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TableColumns from "../global/tableColumns";
import "./affiliate.css";
import FilterInput from "../global/filterInput";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createMediaTemplate,
  deleteMediaTemplate,
  getAllMediaTemplate,
  updateMediaTemplate,
} from "../../utils/Apis";
import toast from "react-hot-toast";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";

const urlOptions = [
  {
    id: 1,
    value: "Url",
  },
  {
    id: 1,
    value: "Banner",
  },
  {
    id: 1,
    value: "Text",
  },
];

const bannerTypeOptions = [
  {
    id: 1,
    value: "Static",
  },
  {
    id: 1,
    value: "Gif",
  },
  {
    id: 1,
    value: "Screenshot",
  },
];

const linkOptions = [
  {
    id: 1,
    value: "Referral Link",
  },
];

const productOptions = [
  {
    id: 1,
    value: "Sport",
  },
  {
    id: 1,
    value: "Slot",
  },
  {
    id: 1,
    value: "Live Casino",
  },
  {
    id: 1,
    value: "Other",
  },
];

const options = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 150 },
];

const MediaTemplates = () => {
  const [activePopup, setActivePopup] = useState("");
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    mediaType: "",
    bannerType: "",
    linkCategory: "",
    product: "",
    affiliateId: "",
  });

  const handlePopup = (value) => {
    setActivePopup(!value ? "" : value);
  };

  return (
    <Layout title="MEDIA TEMPLATES">
      <div className="affiliate-list-page media-template-page users-page">
        <div className="add-media-btn" role="presentation">
          <ButtonWrapper
            text="Add Media Template"
            handleClick={() => handlePopup("Add")}
            icon={
              <span className="add-icon">
                <AddBoxIcon />
              </span>
            }
            clValue="send-notificaton"
          />
        </div>
        <MediaFilter
          fields={filter}
          setFields={setFilter}
          setRecall={setRecall}
        />
        <AffiliateRequestsTable
          setActivePopup={setActivePopup}
          recall={recall}
          filter={filter}
        />
        <MediaTemplatePopup
          show={!!activePopup}
          handleClose={handlePopup}
          showPopup={activePopup}
          setRecall={setRecall}
        />
      </div>
    </Layout>
  );
};

export default MediaTemplates;

const AffiliateRequestsTable = ({ setActivePopup, recall, filter }) => {
  const [rowData, setRowData] = useState([]);
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("10");
  const [pages, setPages] = useState("");
  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    { field: "name", headerName: "Name", minWidth: 150, flex: 1 },
    { field: "creatorName", headerName: "Creator", minWidth: 200, flex: 1 },
    {
      field: "affiliateId",
      headerName: "Affiliate ID",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },
    },
    { field: "mediaType", headerName: "Media Type", minWidth: 150, flex: 1 },
    { field: "bannerType", headerName: "Banner Type", minWidth: 150, flex: 1 },
    {
      field: "linkCategory",
      headerName: "Link Category",
      minWidth: 150,
      flex: 1,
    },
    { field: "product", headerName: "Product", minWidth: 150, flex: 1 },
    { field: "recommended", headerName: "Recommended", minWidth: 150, flex: 1 },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      cellRenderer: () => <Actions setShowPopup={setActivePopup} />,
    },
  ]);
  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );
  const handleGlobalLimitData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          filter: filter,
        };

        setLoading(true);
        const res = await getAllMediaTemplate(config);

        if (res) {
          if (res && res.data && res.data.data) {
            const transformedData = res.data?.data.map((item) => ({
              ...item,
              recommended: item?.recommended ? "Yes" : "No",
              name: item?.name ? item?.name : "---",
              creatorName: item?.creatorName ? item?.creatorName : "---",
              mediaType: item?.mediaType ? item?.mediaType : "---",
              bannerType: item?.bannerType ? item?.bannerType : "---",
              linkCategory: item?.linkCategory ? item?.linkCategory : "---",
              product: item?.product ? item?.product : "---",
              affiliateId: item?.affiliateId ? item?.affiliateId : "---",
            }));
            setPages(res.data?.pages);
            setRowData(transformedData);
          }
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found.");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGlobalLimitData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recall, page, limit, isLoading]);

  const handleTableCheckBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Media Templates</h6>
        <div className="right-area">
          <SelectInput
            listArray={options}
            defaultOption="10"
            handleSelectOption={(e) => {
              setLimit(e.target.value || "10");
            }}
          />
          <TableColumns
            tableData={tableData}
            checkedBox={handleTableCheckBox}
          />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const MediaTemplatePopup = ({ show, handleClose, showPopup, setRecall }) => {
  const { data, user } = useMyContect();
  const [isLoading, setIsLoading] = useState(false);

  const initialFields = {
    name: "",
    mediaType: "",
    bannerType: "",
    linkCategory: "",
    product: "",
    affiliateId: "",
    creatorName: user?.userName,
    creatorId: user?.id,
    recommended: false,
  };
  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    if (data && showPopup === "Edit") {
      setFields({
        name: data?.name,
        mediaType: data?.mediaType,
        bannerType: data?.bannerType,
        creatorName: data?.creatorName,
        creatorId: data?.creatorId,
        linkCategory: data?.linkCategory,
        product: data?.product,
        affiliateId: data?.affiliateId,
        recommended: data?.recommended === "Yes",
      });
    } else {
      setFields(initialFields);
    }
    // eslint-disable-next-line
  }, [showPopup, data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mediaType") {
      setFields((prev) => ({
        ...prev,
        [name]: value,
        bannerType: "",
        linkCategory: "",
      }));
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        setIsLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body: fields,
        };

        const res =
          showPopup === "Add"
            ? await createMediaTemplate(config)
            : await updateMediaTemplate(config, data?.id);
        if (res) {
          setIsLoading(false);
          setFields(initialFields);
          toast.success(res.data.message);
          setRecall(Math.random());
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDelete = async () => {
    const token = localStorage.getItem("accToken");
    try {
      if (token) {
        setIsLoading(true);
        const res = await deleteMediaTemplate(data?.id);
        console.log(res);
        if (res) {
          toast.success(res.data.message);
          setIsLoading(false);
          setRecall(Math.random());
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup add-commission-popup add-global-limit-popup `}
    >
      <Modal.Header closeButton>
        <Modal.Title>{showPopup} Media Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showPopup === "Delete" ? (
          <>
            <div className="">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="auto-btn">
              <ButtonWrapper
                text={isLoading ? "Deleting..." : "Delete"}
                loading={isLoading}
                handleClick={handleDelete}
              />
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="tabs-section">
              <div className="row-one">
                <FilterInput
                  label="Name"
                  name="name"
                  value={fields?.name}
                  handleChange={handleChange}
                  isRequired={true}
                />
                <SelectInput
                  isRequired={true}
                  defaultOption="Media Type"
                  listArray={urlOptions}
                  selectedValue={fields?.mediaType}
                  name={"mediaType"}
                  handleSelectOption={handleChange}
                />
                <SelectInput
                  defaultOption="Banner Type"
                  listArray={bannerTypeOptions}
                  selectedValue={fields?.bannerType}
                  name={"bannerType"}
                  isDisabled={fields?.mediaType !== "Banner"}
                  // isRequired={fields?.mediaType === "Banner"}
                  handleSelectOption={handleChange}
                />

                <SelectInput
                  defaultOption="Link Category"
                  listArray={linkOptions}
                  selectedValue={fields?.linkCategory}
                  name={"linkCategory"}
                  isDisabled={fields?.mediaType !== "Url"}
                  handleSelectOption={handleChange}
                />
              </div>
              <div className="row-one">
                <SelectInput
                  isRequired={true}
                  defaultOption="Product"
                  listArray={productOptions}
                  selectedValue={fields?.product}
                  name={"product"}
                  // isDisabled={fields?.bannerType!=="Banner"}
                  handleSelectOption={handleChange}
                />
                <FilterInput
                  isRequired={true}
                  type={"number"}
                  label="Affiliated ID"
                  name="affiliateId"
                  handleChange={handleChange}
                  value={fields?.affiliateId}
                />
                <SwitchBox
                  label="Recommended"
                  name={"recommended"}
                  handleToggle={(item) => {
                    setFields((prevState) => ({
                      ...prevState,
                      recommended: item,
                    }));
                  }}
                  toggleFlag={fields?.recommended}
                />
              </div>

              <div className="payment-btn">
                <ButtonWrapper
                  text={showPopup === "Edit" ? "Save" : "Add"}
                  loading={isLoading}
                />
                <ButtonWrapper
                  text="Clear All"
                  clValue="clear-btn"
                  handleClick={() => {
                    setFields(initialFields);
                    setRecall(Math.random());
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

const Actions = ({ setShowPopup }) => {
  const [flag, setFlag] = useState(false);
  const handlePoppup = (value) => {
    setFlag(!flag);
    setShowPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
      {/* <EditPaymentMethod show={flag} handleClose={handlePoppup} /> */}
    </>
  );
};
