import Layout from "../../layout/layout";
import { useEffect, useState } from "react";
import ButtonWrapper from "../../global/button";
import { AddBox } from "@mui/icons-material";
import BetFilter from "./betFilter";
import GlobalTable from "../../global/globalTable";
import AddGroupLimit from "./addGroupLimit";
import AddGlobalLimit from "./addGlobalLimit";
import AddIndividualLimit from "./addIndividualLimit";
import HorizontalTabs from "../../global/horizontalTab";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./betLimit.css";
import {
  getAllGlobalLimit,
  getAllGroupLimit,
  getAllIndividualLimit,
} from "../../../utils/Apis";
import toast from "react-hot-toast";
import Pagination from "../../../utils/Pagination";
import { useMyContect } from "../../../context/myContext";
import { formatDate } from "../../../utils/dateTimeFromater";

const tabs = ["Global Limits", "Group Limits", "Individual Limits"];

const BetLimits = () => {
  const [activeTab, setActiveTab] = useState("Global Limits");
  const [showPopup, setShowPopup] = useState("");
  const [actionType, setActionType] = useState("");
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    limitType: "",
    periodType: "",
    countFrom: null,
    countTo: null,
    gameVendor: "",
    gameType: "",
    gameTemplate: "",
    group: "",
    userId: "",
    startDatefrom: "",
    startDateTo: "",
    endDateFrom: "",
    endDateTo: "",
    preventGameLunch: "",
    status: "",
  });

  const handleTabs = (value) => {
    setActiveTab(value);
  };

  const handlePopup = (value) => {
    if (value) {
      setShowPopup(value);
    } else {
      setShowPopup("");
    }
  };
  return (
    <Layout title="BET LIMITS">
      <div className="notification-page">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs notification-first global-limit">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            {activeTab === "Global Limits" ? (
              <ButtonWrapper
                text={"Add Global Limit"}
                clValue={"add-box"}
                svgIcon={<AddBox />}
                handleClick={() => handlePopup("Add")}
              />
            ) : activeTab === "Group Limits" ? (
              <ButtonWrapper
                text={"Add Group Limit"}
                clValue={"add-box"}
                svgIcon={<AddBox />}
                handleClick={() => handlePopup("Add")}
              />
            ) : (
              <ButtonWrapper
                text={"Add Individual Limit"}
                clValue={"add-box"}
                svgIcon={<AddBox />}
                handleClick={() => handlePopup("Add")}
              />
            )}
          </div>
          <div className="bet-limit-content  filter-pd">
            <BetFilter
              filter={filter}
              setFilter={setFilter}
              recall={recall}
              setRecall={setRecall}
              activeTab={activeTab}
            />
            <BetLimitTable
              activeTab={activeTab}
              recall={recall}
              filter={filter}
              setActionType={setActionType}
              setShowPopup={setShowPopup}
            />
          </div>
        </div>
      </div>
      <AddGroupLimit
        show={activeTab === "Group Limits" && !!showPopup}
        handleClose={handlePopup}
        showPopup={showPopup}
        setRecall={setRecall}
        recall={recall}
        actionType={actionType}
      />
      <AddGlobalLimit
        show={activeTab === "Global Limits" && !!showPopup}
        handleClose={handlePopup}
        setRecall={setRecall}
        recall={recall}
        showPopup={showPopup}
      />
      <AddIndividualLimit
        show={activeTab === "Individual Limits" && !!showPopup}
        handleClose={handlePopup}
        showPopup={showPopup}
        setRecall={setRecall}
        recall={recall}
      />
    </Layout>
  );
};
export default BetLimits;

const BetLimitTable = ({
  recall,
  activeTab,
  filter,
  setActionType,
  setShowPopup,
}) => {
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);

  const [limit] = useState(10);
  const [pages, setPages] = useState("");
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);

  const handleGlobalLimitData = async (page, limit) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        setLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          body: filter,
        };

        const res =
          activeTab === "Global Limits"
            ? await getAllGlobalLimit(config)
            : activeTab === "Group Limits"
            ? await getAllGroupLimit(config)
            : await getAllIndividualLimit(config);
        // console.log("res", res);

        if (res) {
          if (res && res.data && res.data.data) {
            const transformedData = res?.data?.data.map((item) => ({
              ...item,
              id: item?.id || "---",
              name: item?.name || "---",
              limitType: item?.limitType || "---",
              periodType: item?.periodType || "---",
              betCount: item?.betCount || "---",
              gameVendor: item?.gameVendor || "---",
              gameType: item?.gameType || "---",
              gameTemplate: item?.gameTemplate || "---",
              startDate: item?.startDate ? formatDate(item?.startDate) : "---",
              endDate: item?.endDate ? formatDate(item?.endDate) : "---",
              preventGameLunch: item?.preventGameLunch || "---",
              creatorName: item?.creatorName || "---",
              creatorId: item?.creatorId || "---",
              status: item?.status || "---",
            }));
            setPages(res.data.pages);
            setRowData(transformedData);
          }
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found.");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGlobalLimitData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recall, page, limit, activeTab, isLoading]);
  const forGlobal = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1, // cellStyle: { "text-align": "center" },,
    },
    {
      field: "limitType",
      headerName: "Limit Type",
      minWidth: 200,
      flex: 1, // cellStyle: { "text-align": "center" },,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "endDate",
      headerName: "To Date",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "periodType",
      headerName: "Period Tyme",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "betCount",
      headerName: "Bet Count",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameVendor",
      headerName: "Game Vendor",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameType",
      headerName: "Game Type",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameTemplate",
      headerName: "Game Template",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "status",
      headerName: "Active",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
      cellRenderer: () => <Actions setShowPopup={setShowPopup} />,
    },
  ];
  const forIndividual = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "userId",
      headerName: "User ID",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },

    {
      field: "limitType",
      headerName: "Limit Type",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "endDate",
      headerName: "To Date",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "periodType",
      headerName: "Period Tyme",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "betCount",
      headerName: "Bet Count",
      minWidth: 150,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameVendor",
      headerName: "Game Vendor",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameType",
      headerName: "Game Type",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameTemplate",
      headerName: "Game Template",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "status",
      headerName: "Active",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
      cellRenderer: () => <Actions setShowPopup={setShowPopup} />,
    },
  ];
  const forGroup = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "group",
      headerName: "Group",
      minWidth: 100,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 120,
      flex: 1, // cellStyle: { "text-align": "center" },,
    },

    {
      field: "limitType",
      headerName: "Limit Type",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      minWidth: 120,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "endDate",
      headerName: "To Date",
      minWidth: 120,
      flex: 1, // cellStyle: { "text-align": "center" },,
    },
    {
      field: "periodType",
      headerName: "Period Tyme",
      minWidth: 120,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "betCount",
      headerName: "Bet Count",
      minWidth: 120,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameVendor",
      headerName: "Game Vendor",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameType",
      headerName: "Game Type",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "gameTemplate",
      headerName: "Game Template",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "status",
      headerName: "Active",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      // cellStyle: { "text-align": "center" },,
      cellRenderer: () => <Actions setShowPopup={setShowPopup} />,
    },
  ];

  let [columnDefs, setColumnDefs] = useState(
    activeTab === "Group Limits"
      ? forGroup
      : activeTab === "Global Limits"
      ? forGlobal
      : forIndividual
  );

  useEffect(() => {
    setColumnDefs(
      activeTab === "Group Limits"
        ? forGroup
        : activeTab === "Global Limits"
        ? forGlobal
        : forIndividual
    );
    // eslint-disable-next-line
  }, [activeTab]);

  return (
    <div className="deposit-table">
      <h2>{activeTab}</h2>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const Actions = ({ setActionType, setShowPopup }) => {
  const [flag, setFlag] = useState(false);
  const handlePoppup = (value) => {
    setFlag(!flag);
    setShowPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("")}>
          <AttachMoneyIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
      {/* <EditPaymentMethod show={flag} handleClose={handlePoppup} /> */}
    </>
  );
};
