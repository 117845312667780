import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";

const TopUserTable = ({ title }) => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "#", minWidth: 150, flex: 1 },
    { field: "User Name", minWidth: 150, flex: 1 },
    { field: "AMOUNT", minWidth: 150, flex: 1 },
  ]);
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>{title}</h6>
        <div className="right-area">
          <ExportExcel />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default TopUserTable;
