import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";
import SwitchBox from "../../global/switchBox";
import {
  AddIndividualLimits,
  deleteIndividualLimit,
} from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import FilterDatePicker from "../../global/filterDatePicker";

const AddIndividualLimit = ({
  show,
  handleClose,
  recall,
  setRecall,
  showPopup,
}) => {
  const { user,  data } = useMyContect();
  const [loading, setLoading] = useState(false);
  const initialFields = {
    name: "",
    limitType: "",
    periodType: "",
    betCount: null,
    gameVendor: "",
    gameType: "",
    gameTemplate: "",
    userId: "",
    startDate: null,
    endDate: null,
    preventGameLunch: "",
    creatorName: user?.firstName,
    creatorId: user?.id,
    status: "No",
  };
  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    if (data && showPopup === "Edit") {
      setFields({
        name: data?.name,
        limitType: data?.limitType,
        periodType: data?.periodType,
        betCount: data?.betCount,
        gameVendor: data?.gameVendor,
        gameType: data?.gameType,
        gameTemplate: data?.gameTemplate,
        startDate: data?.startDate,
        endDate: data?.endDate,
        preventGameLunch: data?.preventGameLunch,
        userId: data?.userId,

        // creatorName: user?.firstName,
        // creatorId: user?.id,
        status: data?.status ? "Yes" : "No",
      });
    } else {
      setFields(initialFields);
    }
    // eslint-disable-next-line
  }, [data, showPopup]);

  let limitTypeOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];

  let periodTypeOptions = [
    {
      id: 1,
      value: "Daily",
    },
    {
      id: 1,
      value: "Weekly",
    },
    {
      id: 1,
      value: "Monthly",
    },
  ];

  let gameVenderOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];
  let gameTypeOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];

  let gameTemplateOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const token = localStorage.getItem("accToken");
      if (token) {
        setLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body: fields,
        };

        const res = await AddIndividualLimits(config, data?.id);
        if (res) {
          setLoading(false);
          setFields(initialFields);
          toast.success(res.data.message);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDateChange = (name, date) => {
    setFields((prev) => ({
      ...prev,
      [name]: date,
    }));
  };
  const handleClear = () => {
    setFields(initialFields);
    setRecall(Math.random());
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("accToken");
    try {
      if (token) {
        setLoading(true);
        const res = await deleteIndividualLimit(data?.id);
        console.log(res);
        if (res) {
          toast.success(res.data.message);
          setLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup add-group-limit-popup add-global-limit-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{showPopup} Individual Limit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          {showPopup === "Delete" ? (
            <>
              <div className="">
                <p>Are you sure you want to delete?</p>
              </div>
              <div className="auto-btn">
                <ButtonWrapper
                  text={loading ? "Deleting..." : "Delete"}
                  loading={loading}
                  handleClick={handleDelete}
                />
              </div>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <div className="row-one">
                  <FloatingLabel controlId="floatingInput" label="Name">
                    <Form.Control
                      required
                      type="text"
                      placeholder="name@example.com"
                      autoComplete="off"
                      name="name"
                      value={fields.name}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                  <SelectInput
                    isRequired={true}
                    defaultOption={"Limit Type"}
                    listArray={limitTypeOptions}
                    name="limitType"
                    selectedValue={fields.limitType}
                    handleSelectOption={handleChange}
                  />
                  <SelectInput
                    isDisabled={
                      !(
                        fields.limitType === "Per Period Bet Count" ||
                        fields.limitType === "Per Period Amount"
                      )
                    }
                    defaultOption={"Period Type"}
                    listArray={periodTypeOptions}
                    name="periodType"
                    selectedValue={fields.periodType}
                    handleSelectOption={handleChange}
                  />
                  <FloatingLabel controlId="floatingInput" label="Bet Count">
                    <Form.Control
                      required={fields.limitType === "Per Period Bet Count"}
                      disabled={fields.limitType !== "Per Period Bet Count"}
                      type={"number"}
                      placeholder="name@example.com"
                      autoComplete="off"
                      name="betCount"
                      value={fields.betCount}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </div>
                <div className="row-one">
                  <SelectInput
                    defaultOption={"Game Vendor"}
                    listArray={gameVenderOptions}
                    name="gameVendor"
                    selectedValue={fields.gameVendor}
                    handleSelectOption={handleChange}
                  />
                  <SelectInput
                    defaultOption={"Game Type"}
                    listArray={gameTypeOptions}
                    name="gameType"
                    selectedValue={fields.gameType}
                    handleSelectOption={handleChange}
                  />
                  <SelectInput
                    defaultOption={"Game Template"}
                    listArray={gameTemplateOptions}
                    name="gameTemplate"
                    selectedValue={fields.gameTemplate}
                    handleSelectOption={handleChange}
                  />
                  <SwitchBox
                    label={"Prevent Game Launch"}
                    toggleFlag={fields.preventGameLunch === "Yes"}
                    name={"preventGameLunch"}
                    handleToggle={(item) => {
                      setFields((prevState) => ({
                        ...prevState,
                        preventGameLunch: item === true ? "Yes" : "No",
                      }));
                    }}
                  />
                </div>
                <div className="row-one">
                  <FloatingLabel controlId="floatingInput" label="User Id">
                    <Form.Control
                      required
                      type={"text"}
                      placeholder="name@example.com"
                      autoComplete="off"
                      name="userId"
                      value={fields.userId}
                      onChange={handleChange}
                    />
                  </FloatingLabel>

                  <FilterDatePicker
                    label="Start Date"
                    name={"startDate"}
                    flag={false}
                    value={fields.startDate}
                    handleDateChange={handleDateChange}
                  />
                  <FilterDatePicker
                    label="To Date"
                    name={"endDate"}
                    flag={false}
                    value={fields.endDate}
                    handleDateChange={handleDateChange}
                  />
                  <SwitchBox
                    label={"Active"}
                    toggleFlag={fields.status === "Yes"}
                    handleToggle={(item) => {
                      setFields((prevState) => ({
                        ...prevState,
                        status: item === true ? "Yes" : "No",
                      }));
                    }}
                  />
                  <div className="payment-btn">
                    <ButtonWrapper
                      text={
                        showPopup === "Add"
                          ? loading
                            ? "Adding..."
                            : "Add"
                          : loading
                          ? "Saving..."
                          : "Save"
                      }
                      loading={loading}
                      type={"submit"}
                    />
                    <ButtonWrapper
                      text="Clear All"
                      clValue="clear-btn"
                      handleClick={handleClear}
                    />
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddIndividualLimit;
