import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SwitchBox from "../../global/switchBox";
import FilterInput from "../../global/filterInput";
import toast from "react-hot-toast";
import { deleteKycForm, kycFormAddUpdate } from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import HorizontalTabs from "../../global/horizontalTab";
import GlobalEditor from "../../global/globalEditor";
import SelectInput from "../../global/selectInput";

const AddKycFormPopup = ({
  show,
  handleClose,
  setRefreshForm,
  sendNotification,
}) => {
  const editorRef = useRef(null);
  const { user, data } = useMyContect();
  const [loading, setLoading] = useState(false);
  const tabs = ["Properties", "Edit Description"];
  const [activeTab, setActiveTab] = useState("Properties");

  const initialFields = {
    name: "",
    order: "",
    minQues: "",
    required: "",
    status: "Yes",
    creatorName: user?.firstName,
    creatorId: user?.id,
  };
  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    if (data && sendNotification === "Edit") {
      setFields({
        name: data?.name,
        order: data?.order,
        minQues: data?.minQues,
        required: data?.required,
        status: data?.status === "Yes",
      });
    } else {
      setFields(initialFields);
    }
    // eslint-disable-next-line
  }, [data, sendNotification]);

  const handleChange = (e) => {
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    await kycFormAddUpdate({
      body: fields,
      id: data?.id || "",
    })
      .then((result) => {
        if (result?.data?.status === 200) {
          setRefreshForm(Math.random());
          toast.success(result?.data?.message);
          handleClose();
        } else {
          toast.error(result?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };
  const handleDelete = async () => {
    const token = localStorage.getItem("accToken");
    try {
      if (token) {
        setLoading(true);
        const res = await deleteKycForm(data?.id);
        console.log(res);
        if (res) {
          toast.success(res.data.message);
          setLoading(false);
          setRefreshForm(Math.random());
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabs = (value) => {
    setActiveTab(value);
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className={`notification-popup kyc-popup add-payment-popup`}
      >
        <Modal.Header closeButton>
          <Modal.Title>{sendNotification} KYC Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sendNotification === "Delete" ? (
            <>
              <div className="">
                <p>Are you sure you want to delete?</p>
              </div>
              <div className="auto-btn">
                <ButtonWrapper
                  text={loading ? "Deleting..." : "Delete"}
                  loading={loading}
                  handleClick={handleDelete}
                />
              </div>
            </>
          ) : (
            <>
              {sendNotification === "Edit" && (
                <div className="tabs-section notification-tabs">
                  <div className="horizontal-tabs notification-first">
                    <HorizontalTabs
                      activeTab={activeTab}
                      handleTabs={handleTabs}
                      tabs={tabs}
                    />
                  </div>
                </div>
              )}

              {activeTab === "Properties" && (
                <>
                  <div className="row-one">
                    <FilterInput
                      label="Form Name"
                      name={"name"}
                      value={fields?.name}
                      handleChange={handleChange}
                    />
                    <FilterInput
                      label="order"
                      name={"order"}
                      value={fields?.order}
                      handleChange={handleChange}
                    />
                    <FilterInput
                      label="Minimum Questionnaires"
                      name={"minQues"}
                      value={fields?.minQues}
                      handleChange={handleChange}
                    />

                    <SwitchBox
                      label={"Required For Verification"}
                      toggleFlag={fields?.required === "Yes" ? true : false}
                      handleToggle={(item) => {
                        setFields((prevState) => ({
                          ...prevState,
                          required: item === true ? "Yes" : "No",
                        }));
                      }}
                    />
                  </div>
                  <div className="filter-section register-affiliate-btn justify-content-end">
                    <ButtonWrapper
                      loading={loading}
                      text={
                        sendNotification === "Add"
                          ? loading
                            ? "Adding..."
                            : "Add"
                          : loading
                          ? "Saving..."
                          : "Save"
                      }
                      clValue="search-filter-btn"
                      handleClick={handleSubmit}
                    />
                    <ButtonWrapper
                      text="Clear All"
                      clValue="clear-btn"
                      handleClick={() =>
                        setFields({
                          name: "",
                          order: "",
                          minQues: "",
                          required: "",
                        })
                      }
                    />
                  </div>
                </>
              )}

              {activeTab === "Edit Description" &&
                sendNotification === "Edit" && (
                  <EditDescription
                    sendNotification={sendNotification}
                    editorRef={editorRef}
                    handleClose={handleClose}
                    setRefreshForm={setRefreshForm}
                  />
                )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default AddKycFormPopup;

const EditDescription = ({
  sendNotification,
  editorRef,
  handleClose,
  setRefreshForm,
}) => {
  const { user, data } = useMyContect();
  const [loading, setLoading] = useState(false);

  const options = [
    { id: 1, value: "en-US" },
    { id: 2, value: "es-ES" },
    { id: 3, value: "fr-FR" },
    { id: 4, value: "de-DE" },
    { id: 5, value: "it-IT" },
    { id: 6, value: "ja-JP" },
    { id: 7, value: "ko-KR" },
    { id: 8, value: "zh-CN" },
    { id: 9, value: "ru-RU" },
    { id: 10, value: "ar-SA" },
  ];

  const initialFields = {
    name: "",
    order: "",
    minQues: "",
    required: "",
    status: "Yes",
    creatorName: user?.firstName,
    creatorId: user?.id,
    language: "",
    description: "",
  };
  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    if (data && sendNotification === "Edit") {
      setFields({
        name: data?.name,
        order: data?.order,
        minQues: data?.minQues,
        required: data?.required,
        status: data?.status === "Yes",
        language: data?.language,
        description: data?.description,
      });
    } else {
      setFields(initialFields);
    }
    // eslint-disable-next-line
  }, [data, sendNotification]);
  const handleChange = (e, fieldType) => {
    if (fieldType === "select") {
      setFields((prevState) => ({
        ...prevState,
        language: e.target.value,
      }));
    } else {
      setFields((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleEditorChange = (content) => {
    setFields((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    await kycFormAddUpdate({
      body: fields,
      id: data?.id || "",
    })
      .then((result) => {
        if (result?.data?.status === 200) {
          setRefreshForm(Math.random());
          toast.success(result?.data?.message);
          handleClose();
        } else {
          toast.error(result?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <SelectInput
        listArray={options}
        defaultOption="Select Language"
        selectedValue={fields?.language}
        handleSelectOption={(e) => handleChange(e, "select")}
      />

      <div className="editor-section">
        <h6>Text</h6>
        <GlobalEditor
          editorRef={editorRef}
          textValue={fields?.description}
          handleEditorChange={handleEditorChange}
        />
      </div>

      <div className="filter-section register-affiliate-btn justify-content-end">
        <ButtonWrapper
          loading={loading}
          text={
            sendNotification === "Add"
              ? loading
                ? "Adding..."
                : "Add"
              : loading
              ? "Saving..."
              : "Save"
          }
          clValue="search-filter-btn"
          handleClick={handleSubmit}
        />
      </div>
    </>
  );
};
