import { useState } from "react";
import GlobalTable from "../global/globalTable";
import Layout from "../layout/layout";
import AffiliateFilter from "./affiliateFilter";
import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import "./affiliate.css";
import TableColumns from "../global/tableColumns";

const options = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

const AffiliateList = () => {
  return (
    <Layout title="AFFILIATES LIST">
      <div className="affiliate-list-page users-page">
        <AffiliateFilter />
        <AffiliateTable />
      </div>
    </Layout>
  );
};

const AffiliateTable = () => {
  const [
    rowData,
    // setRowData
  ] = useState([]);

  const columnDefs = [
    {
      headerName: "User ID",
      field: "id",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "User Name",
      field: "userName",
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Email",
      field: "Email",
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "Country",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "First Name",
      field: "First Name",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Last Name",
      field: "Last Name",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Create Date",
      field: "Create Date",
      minWidth: 150,
      flex: 1,
    },
  ];

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Affiliates</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption={10} />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default AffiliateList;
