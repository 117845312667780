import { Button } from "react-bootstrap";
import Layout from "../../layout/layout";
import { useState } from "react";
import Filter from "./filter";
import AddPopup from "./addPopup";
import PopupsTable from "./popupsTable";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const ContentPopups = () => {
  const [addPopup, setAddPopup] = useState("");
  const [recall, setRecall] = useState(0);
  const [columnDefs] = useState([
    { headerName: "language", field: "language", width: 200, flex: 1 },
    {
      headerName: "Interval Hours",
      field: "intervalhours",
      width: 200,
      flex: 1,
    },
    { headerName: "image", field: "image", width: 200, flex: 1 },
    { headerName: "Detailed Text", field: "detailedText", width: 200, flex: 1 },
    {
      headerName: "Actions",
      minWidth: 200,
      flex: 1,
      cellRenderer: () => <Actions setAddPopup={setAddPopup} />,
    },
  ]);

  const handlePopup = (value) => {
    if (value !== "") {
      setAddPopup(value);
    } else {
      setAddPopup("");
    }
  };
  return (
    <Layout title="POPUPS">
      <div className="notification-page payment-method-page banner-page1 report-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs horizontal-tabs-top">
            <Button
              className="send-notificaton"
              onClick={() => handlePopup("Add")}
            >
              <span className="add-icon">
                <AddBoxIcon />
              </span>{" "}
              Add Popup
            </Button>
          </div>
          <Filter />
        </div>
        <AddPopup
          show={addPopup}
          handleClose={handlePopup}
          setRecall={setRecall}
        />
        <PopupsTable columnDefs={columnDefs} recall={recall} />
      </div>
    </Layout>
  );
};
export default ContentPopups;

const Actions = ({ setAddPopup }) => {
  const handlePoppup = (value) => {
    setAddPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
    </>
  );
};
