import { Button } from "react-bootstrap";
import { useLayoutEffect, useState } from "react";
import Layout from "../../layout/layout";
import GlobalTable from "../../global/globalTable";
import AddKycFormPopup from "./addKycFormPopup";
import AddNewQuestionnairePopup from "./addNewQuestionnairePopup";
import HorizontalTabs from "../../global/horizontalTab";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "./kycform.css";
import { kycFormList, kycQuestionnaires } from "../../../utils/Apis";
import Pagination from "../../../utils/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const tabs = ["Forms", "Questionnaires"];

const KycForm = () => {
  const [showKycForm, setShowKycForm] = useState("");
  const [showQuestionnaireForm, setShowQuestionnaireForm] = useState("");
  const [activeTab, setActiveTab] = useState("Forms");
  const [formMode, setFormMode] = useState("New");
  // const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1 },
    { field: "createdAt", headerName: "Date Created", minWidth: 150, flex: 1 },
    {
      field: "creatorName",
      headerName: "Creator Name",
      minWidth: 180,
      flex: 1,
    },
    { field: "order", headerName: "Order", minWidth: 180, flex: 1 },
    // { field: "minQues", headerName: "Min Questionnaires" },
    { field: "required", headerName: "Required", minWidth: 150, flex: 1 },
    { field: "status", headerName: "Active", minWidth: 180, flex: 1 },
    {
      field: "",
      minWidth: 200,
      flex: 1,
      headerName: "Action",
      cellRenderer: () => (
        <Actions
          setShowPopup={setShowQuestionnaireForm}
          setFormType={setFormMode}
        />
      ),
    },
  ]);
  const [columnDefs2] = useState([
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1 },
    { field: "order", headerName: "Order In Form", minWidth: 180, flex: 1 },
    { field: "required", headerName: "Mandatory", minWidth: 180, flex: 1 },
    { field: "status", headerName: "Active", minWidth: 180, flex: 1 },
    {
      field: "",
      minWidth: 200,
      flex: 1,
      headerName: "Action",
      cellRenderer: () => (
        <Actions setShowPopup={setShowKycForm} setFormType={setFormMode} />
      ),
    },
  ]);

  const handleKycForm = (value) => {
    setFormMode(value);
    if (value !== "") {
      setShowKycForm(value);
    } else {
      setShowKycForm("");
    }
  };

  const handleQuestionnairesForm = (value) => {
    setFormMode(value);
    if (value !== "") {
      setShowQuestionnaireForm(value);
    } else {
      setShowQuestionnaireForm("");
    }
  };

  const handleTabs = (value) => {
    setActiveTab(value);
  };

  // ---------------------Kyc Form--------------------
  const [refreshForm, setRefreshForm] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [kycFormData, setKycFormData] = useState([]);
  const [kycLoading, setKycLoading] = useState(false);
  useLayoutEffect(() => {
    setKycLoading(true);
    kycFormList()
      .then((result) => {
        // const list = result?.data?.data?.map((item) => ({
        //   ID: item.id,
        //   Name: item.name,
        //   "Order In Form": item.order,
        //   Mandatory: item.required,
        //   Active: item.status,
        // }));
        setPages(result?.data?.pages);
        setKycFormData(result?.data?.data);
      })
      .finally(() => setKycLoading(false));
  }, [refreshForm]);

  // ---------------------Questionnaire Form--------------------
  const [refreshQues, setRefreshQues] = useState("");
  const [pageQues, setPageQues] = useState(1);
  const [pagesQues, setPagesQues] = useState(1);
  const [questionnairesFormData, setQuestionnairesFormData] = useState([]);
  const [quesLoading, setQuesLoading] = useState(false);
  useLayoutEffect(() => {
    setQuesLoading(true);
    kycQuestionnaires()
      .then((result) => {
        // const list = result?.data?.data?.map((item) => ({
        //   ID: item.id,
        //   Name: item.name,
        //   "Date Created": item.createdAt,
        //   "Creator Name": item.creatorName,
        //   Order: item.order,
        //   "Min Questionnaires": item.minQues,
        //   Required: item.required,
        //   Active: item.status,
        // }));
        setPagesQues(result?.data?.pages);
        setQuestionnairesFormData(result?.data?.data);
      })
      .finally(() => setQuesLoading(false));
  }, [refreshQues]);

  return (
    <Layout title="KYC FORMS">
      <div className="notification-page">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs notification-first">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            {activeTab === "Forms" ? (
              <Button
                className="send-notificaton"
                onClick={() => handleKycForm("Add")}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Add KYC Form
              </Button>
            ) : (
              <Button
                className="send-notificaton"
                onClick={() => handleQuestionnairesForm("Add")}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Add New Questionnaire
              </Button>
            )}
          </div>
        </div>
        {activeTab === "Forms" && (
          <div className="deposit-table">
            <h2>KYC Forms</h2>
            {kycLoading ? (
              <div className="spinner my-5"></div>
            ) : kycFormData?.length === 0 ? (
              <h4 className="text-center my-5">No Data Found</h4>
            ) : (
              <GlobalTable columnDefs={columnDefs2} rowData={kycFormData} />
            )}
            <Pagination
              currentPage={page}
              totalPages={pages}
              onPageChange={setPage}
            />
          </div>
        )}
        {activeTab === "Questionnaires" && (
          <>
            {quesLoading ? (
              <div className="spinner my-5"></div>
            ) : questionnairesFormData?.length === 0 ? (
              <h4 className="text-center my-5">No Data Found</h4>
            ) : (
              <GlobalTable
                columnDefs={columnDefs}
                rowData={questionnairesFormData}
              />
            )}
            <Pagination
              currentPage={pageQues}
              totalPages={pagesQues}
              onPageChange={setPageQues}
            />
          </>
        )}
      </div>
      <AddKycFormPopup
        show={activeTab === "Forms" && !!showKycForm}
        handleClose={handleKycForm}
        setRefreshForm={setRefreshForm}
        sendNotification={formMode}
      />
      <AddNewQuestionnairePopup
        show={activeTab === "Questionnaires" && !!showQuestionnaireForm}
        handleClose={handleQuestionnairesForm}
        setRefreshQues={setRefreshQues}
        sendNotification={formMode}
      />
    </Layout>
  );
};
export default KycForm;

const Actions = ({ setShowPopup, setFormType }) => {
  const [flag, setFlag] = useState(false);
  const handlePopup = (value) => {
    setFlag(!flag);
    setShowPopup(value);
    setFormType(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action ms" onClick={() => handlePopup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePopup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
    </>
  );
};
