import Layout from "../layout/layout";
import CatTable from "./cat-table";

import Filter from "./filter";
import { useState } from "react";
import "./index.css";

const AllCategories = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    id: "",
    categoryName: "",
    page: "",
    pageSize: "",
  });

  return (
    <Layout title="All categories ">
      <div className="users-page position-relative">
        <Filter setRecall={setRecall} setFilter={setFilter} filter={filter} />

        <CatTable filter={filter} recall={recall} setRecall={setRecall} setFilter={setFilter}/>
      </div>
    </Layout>
  );
};
export default AllCategories;
