import { Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import FilterInput from "../global/filterInput";
import { useEffect, useState } from "react";
import { useMyContect } from "../../context/myContext";
import {
  createCommissionStageMonthly,
  deleteCommissionStageMonthly,
  updateCommissionStageMonthly,
} from "../../utils/Apis";
import toast from "react-hot-toast";

const AddCommissionStageMonthly = ({
  show,
  handleClose,
  showPopup,
  setRecall,
}) => {
  const { data } = useMyContect();
  const [isLoading, setIsLoading] = useState(false);
  const initialFields = {
    levelName: "",
    nthMonth: "",
    ftdcountMorethanOrEqual: "",
  };
  const [fields, setFields] = useState(initialFields);
  useEffect(() => {
    if (data && showPopup === "Edit") {
      setFields({
        levelName: data?.levelName,
        nthMonth: data?.nthMonth,
        commissionIncrese: data?.commissionIncrese,
      });
    } else {
      setFields(initialFields);
    }
    // eslint-disable-next-line
  }, [data, showPopup]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      // console.log("data val", fields);
      e.preventDefault();
      const token = localStorage.getItem("accToken");
      if (token) {
        setIsLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body: fields,
        };

        const res =
          showPopup === "Add"
            ? await createCommissionStageMonthly(config)
            : await updateCommissionStageMonthly(config, data?.id);
        if (res) {
          setIsLoading(false);
          toast.success(res.data.message);
          setRecall(Math.random());
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setFields(initialFields);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("accToken");
    try {
      if (token) {
        setIsLoading(true);
        const res = await deleteCommissionStageMonthly(data?.id);
        console.log(res);
        if (res) {
          toast.success(res.data.message);
          setIsLoading(false);
          setRecall(Math.random());
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup add-commission-popup commision-modal`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Commission Stage Monthly</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showPopup === "Delete" ? (
          <>
            <div className="">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="auto-btn">
              <ButtonWrapper
                text={isLoading ? "Deleting..." : "Delete"}
                loading={isLoading}
                handleClick={handleDelete}
              />
            </div>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <div className="tabs-section">
                <div className="row-one">
                  <FilterInput
                    label="Level Name"
                    name="levelName"
                    value={fields?.levelName}
                    handleChange={handleChange}
                    isRequired={true}
                  />
                  <FilterInput
                    label="nth Monthly"
                    name="nthMonth"
                    value={fields?.nthMonth}
                    handleChange={handleChange}
                    isRequired={true}
                  />
                  <FilterInput
                    label="Commission Increase"
                    name="commissionIncrese"
                    value={fields?.commissionIncrese}
                    handleChange={handleChange}
                    isRequired={true}
                    type={"number"}
                  />
                </div>
                <div className="payment-btn">
                  <ButtonWrapper
                    text={showPopup === "Edit" ? "Update" : showPopup}
                    loading={isLoading}
                  />
                  <ButtonWrapper
                    text="Clear All"
                    clValue="clear-btn"
                    handleClick={handleClear}
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AddCommissionStageMonthly;
