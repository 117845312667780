import { useEffect, useState } from "react";
import GlobalTable from "../global/globalTable";
import Layout from "../layout/layout";
import RegisterAffiliateFilter from "./registerAffiliateFilter";
import "./affiliate.css";
import TableColumns from "../global/tableColumns";
import { getRegisterAffiliateData } from "../../utils/Apis";
import { Button } from "react-bootstrap";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";
import RegisterAffiliatePopup from "./registerAffiliatePopup";

const RegisterAffiliate = () => {
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    id: "",
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    country: "",
    city: "",
    address: "",
    iskycCompleted: "",
    fromBalance: "",
    toBalance: "",
    page: "",
    pageSize: "",
  });
  const [registerAffiliatePopup, setRegisterAffiliatePopup] = useState();

  const handlePopup = (value) => {
    if (value !== " ") {
      setRegisterAffiliatePopup(value);
    } else {
      setRegisterAffiliatePopup(" ");
    }
  };

  return (
    <Layout title="REGISTER AFFILIATES">
      <div className="affiliate-list-page media-template-page register-affiliate-page users-page">
        <RegisterAffiliateFilter
          setRecall={setRecall}
          setFilter={setFilter}
          filter={filter}
        />
        <RegisterAffiliateTable
          filter={filter}
          recall={recall}
          setRegisterAffiliatePopup={setRegisterAffiliatePopup}
        />

        <RegisterAffiliatePopup
          show={registerAffiliatePopup}
          handleClose={handlePopup}
          setRecall={setRecall}
        />
      </div>
    </Layout>
  );
};

export default RegisterAffiliate;

const RegisterAffiliateTable = ({
  filter,
  recall,
  setRegisterAffiliatePopup,
}) => {
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const limit = 20;
  const [rowData, setRowData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Brand",
      field: "Brand",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => localStorage.getItem("brand"),
    },
    {
      headerName: "User ID",
      field: "id",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      valueFormatter: (params) => params.value || "-",
    },
    {
      headerName: "User Name",
      field: "userName",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
    },
    {
      headerName: "PIN",
      field: "Pin",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value || "-",
    },
    {
      headerName: "Balance",
      field: "USD",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      cellStyle: { textAlign: "end" },
      cellRenderer: (params) => params.value.toFixed(2) + " USD",
      valueFormatter: (params) => params.value || "-",
    },
    {
      headerName: "Registration Date",
      field: "createdAt",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      valueFormatter: (params) => params.value || "-",
      cellRenderer: function (params) {
        return new Date(params.value).toLocaleString();
      },
    },
    {
      headerName: "Register as Affiliate",
      field: "AffiliateRegisters.id",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <Actions
          setRegisterAffiliatePopup={setRegisterAffiliatePopup}
          value={params}
        />
      ),
    },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const handleGetAffiliateData = async (page, pageSize) => {
    console.log("filter", filter);
    try {
      const token = localStorage.getItem("accToken");
      setLoading(true);
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        };
        setRowData([]);
        const res = await getRegisterAffiliateData(config);
        if (res && res.data && res.data?.data) {
          setLoading(false);
          setPages(res.data.pages);
          setRowData(res.data?.data);
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found in the fetching gameTransaction Data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAffiliateData(page, limit);
    // eslint-disable-next-line
  }, [page, limit, recall]);

  const handleTableCheckBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Register Affiliates</h6>
        <div className="right-area">
          <TableColumns
            tableData={tableData}
            checkedBox={handleTableCheckBox}
          />
        </div>
      </div>

      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const Actions = ({ setRegisterAffiliatePopup, value }) => {
  const [flag, setFlag] = useState(false);
  const handlePopup = (value) => {
    setFlag(!flag);
    setRegisterAffiliatePopup(value);
  };

  return (
    <>
      {value.data["AffiliateRegister.id"] ? (
        <Button variant="light" size="sm" disabled>
          Registered
        </Button>
      ) : (
        <Button
          variant="success"
          size="sm"
          onClick={() => handlePopup("register")}
        >
          Register
        </Button>
      )}
    </>
  );
};
