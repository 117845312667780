import { useState } from "react";
import GlobalTable from "../../global/globalTable";

const StatisticsTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "ID", minWidth: 150, flex: 1 },
    { field: "Game", minWidth: 180, flex: 1 },
    { field: "Currency", minWidth: 180, flex: 1 },
    { field: "Bet Amount", minWidth: 180, flex: 1 },
    { field: "Win Amount", minWidth: 180, flex: 1 },
    { field: "Profit", minWidth: 180, flex: 1 },
    { field: "Bet Count", minWidth: 180, flex: 1 },
    { field: "Win Count", minWidth: 180, flex: 1 },
    { field: "User Count", minWidth: 180, flex: 1 },
    { field: "Probability", minWidth: 180, flex: 1 },
  ]);

  return (
    <div className="deposit-table">
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default StatisticsTable;
