import { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../../global/globalTable";

const AuthorizationTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "Date", minWidth: 180, flex: 1 },
    { field: "Unique", minWidth: 150, flex: 1 },
    { field: "Authorized", minWidth: 180, flex: 1 },
  ]);

  return (
    <div className="deposit-table">
      <h2>Authorization Statistics</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
export default AuthorizationTable;
