import { useState } from "react";
import GlobalTable from "../../global/globalTable";

const PerformanceStatisticsTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "Year", minWidth: 180, flex: 1 },
    { field: "Month", minWidth: 180, flex: 1 },
    { field: "Total Deposit", minWidth: 180, flex: 1 },
    { field: "Total Withdraw", minWidth: 180, flex: 1 },
    { field: "System Diff", minWidth: 180, flex: 1 },
    { field: "Total Bet", minWidth: 180, flex: 1 },
    { field: "Total Win", minWidth: 180, flex: 1 },
    { field: "Profit", minWidth: 180, flex: 1 },
    { field: "Unique Players", minWidth: 180, flex: 1 },
    { field: "Authorized Players", minWidth: 180, flex: 1 },
    { field: "Unique Players", minWidth: 180, flex: 1 },
    { field: "Registered Player Count", minWidth: 180, flex: 1 },
    { field: "Currency", minWidth: 180, flex: 1 },
  ]);
  return (
    <div className="deposit-table">
      <h2>Performance Report</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default PerformanceStatisticsTable;
