import { useEffect, useRef, useState } from "react";
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import grapesjsBlocksBasic from "grapesjs-blocks-basic";
import grapesjsNavbar from "grapesjs-navbar";
import grapesjsComponentCountdown from "grapesjs-component-countdown";
// import grapesjsTabs from "grapesjs-tabs";
import grapesjsCustomCode from "grapesjs-custom-code";
// import grapesjsLorySlider from "grapesjs-lory-slider";
import grapesjsPluginForms from "grapesjs-plugin-forms";
import grapesjsStyleFilter from "grapesjs-style-filter";
import grapesjsStyleGradient from "grapesjs-style-gradient";
import grapesjsTuiImageEditor from "grapesjs-tui-image-editor";
import grapesjsPresetWebpage from "grapesjs-preset-webpage";
import { saveStaticPages } from "../../../utils/Apis";
import toast from "react-hot-toast";
import "./StaticPages.css";
import { Spinner } from "react-bootstrap";

const StaticPages = () => {
  const editorRef = useRef(null);
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    const editor = grapesjs.init(
      {
        container: "#grapesJs",
        fromElement: true,
        noticeOnUnload: false,
        storageManager: {
          id: "gjs-",
          type: "local",
          autosaveKey: "grapesjs-pub",
          stepsBeforeSave: 1,
        },
        plugins: [
          grapesjsBlocksBasic,
          grapesjsNavbar,
          grapesjsCustomCode,
          grapesjsComponentCountdown,
          //   grapesjsTabs,
          //   grapesjsLorySlider,
          grapesjsPluginForms,
          grapesjsStyleFilter,
          grapesjsStyleGradient,
          grapesjsTuiImageEditor,
          grapesjsPresetWebpage,
        ],
      },
      true
    );

    // Add custom component type
    editor.DomComponents.addType("g-slider", {
      isComponent: (el) =>
        el.tagName === "DIV" && el.classList.contains("g-slider"),
      model: {
        defaults: {
          tagName: "div",
          draggable: true,
          droppable: true,
          resizable: true,
        },
      },
    });

    // Add custom block
    editor.BlockManager.add("slider", {
      label: "Slider",
      content:
        '<div class="g-slider" data-gjs-type="g-slider" style="min-height: 100px; background-color: #2a3342;"></div>',
      category: "Extra",
      attributes: { title: "INSERT_SLIDER" },
    });

    editorRef.current = editor;
  }, []);

  const handleSave = async () => {
    if (editorRef.current) {
      const html = editorRef.current.getHtml();
      const css = editorRef.current.getCss();

      // Here you can implement your save logic
      // For example, sending to a server or saving to local storage
      console.log("HTML:", html);
      console.log("CSS:", css);
      setIsClick(true);
      try {
        const result = await saveStaticPages({
          body: { html, css },
        });
        if (result?.data?.status === 200) {
          toast.success(result?.data?.message);
          // Call getData() only when status is 200
        } else {
          toast.error(result?.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsClick(false);
      }
    }
  };

  return (
    <div>
      <div className="actions-save">
        <div className="btn save" text_key="SAVE" onClick={handleSave}>
          {isClick ? <Spinner animation="border" /> : "Save"}
        </div>
      </div>
      <div id="grapesJs">StaticPages</div>
    </div>
  );
};

export default StaticPages;
