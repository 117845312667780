import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
// import {useRef } from "react";
// import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import SwitchBox from "../../global/switchBox";
import toast from "react-hot-toast";
import { createBanner, updateBanner, deleteBanner } from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import { formatDateTime } from "../../../utils/dateTimeFromater";
// import DatePickerInput from "../../global/datePickerInput";
import DateTimePicker from "../../global/DateTimePicker";
const BannerPopup = ({ show, handleClose, bannerPage, setRecall }) => {
  //   const editorRef = useRef(null);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup banner-page `}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {show === "bannerPopup" ? "Add" : show === "edit" ? "Update" : show}{" "}
          Banner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section ">
          <AddBanner
            rowData={bannerPage}
            show={show}
            handleClose={handleClose}
            setRecall={setRecall}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BannerPopup;

const AddBanner = ({ rowData, show, handleClose, setRecall }) => {
  const { data } = useMyContect();
  const [toggleFlag, setToggleFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [addBaner, setAddBaner] = useState({
    language: "",
    bannerPage: "",
    plateformType: "",
    bannerImage: "",
    bannerPosition: "",
    activeFrom: "",
    activeTo: "",
    bannerAlt: "",
    bannerLink: "",
    opensInNewTab: false,
  });
  useEffect(() => {
    if (show === "edit") {
      if (data?.opensInNewTab === "True") {
        setToggleFlag(true);
      } else {
        setToggleFlag(false);
      }

      const imageUrl = data?.bannerImage;
      let name = "";
      if (imageUrl) {
        name = imageUrl?.substring(imageUrl.lastIndexOf("/") + 1);
        if (name?.length > 15) {
          name = name.slice(0, 15);
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
      }
      setAddBaner({
        language: data?.language,
        bannerPosition: data?.bannerPosition,
        bannerPage: data?.bannerPage,
        plateformType: data?.plateformType,
        bannerImage: name,
        activeFrom: formatDateTime(data?.activeFrom),
        activeTo: formatDateTime(data?.activeTo),
        bannerAlt: data?.bannerAlt,
        bannerLink: data?.bannerLink,
        opensInNewTab: data?.opensInNewTab,
      });
      setImageName(name);
    }
    // eslint-disable-next-line
  }, [data]);

  const language = [{ id: 1, value: "en-US" }];

  const handleToggle = (e) => {
    setToggleFlag(!toggleFlag);
    setAddBaner({
      ...addBaner,
      opensInNewTab: !toggleFlag,
    });
  };

  const [error, setError] = useState({
    language: false,
    bannerPosition: false,
    bannerPage: false,
    plateformType: false,
    bannerImage: false,
    activeFrom: false,
    activeTo: false,
    bannerAlt: false,
    bannerLink: false,
    opensInNewTab: false,
  });

  const handleSelectChange = (e) => {
    const plateform = e.target.selectedOptions[0].parentNode.label;
    const value = e.target.value;
    setAddBaner({ ...addBaner, bannerPage: value, plateformType: plateform });
    // setError({
    //   ...error,
    //   bannerPage: false,
    // });
  };

  const handleChange = (e, fieldType) => {
    const { name, value } = e.target;
    const parsedValue = fieldType === "number" ? parseInt(value) : value;
    setError({ ...error, [name]: false });
    if (fieldType === "select") {
      setAddBaner({
        ...addBaner,
        language: value,
      });
      setError({ ...error, language: false });
    } else {
      setAddBaner({
        ...addBaner,
        [name]: parsedValue,
      });
    }
    if (!value.trim()) {
      setError({ ...error, [name]: true });
    }
  };

  // const handleActiveFromChange = (date) => {
  //   setAddBaner({ ...addBaner, activeFrom: date });
  //   setError({ ...error, activeFrom: false });
  // };

  // const handleActiveToChange = (date) => {
  //   setAddBaner({ ...addBaner, activeTo: date });
  //   setError({ ...error, activeTo: false });
  // };

  const handleImageUpload = (file) => {
    console.log("file", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];
    if (!file) {
      setError({
        ...error,
        bannerImage: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setError({ ...error, bannerImage: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setError({
          ...error,
          bannerImage: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setError({
          ...error,
          bannerImage: false,
        });
        let name = file.name;
        if (name?.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
        setAddBaner({ ...addBaner, bannerImage: file });
        setImageName(name);
      }
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    const newErrors = { ...error };
    if (!addBaner.language) {
      newErrors.language = true;
      isValid = false;
    } else {
      newErrors.language = false;
    }
    if (!addBaner.bannerPosition) {
      newErrors.bannerPosition = true;
      isValid = false;
    } else {
      newErrors.bannerPosition = false;
    }

    if (!addBaner.bannerPage) {
      newErrors.bannerPage = true;
      isValid = false;
    } else {
      newErrors.bannerPage = false;
    }

    if (!!error.bannerImage || !addBaner.bannerImage) {
      newErrors.bannerImage = "*Image is required";
      isValid = false;
    } else {
      newErrors.bannerImage = false;
    }
    if (!addBaner.activeFrom) {
      newErrors.activeFrom = true;
      isValid = false;
    } else {
      newErrors.activeFrom = false;
    }
    if (!addBaner.activeTo) {
      newErrors.activeTo = true;
      isValid = false;
    } else {
      newErrors.activeTo = false;
    }
    if (!addBaner.bannerAlt) {
      newErrors.bannerAlt = true;
      isValid = false;
    } else {
      newErrors.bannerAlt = false;
    }
    if (!addBaner.bannerLink) {
      newErrors.bannerLink = true;
      isValid = false;
    } else {
      newErrors.bannerLink = false;
    }
    setError(newErrors);
    if (isValid) {
      const formData = new FormData();
      formData.append("language", addBaner.language);
      formData.append("bannerImage", addBaner.bannerImage);
      formData.append("bannerPosition", addBaner.bannerPosition);
      formData.append("plateformType", addBaner.plateformType);
      formData.append("bannerPage", addBaner.bannerPage);
      formData.append("activeFrom", addBaner.activeFrom);
      formData.append("activeTo", addBaner.activeTo);
      formData.append("bannerAlt", addBaner.bannerAlt);
      formData.append("bannerLink", addBaner.bannerLink);
      formData.append("opensInNewTab", toggleFlag);
      try {
        const token = localStorage.getItem("accToken");
        setLoading(true);
        if (token) {
          const config = {
            headers: {
              DomainToken: `${token}`,
            },
          };
          const res =
            show === "edit"
              ? await updateBanner(data.id, config, formData)
              : await createBanner(config, formData);
          if (res?.data?.status === 200) {
            if (show === "edit") {
              toast.success("You Have successfully Updated Data", {
                toastId: "update",
              });
            } else {
              toast.success("You Have successfully Created Data", {
                toastId: "create",
              });
            }

            setLoading(false);
            setRecall(Math.random());
            handleClose();
          } else {
            toast.error("Something went Wrong Plase try Again", {
              toastId: "tryAgain",
            });
          }
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.log("Error found while fetching data", error);
      } finally {
        setLoading(false);
      }
      console.log("Form submitted successfully:", addBaner);
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const handleDateChange = (name, date) => {
    setAddBaner((prev) => ({
      ...prev,
      [name]: date,
    }));
    setError({
      ...error,
      [name]: false,
    });
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
        };
        const res = await deleteBanner(data.id, config);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted data", {
            toastId: "toats",
          });
          setLoading(false);
          setRecall(Math.random());
          handleClose();
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      {show !== "Delete" ? (
        <>
          <div className="auto-options1 popup-filter">
            <div>
              <SelectInput
                listArray={language}
                selectedValue={addBaner.language}
                handleSelectOption={(e) => handleChange(e, "select")}
                defaultOption="Choose a Language"
              />
              {error.language && (
                <div className="text-danger">*Language is required</div>
              )}
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                className="form-floating"
                onChange={(e) => handleSelectChange(e)}
                value={addBaner.bannerPage}
                name="bannerPage"
              >
                <option selected>Choose a banner</option>
                {Object.keys(rowData).map((el) => (
                  <optgroup label={el}>
                    {rowData[el].map((elem) => (
                      <option value={elem.pageName} key={elem.pageName}>
                        {elem.pageName}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select>
              {error.bannerPage && (
                <div className="text-danger">*Page Name is required</div>
              )}
            </div>
            <SwitchBox
              handleToggle={handleToggle}
              toggleFlag={toggleFlag}
              label={"Opens in new tab"}
            />
            <div>
              <div className="border p-2 rounded-2">
                <div className="upload-image">
                  {addBaner.bannerImage ? (
                    <label htmlFor="upload" className="upload-image-box">
                      {imageName}
                      <span className="uplaod-icon">
                        <ImageIcon />
                      </span>
                    </label>
                  ) : (
                    <label htmlFor="upload" className="upload-image-box">
                      Image
                      <span className="cloud-icon">
                        <CloudUploadIcon />
                      </span>
                    </label>
                  )}
                  <input
                    type="file"
                    id="upload"
                    accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                    image/gif,
                    image/bmp,image/webp"
                    onChange={(event) =>
                      handleImageUpload(event.target.files[0])
                    }
                  />
                </div>
              </div>
              {!!error.bannerImage && (
                <div className="text-danger">{error?.bannerImage}</div>
              )}
            </div>
          </div>
          <div className="auto-options1 mt-4 popup-filter">
            <div>
              <FloatingLabel controlId="floatingInput" label="Banner Position">
                <Form.Control
                  type="number"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="bannerPosition"
                  value={addBaner.bannerPosition}
                  onChange={(e) => handleChange(e)}
                />
              </FloatingLabel>
              {error.bannerPosition && (
                <div className="text-danger">*Banner Position is required</div>
              )}
            </div>
            <div className="">
              <DateTimePicker
                label={"Active From"}
                value={addBaner.activeFrom}
                name={"activeFrom"}
                handleDateChange={handleDateChange}
              />

              {error.activeFrom && (
                <div className="text-danger">
                  *Banner active from is required
                </div>
              )}
            </div>
            <div className="test-class ">
              <DateTimePicker
                label={"Active To"}
                value={addBaner.activeTo}
                name={"activeTo"}
                handleDateChange={handleDateChange}
              />

              {error.activeTo && (
                <div className="text-danger">*Banner active to is required</div>
              )}
            </div>
            <div>
              <FloatingLabel controlId="floatingInput" label="Banner Alt">
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="bannerAlt"
                  value={addBaner.bannerAlt}
                  onChange={(e) => handleChange(e)}
                />
              </FloatingLabel>
              {error.bannerAlt && (
                <div className="text-danger">*Banner Alt is required</div>
              )}
            </div>
            <div>
              <FloatingLabel controlId="floatingInput" label="Banner">
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="bannerLink"
                  value={addBaner.bannerLink}
                  onChange={(e) => handleChange(e)}
                />
              </FloatingLabel>
              {error.bannerLink && (
                <div className="text-danger">*Banner Name is required</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="ms-2">
          <p>Are you sure you want to delete this?</p>
        </div>
      )}

      <div className="auto-btn filter-section btn-wrapper">
        {data && show === "edit" ? (
          <ButtonWrapper
            text={`${loading ? "Updating..." : "Update"}`}
            loading={loading}
            handleClick={handleSubmit}
          />
        ) : show === "Delete" ? (
          <ButtonWrapper
            text={`${loading ? "Deleting..." : "Delete"}`}
            handleClick={handleDelete}
            loading={loading}
          />
        ) : (
          <ButtonWrapper
            text={`${loading ? "Creating..." : "Submit"}`}
            loading={loading}
            handleClick={handleSubmit}
          />
        )}
        {show === "edit" && (
          <ButtonWrapper
            text={"Clear All"}
            clValue="clear-btn"
            handleClick={() => {
              setAddBaner({
                language: "",
                bannerPage: "",
                plateformType: "",
                bannerImage: "",
                bannerPosition: "",
                activeFrom: null,
                activeTo: "",
                bannerAlt: "",
                bannerLink: "",
                opensInNewTab: false,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};
