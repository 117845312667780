import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import ButtonWrapper from "../global/button";
import { agentSchema } from "./AgentValidationSchema";
import { useMyContect } from "../../context/myContext";
// import GenericInputField from "../genericInput/GenericInputField";

const AddAgentPopUp = ({
  show,
  loading,
  handleClose,
  handleNewAgent,
  agentDetails,
}) => {
  const { user } = useMyContect();

  const getLevel = () => {
    let level;
    if (user?.level === "master") {
      level = "distributor";
    } else if (user?.level === "distributor") {
      level = "sub-distributor";
    } else if (user?.level === "sub-distributor") {
      level = "store";
    } else {
      level = "cashier";
    }

    const displayLevel = level.charAt(0).toUpperCase() + level.slice(1);
    return { displayLevel, apiLevel: level };
  };

  const { displayLevel, apiLevel } = getLevel();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="notification-popup add-payment-popup add-commission-popup deposit-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{show === "add" ? "Add Agent" : "Edit Agent"}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            level: apiLevel,
            username: agentDetails?.username,
            password: "",
            confirmPassword: "",
            agentId: agentDetails?.agentId,
            // balance: "",
          }}
          validationSchema={agentSchema}
          onSubmit={(values) => {
            handleNewAgent(values);
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="level-label">
                  <span>Level:</span>{" "}
                  <p class="badge badge-pill badge-success p-2 m-0">
                    {agentDetails?.level || displayLevel}
                  </p>
                </Form.Label>
                {/* <Form.Select
                  name="level" 
                  value={values.level}
                  onChange={handleChange}
                  isInvalid={touched.level && !!errors.level}
                >
                  <option value="distributor">Distributor</option>
                  <option value="sub-distributor">Sub Distributor</option>
                  <option value="store">Store</option>
                  <option value="master">Master</option>
                  <option value="cashier">Cashier</option>
                </Form.Select>
                {console.log(errors, Math.floor(Math.random() * 90000) + 10000)}
                <Form.Control.Feedback type="invalid">
                  {errors.level}
                </Form.Control.Feedback> */}
              </Form.Group>
              {show !== "edit" && (
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text py-3 pe-1" id="">
                        User Name:
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder={`Enter User Name`}
                      autoComplete="off"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      isRequired={true}
                      className="form-control py-3 px-1 modal-input"
                      isInvalid={touched.username && !!errors.username}
                    />
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`${touched.username && "d-block"}`}
                  >
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group>
                {/* <Form.Control
                  type="password"
                  placeholder={`Password`}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                /> */}
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text py-3 pe-1" id="">
                      Password:
                    </span>
                  </div>
                  <input
                    type="password"
                    placeholder={`Enter Password`}
                    autoComplete="off"
                    name="password"
                    onChange={handleChange}
                    isRequired={true}
                    className="form-control py-3 px-1 modal-input"
                    isInvalid={touched.password && !!errors.password}
                  />
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  className={`${touched.password && "d-block"}`}
                >
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                {/* <Form.Control
                  type="password"
                  placeholder={`Confirm Password`}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                /> */}
                {/* <GenericInputField
                  label={"Confirm Password"}
                  name="confirmPassword"
                  type={"password"}
                  value={values.confirmPassword}
                  handleChange={handleChange}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                /> */}
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text py-3 pe-1" id="">
                      Confirm Password:
                    </span>
                  </div>
                  <input
                    type="password"
                    placeholder={`Re-Type Password`}
                    autoComplete="off"
                    name="confirmPassword"
                    onChange={handleChange}
                    isRequired={true}
                    className="form-control py-3 px-1 modal-input"
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                  />
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  className={`${touched.confirmPassword && "d-block"}`}
                >
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group>
                <Form.Label>Balance</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={`Balance`}
                  name="balance"
                  value={values.balance}
                  onChange={handleChange}
                  isInvalid={touched.balance && !!errors.balance}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.balance}
                </Form.Control.Feedback>
              </Form.Group> */}

              {show !== "edit" && (
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text py-3 pe-1" id="">
                        Agent ID (5 Alphanumerics):
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder={`Agent ID`}
                      autoComplete="off"
                      name="agentId"
                      value={values.agentId.toUpperCase()}
                      onChange={handleChange}
                      isRequired={true}
                      maxLength={5}
                      className="form-control py-3 px-1 modal-input"
                      isInvalid={touched.agentId && !!errors.agentId}
                    />
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`${touched.agentId && "d-block"}`}
                  >
                    {errors.agentId}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <div className="deposit-popup-btn">
                <ButtonWrapper
                  loading={loading}
                  text={show === "add" ? "Create" : "Save Changes"}
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddAgentPopUp;
