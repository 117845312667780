import { useState, useEffect } from "react";
import UploadImage from "../../global/uploadImage";
import CreateIcon from "@mui/icons-material/Create";
import ButtonWrapper from "../../global/button";
import { FloatingLabel, Form, Spinner } from "react-bootstrap";
import { useMyContect } from "../../../context/myContext";
import toast from "react-hot-toast";
import {
  getGeneralContent,
  addUpdateGeneralContent,
} from "../../../utils/Apis";
const GeneralData = () => {
  let leftListData = [
    {
      id: 1,
      value: "LOGO",
      name: "logo",
    },
    {
      id: 2,
      value: "LOGO 2X",
      name: "logo2X",
    },
    {
      id: 3,
      value: "LOGO FOR MOBILE",
      name: "logoForMobile",
    },
    {
      id: 4,
      value: "FAV ICON",
      name: "favIcon",
    },
    {
      id: 5,
      value: "LOADER",
      name: "loader",
    },
    {
      id: 6,
      value: "ICON FOR SHORTCUTS",
      name: "shortcutIcon",
    },
  ];
  let rightListData = [
    {
      id: 1,
      value: "TITLE",
      name: "title",
    },
    {
      id: 2,
      value: "DESCRIPTION",
      name: "description",
    },
    {
      id: 3,
      value: "KEYWORDS",
      name: "keywords",
    },
    {
      id: 4,
      value: "COPYRIGHT",
      name: "copyright",
    },
    {
      id: 5,
      value: "EMAIL",
      name: "email",
    },
    {
      id: 6,
      value: "PHONE NUMBER",
      name: "phone_number",
    },
    {
      id: 7,
      value: "WORKING HOURS",
      name: "working_hour",
    },
    {
      id: 8,
      value: "ADDRESS",
      name: "address",
    },
  ];

  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [isClick, setIsClick] = useState({
    title: false,
    description: false,
    keywords: false,
    copyright: false,
    email: false,
    phone_number: false,
    working_hour: false,
    address: false,
    logo: false,
    logo2X: false,
    logoForMobile: false,
    favIcon: false,
    loader: false,
    shortcutIcon: false,
  });

  const [label, setLabel] = useState({
    logo: "No Image",
    logo2X: "No Image",
    logoForMobile: "No Image",
    favIcon: "No Image",
    loader: "No Image",
    shortcutIcon: "No Image",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  const handleLogoUpload = (file) => {
    handleSubmit("logo", file);
  };

  const handleLogo2xUpload = (file) => {
    handleSubmit("logo2X", file);
  };

  const handleLogoMobileUpload = (file) => {
    handleSubmit("logoForMobile", file);
  };

  const handleFavIconUpload = (file) => {
    handleSubmit("favIcon", file);
  };

  const handleLoaderUpload = (file) => {
    handleSubmit("loader", file);
  };

  const handleIconForShortcutsUpload = (file) => {
    handleSubmit("shortcutIcon", file);
  };

  const handleSubmit = async (field, file) => {
    setIsClick({
      ...isClick,
      [field]: true,
    });
    const postData = new FormData();
    if (file) {
      setLabel((prevState) => ({
        ...prevState,
        [field]: file?.name,
      }));
      postData.append(field, file);
    } else {
      postData.append(field, formData[field]);
    }
    postData.append("field", field);
    setTimeout(() => {}, 2000);
    try {
      const result = await addUpdateGeneralContent(postData);
      if (result?.data?.status === 200) {
        toast.success(result?.data?.message);
        // Call getData() only when status is 200
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsClick(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await getGeneralContent();
        setFormData(result?.data?.data[0]);
        setLabel({
          logo: result?.data?.data[0]?.logo || "No Image",
          logo2X: result?.data?.data[0]?.logo2X || "No Image",
          logoForMobile: result?.data?.data[0]?.logoForMobile || "No Image",
          favIcon: result?.data?.data[0]?.favIcon || "No Image",
          loader: result?.data?.data[0]?.loader || "No Image",
          shortcutIcon: result?.data?.data[0]?.shortcutIcon || "No Image",
        });
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [isLoading]);

  return (
    <div className="content-data">
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : (
        <div className="content-item">
          <div className="content-item-left">
            <ul className="list">
              {leftListData.map((item) => {
                return (
                  <li key={item.id}>
                    <div className="list-text">{item.value}</div>
                    <div className="d-flex align-items-center ms-auto content-width">
                      <UploadImage
                        label={label[item.name]}
                        id={item.id}
                        name={item.name}
                        onImageUpload={
                          item.name === "logo"
                            ? handleLogoUpload
                            : item.name === "logo2X"
                            ? handleLogo2xUpload
                            : item.name === "logoForMobile"
                            ? handleLogoMobileUpload
                            : item.name === "favIcon"
                            ? handleFavIconUpload
                            : item.name === "loader"
                            ? handleLoaderUpload
                            : handleIconForShortcutsUpload
                        }
                      />
                      {isClick?.[item.name] ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <CreateIcon />
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="content-item-right">
            <ul className="list">
              {rightListData.map((item) => {
                return (
                  <li key={item.id}>
                    <div className="list-text">{item.value}</div>
                    <div className="d-flex align-items ms-auto  mb-0 content-width ">
                      <FloatingLabel controlId="floatingInput" label="">
                        <Form.Control
                          type="text"
                          placeholder=""
                          autoComplete="off"
                          name={item.name}
                          onChange={handleChange}
                          value={formData?.[item.name] || ""}
                        />
                      </FloatingLabel>
                      <ButtonWrapper
                        text="Save"
                        clValue="search-filter-btn"
                        handleClick={() => handleSubmit(item.name)}
                        loading={isClick?.[item.name]}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralData;
